import React, { Fragment, useState, useRef, useEffect } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import {Container,Row,Col,Card,CardHeader,CardBody,CardFooter,Button,Form,FormGroup,Label,Input} from 'reactstrap'
import {Cancel} from "../../constant";
import DatePicker from "react-datepicker";
import { Typeahead, AsyncTypeahead } from 'react-bootstrap-typeahead'
import 'react-datepicker/dist/react-datepicker.css'

import { useTranslation } from 'react-i18next'
import SweetAlert from 'sweetalert2'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { useForm, Controller } from 'react-hook-form'
import LoadingCardSpinner from '../../components/LoadingCardSpinner/LoadingCardSpinner'
import ROLES from '../../utils/roles'
import useAuth from '../../hooks/useAuth'
import axios from '../../api/axios';
import { useLocation, useNavigate } from 'react-router-dom';
import GoBack from '../../layout/navigation/GoBack';

const formatDate = (date) => {
  const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date)
  const mo = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(date)
  const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date)
  return (`${da}-${mo}-${ye}`)
  
}

const deFormatDate = (strDate) => {
  let dated = strDate.split("-")
  let da = dated[0], mo = dated[1], ye = dated[2]
  return (new Date(`${mo}/${da}/${ye}`))
}


const CreateReport = () => {
  const location = useLocation()
  const navigate = useNavigate()
  
  // is-a create-copy
  const copymode = !!(location?.state?.copymode)
  let copysource = null
  if(copymode)
    copysource = {sourceReport: location.state.sourceReport,uri:location.state.from}  //not used

  const { auth: { role: userRole }, auth } = useAuth()
  const [multiSelections, setMultiSelections] = useState([])
  const [selectedPreset, setSelectedPreset] = useState([])
  const [selectDateType, setSelectDateType] = useState('preset')
  const [selectedPresetError, setSelectedPresetError] = useState(false)
  const [selectedIndicatorsError, setSelectedIndicatorsError] = useState(false)

  
  const [selectedUsersList, setSelectedUsersList] = useState(Array(10).fill([]))

  const { t } = useTranslation('forms')
  const { register, handleSubmit, errors, control, watch, reset, setValue, getValues } = useForm()
  const { axiosPrivate, usePrivateAxiosHook } = useAxiosPrivate()
  const [isLoading, setIsLoading] = useState(false)
  const formRef = useRef()

  // company state
  const [
    { data: {results: companyData} = {}, loading: companyLoading, error: companyError },
    refetchCompany
  ] = usePrivateAxiosHook({
    url: function () {
      if ([ROLES.superadmin, ROLES.admin].includes(userRole)) {
        return `company/`
      }
    }()
  }, { manual: userRole === ROLES.companymanager})

  const [companySelection, setCompanySelection] = useState([])

  // headquarter state
  const [
    { data: {results: headquarterData} = {}, loading: headquarterLoading, error: headquarterError },
    getHeadquarters
  ] = usePrivateAxiosHook({
    url: function () {
      if ([ROLES.superadmin, ROLES.admin].includes(userRole)) {
        return `/company/${companySelection[0]?.slug}/headquarter/`
      } else if ([ROLES.companymanager].includes(userRole)) {
        return `/company/${auth.companySlug}/headquarter/`
      }
    }()
  }, { manual: ![ROLES.companymanager].includes(userRole)})
  const [headquarterSelection, setHeadquarterSelection] = useState([])

  // presets
  const [{ data: {results: presetsData} = {}, loading, error }, refetch] = usePrivateAxiosHook(`presets/`)
  const [
    {
      data: {results: presetsIndicatorsData} = {},
      loading: loadingPresetIndicators,
      error: loadingPresetIndicatorsError
    }, executeGetPresetIndicators] = usePrivateAxiosHook({
      url: `preset/${selectedPreset[0]?.slug}/indicators/`
    }, { manual: true })

  // users data
  const [
    {
      data: {results: usersData} = {},
      loading: loadingUsers,
      error: usersError
    }, getUsers] = usePrivateAxiosHook({
      url: function () {
        if ([ROLES.superadmin, ROLES.admin].includes(userRole)) {
          return `company/${companySelection[0]?.slug}/users/`
        } else if ([ROLES.companymanager].includes(userRole)) {
          return `/company/${auth.companySlug}/users/`
        }
      }()
    }, { manual: [ROLES.admin, ROLES.superadmin].includes(userRole) })
  const [{ data: {results: indicatorsData} = {}, loading: loadingIndicators, error: errorIndicators }, refetchIndicators] = usePrivateAxiosHook(`indicators/`)

  const { startDate, endDate } = watch()

  const handleSelectChange = value => {
    setSelectDateType(value.target.value)
  }

  const [copyReference, setCopyReference] = useState(null)
  useEffect(()=>{
    if(copymode){
      axiosPrivate.get("/compilation/"+copysource.sourceReport+"/")
        .then(data=>{
          let about = data.data
          setCopyReference(about)
          setCompanySelection([about.compilation.company])
          setHeadquarterSelection([about.compilation.headquarter])
          setValue("name", about.compilation.name + " - COPY ")
          setValue("description", about.compilation.description)
          setValue("startDate", deFormatDate(about.compilation.start))
          setValue('endDate', deFormatDate(about.compilation.end))
          
          setSelectDateType('indicator')

          let reformulated = [...selectedUsersList]
          for(let index in about.indicators)
            reformulated[index] = [about.indicators[index].data_manager]
          setSelectedUsersList([...reformulated])

        })

    }
  },[])

  useEffect(()=>{
    if(!copyReference)  return;
    setMultiSelections([...copyReference.indicators])
  },[selectedUsersList])

  useEffect(() => {
    if (!!companySelection.length) {
      getHeadquarters()
      getUsers()
    } else {
      setHeadquarterSelection([])
      setSelectedUsersList(Array(10).fill([]))
    }
  }, [companySelection])

  const onSubmit = data => {
    if (!selectedUsersList[0]?.length) {
      setSelectedPresetError(true)
      return false
    } else {
      setSelectedPresetError(false)
    }
    SweetAlert.fire({
      title: t('confirmCreationMsg'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: t('confirm'),
      cancelButtonText: t('cancel'),
      reverseButtons: true
    })
    .then((result) => {
      if (result.value) {
        if (data !== '') {
          const usersListOfIds = selectedUsersList.map(item => {
            if (item[0]?.id) {
              return item[0].id
            }
          })
          const indicatorsListOfSlugs = selectDateType === 'preset' 
            ? presetsIndicatorsData.map(item => item.slug)
            : multiSelections.map((item) => item.slug)
          // debugger
          // return false
          setIsLoading(true)
          try {
            let body = {
              slug_Company:companySelection[0].slug,
              slug_Headquarter:headquarterSelection[0].slug,
              name: data.name,
              description: data.description,
              start: formatDate(data.startDate),
              end: formatDate(data.endDate)
            }
            axiosPrivate.post(`compilations/`, body )
              .then(res => {
                // console.log(res);
                let reportSlug = res.data?.slug
                if ([200,201].includes(res.status) && reportSlug) {
                  //handle multiple POST compilation/<str:slug>/indicator/<str:slug> requests
                  new Promise((resolve,reject)=>{
                    let i = 0
                    for(let slug of indicatorsListOfSlugs){
                      let body = {userId:usersListOfIds[i++]}
                      axiosPrivate.post('compilation/'+reportSlug+'/indicator/'+slug, body)
                        .then(res=>{
                          if(![200,201].includes(res.status))
                            //may be a >499 error code
                            reject("tryLater")
                        }).catch(err=>{
                          //report created but indicators were not correctly associated
                          reject("tryToAddIndicatorsOnManageReport")
                        })
                    }
                    resolve()
                  }).then(_ =>{
                    SweetAlert.fire(
                      t('saved'),
                      t('successCreationMsg'),
                      'success'
                    ).then(()=>{
                      if(copymode)
                        navigate('/edit-report/'+copysource.sourceReport, {state:{reportSlug:copysource.sourceReport}, replace:true})
                    })
                  }).catch(err =>{
                    SweetAlert.fire(
                      t('somethingWentWrongDuringIndicatorsRegistrationMsg'),
                      t(err),
                      'warning'
                    )
                  })
                  // formRef.current.reset()
                  setSelectedUsersList(Array(10).fill([]))
                  setMultiSelections([])
                  setSelectedPreset([])
                  reset()
                } else {
                  SweetAlert.fire(
                    t('somethingWentWrongMsg'),
                    t('tryAgainMsg'),
                    'warning'
                  )
                }
              })
              .catch(err => {
                // console.log('error: ', err)
                if (err.request.status === 409) {
                  SweetAlert.fire(
                    t('somethingWentWrongMsg'),
                    t('sameDataCollectionDataExist'),
                    'warning'
                  )
                } else {
                  SweetAlert.fire(
                    t('somethingWentWrongMsg'),
                    t('tryAgainMsg'),
                    'warning'
                  )
                }
              })
              .finally(() => {
                setIsLoading(false)
              })
          } catch (e) {
            // console.log(e)
          }
        } else {
          errors.showMessages()
          setIsLoading(false)
        }
      }
    })
  }

  const onImportPresetIndicatorsPress = () => {
    executeGetPresetIndicators()
      .then((res) => {
        const arrayOfArrays = []
        for (let i = 0; i < res?.data?.length; i++) {
          arrayOfArrays.push([])
        }
        setSelectedUsersList(arrayOfArrays)
      })
  }

  const renderIndicatorItem = (indicator, index) => {
    return (
      <FormGroup className="row" key={indicator.slug}>
        <Label className="col-sm-3 col-form-label">{indicator.code}</Label>
        <Col sm="9">
          <div className="form-row">
            <Typeahead
              style={{ width: '100%' }}
              id={`select-indicator-${indicator.slug}`}
              labelKey="name"
              onChange={item => setSelectedUsersList(prev => {
                const newState = [...prev]
                newState[index] = item
                return newState
              })}
              options={usersData || []}
              placeholder="Seleziona il referente dati che deve compilare questo indicatore"
              selected={selectedUsersList[index]}
              isValid={!!selectedUsersList[index]?.length}
              isInvalid={!selectedUsersList[index]?.length}
              inputProps={{ required: true }}
            />
            <span className='danger-text-color'>{selectedPresetError && t('requiredField')}</span>
          </div>
        </Col>
      </FormGroup>
    )
  }

  return (
    <Fragment>
      <Breadcrumb parent="Report" title={copymode?"Create Copy":"Create Report"}>
        {copymode &&
          <GoBack 
            text={t('goBack')} 
            to={'/edit-report/'+copysource.sourceReport} 
            state={{reportSlug:copysource.sourceReport}} 
            replace={true}
          />}
      </Breadcrumb>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <LoadingCardSpinner isLoading={loading || headquarterLoading || isLoading || loadingPresetIndicators || loadingUsers || loadingIndicators} />
              <CardHeader>
                <h5>{copymode?t('createCopy'):t('createReport')}</h5>
              </CardHeader>
              <Form className="form theme-form needs-validation" autoComplete='off' onSubmit={handleSubmit(onSubmit)}  innerRef={formRef}>
                <CardBody>
                  <Row>
                    <Col>
                      {![ROLES.companymanager].includes(userRole) && <><FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('company')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Typeahead
                              style={{ width: '100%' }}
                              id="select-company"
                              labelKey="companyName"
                              onChange={setCompanySelection}
                              options={companyData || []}
                              placeholder={t('company')}
                              selected={companySelection}
                              isValid={!!companySelection.length}
                              isInvalid={!companySelection.length}
                              inputProps={{ required: true }}
                              name="company"
                              innerRef={register({ required: true })}
                            />
                          </div>
                        </Col>
                      </FormGroup> 
                      <FormGroup className="row" style={ !!companySelection.length || [ROLES.companymanager].includes(userRole) ? {} : { display: 'none' } }>
                        <Label className="col-sm-3 col-form-label">{t('headquarter')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Typeahead
                              style={{ width: '100%' }}
                              id="select-headquarter"
                              labelKey="name"
                              onChange={setHeadquarterSelection}
                              options={(!!companySelection.length || [ROLES.companymanager].includes(userRole)) ? (headquarterData || []) : []}
                              placeholder="Seleziona Sede"
                              selected={headquarterSelection}
                              isValid={!!headquarterSelection.length}
                              isInvalid={!headquarterSelection.length}
                              inputProps={{ required: true }}
                              name="headquarter"
                              innerRef={register({ required: true })}
                            />
                          </div>
                        </Col>
                      </FormGroup></>}
                      <hr/>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('name')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control"
                              name='name'
                              type="text"
                              placeholder={t('name')}
                              innerRef={register({ required: true, maxLength: 100 })}
                            />
                            <span className="">{errors.name?.type === 'required' && t('requiredField')}</span>
                            <span className="">{errors.name?.type === 'maxLength' && t('lengthLimit', {length: 100})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('description')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control"
                              name='description'
                              type="textarea"
                              rows='3'
                              placeholder={t('description')}
                              innerRef={register({ required: true })}
                            />
                            <span className='danger-text-color'>{errors.description && t('requiredField')}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{ t('startingDate') }*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Controller
                              control={control}
                              name="startDate"
                              rules={{ required: true }}
                              defaultValue=""
                              render={( field ) => (
                                <DatePicker
                                  className="form-control digits"
                                  selected={field.value}
                                  onChange={(date) => field.onChange(date)}
                                  // minDate={new Date()}
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="Click to select a date"
                                  selectsStart
                                  startDate={field.value}
                                  endDate={endDate}
                                />
                              )}
                            />
                            <span className='danger-text-color'>{errors.startDate && t('requiredField')}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{ t('endingDate') }*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Controller
                              control={control}
                              name="endDate"
                              rules={{ required: true }}
                              defaultValue=""
                              render={( field ) => (
                                <DatePicker
                                  className="form-control digits"
                                  selected={field.value}
                                  onChange={(date) => field.onChange(date)}
                                  minDate={startDate || new Date()}
                                  selectsRange={false}
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="Click to select a date"
                                  selectsEnd
                                  startDate={startDate}
                                  endDate={field?.value}
                                />
                              )}
                            />
                            <span className='danger-text-color'>{errors.endDate && t('requiredField')}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup onChange={handleSelectChange} className="row">
                        <Label className="col-sm-3 col-form-label">{ t('dataType') }*</Label>
                        <Col sm="9" className="d-flex align-items-center">
                          <Label className="d-flex align-items-center p-l-10 p-r-10 m-0" for="edo-ani">
                            <Input className="radio_animated" value="preset" id="edo-ani" type="radio" name="rdo-ani" checked={selectDateType === 'preset'} /> {t('preset')}
                          </Label>
                          <Label className="d-flex align-items-center p-l-10 p-r-10 m-0" for="edo-ani1">
                            <Input className="radio_animated" value="indicator" id="edo-ani1" type="radio" name="rdo-ani" checked={selectDateType === 'indicator'}/> {t('customIndicators')}
                          </Label>
                        </Col>
                      </FormGroup>
                      {selectDateType === 'preset' ? (
                        <>
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label">{t('preset')}</Label>
                            <Col sm="9">
                              <div className="form-row">
                                <Typeahead
                                  style={{ width: '100%' }}
                                  id="select-preset"
                                  labelKey="name"
                                  onChange={setSelectedPreset}
                                  options={presetsData || []}
                                  placeholder="Seleziona il preset da aggiungere alla raccolta"
                                  selected={selectedPreset}
                                  isValid={!!selectedPreset.length}
                                  isInvalid={!selectedPreset.length}
                                />
                                <Button
                                  color="success"
                                  className="mr-1 mt-3"
                                  onClick={onImportPresetIndicatorsPress}
                                  disabled={!selectedPreset.length}
                                >
                                  {t('importPreset')}
                                </Button>
                                <span className='danger-text-color w-100'>{selectedPresetError && t('requiredField')}</span>
                                <span className='danger-text-color w-100'>{selectedPresetError && 'Per applicare il preset bisogna importarlo'}</span>
                              </div>
                            </Col>
                          </FormGroup>
                          { !!presetsIndicatorsData?.length && presetsIndicatorsData.map((item, index) => renderIndicatorItem(item, index))}
                        </>
                      ) : (
                        <>
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label">{t('indicators')}</Label>
                            <Col sm="9">
                              <Typeahead
                                id="select-indicators"
                                labelKey="code"
                                multiple
                                onChange={setMultiSelections}
                                options={
                                  indicatorsData?.filter((item) => !multiSelections.map(_=>_.id).includes(item.id) )
                                  || []}
                                placeholder="Seleziona più indicatori per aggiungerli alla raccolta"
                                selected={multiSelections}
                              />
                            </Col>
                          </FormGroup>
                          {multiSelections?.map((item, index) => renderIndicatorItem(item, index))}
                        </>
                      )}
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Col sm="9 offset-sm-3">
                    <Button color="primary" className="mr-1">{t('save')}</Button>
                    <Button color="light" type="reset" >{t('reset')}</Button>
                  </Col>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CreateReport;