// import { ROLES } from '../components/app'
// dashbaord
import Default from '../components/dashboard/default'
import UserProfile from '../components/users/userProfile'

import EditUser from '../components/users/EditUser'
import CreateUser from '../components/users/CreateUser'
import UsersList from '../components/users/UsersList'

import CompanyList from '../components/company/CompanyList'
import CreateCompany from '../components/company/CreateCompany'
import EditCompany from '../components/company/EditCompany'

import HoldingList from '../components/holding/HoldingList'
import CreateHolding from '../components/holding/CreateHolding'
import EditHolding from '../components/holding/EditHolding'

import HeadquartersList from '../components/headquarters/HeadquartersList'
import CreateHeadquarters from '../components/headquarters/CreateHeadquarters'
import EditHeadquarters from '../components/headquarters/EditHeadquarters'


import IndicatorList from '../pages/indicators/IndicatorList'
import CreateIndicator from '../pages/indicators/CreateIndicator'
import EditIndicator from '../pages/indicators/EditIndicator'
// import CompileIndicator from '../pages/indicators/CompileIndicator'

import SubIndicatorList from '../pages/subindicators/SubIndicatorList'
import CreateSubIndicator from '../pages/subindicators/CreateSubIndicator'
import EditSubIndicator from '../pages/subindicators/EditSubIndicator'

import PresetList from '../pages/preset/PresetList'
import CreatePreset from '../pages/preset/CreatePreset'
import EditPreset from '../pages/preset/EditPreset'

// import DataCollectionList from '../pages/dataCollection/DataCollectionList'
// import CreateDataCollection from '../pages/dataCollection/CreateDataCollection'
// import EditDataCollection from '../pages/dataCollection/EditDataCollection'

import CreateReport from '../pages/report/CreateReport'
import EditReport from '../pages/report/EditReport'
import ReportList from '../pages/report/ReportList'
import ExploreIndicator from '../pages/report/indicatorExploration/ExploreIndicator'
import Overview from '../pages/report/Overview'


// import ReportingPeriodList from '../pages/reportingPeriod/ReportingPeriodList'
// import CreateReportingPeriod from '../pages/reportingPeriod/CreateReportingPeriod'
// import EditReportingPeriod from '../pages/reportingPeriod/EditReportingPeriod'

const ROLES = {
  'superadmin': 'superadmin',
  "admin": "admin",
  "groupleader": "groupleader",
  "companymanager": "companymanager",
  "datamanager": "datamanager"
}

export const routes = [
  { path:`/homepage/`, Component:Default, Roles: [ROLES.companymanager, ROLES.groupleader, ROLES.datamanager] },
  { path:`/user-profile/`, Component:UserProfile, Roles: [ROLES.companymanager, ROLES.groupleader, ROLES.datamanager] },

  { path:`/create-user/`, Component:CreateUser, Roles: [ROLES.companymanager, ROLES.groupleader]},
  { path:`/edit-user/:id`, Component:EditUser, Roles: [ROLES.companymanager, ROLES.groupleader]},
  { path:`/manage-user/`, Component:UsersList, Roles: [ROLES.companymanager, ROLES.groupleader]},

  { path:`/create-company/`, Component:CreateCompany, Roles: [ROLES.groupleader]},
  { path:`/edit-company/:id`, Component:EditCompany, Roles: [ROLES.groupleader]},
  { path:`/manage-company/`, Component:CompanyList, Roles: [ROLES.groupleader]},
  
  { path:`/create-holding/`, Component:CreateHolding, Roles: []},
  { path:`/edit-holding/:id`, Component:EditHolding, Roles: []},
  { path:`/manage-holding/`, Component:HoldingList, Roles: []},

  { path:`/create-headquarters/`, Component:CreateHeadquarters, Roles: [ROLES.companymanager, ROLES.groupleader]},
  { path:`/edit-headquarter/:id`, Component:EditHeadquarters, Roles: [ROLES.companymanager, ROLES.groupleader]},
  { path:`/manage-headquarters/`, Component:HeadquartersList, Roles: [ROLES.companymanager, ROLES.groupleader]},

  { path:`/create-indicators/`, Component:CreateIndicator, Roles: [ROLES.companymanager]},
  { path:`/edit-indicators/:id`, Component:EditIndicator, Roles: [ROLES.companymanager, ROLES.groupleader]},
  { path:`/manage-indicators/`, Component:IndicatorList, Roles: [ROLES.companymanager, ROLES.groupleader]},
  
  { path:`/create-subindicators/`, Component:CreateSubIndicator, Roles: [ROLES.companymanager]},
  { path:`/edit-subindicators/:id`, Component:EditSubIndicator, Roles: [ROLES.companymanager, ROLES.groupleader]},
  { path:`/manage-subindicators/`, Component:SubIndicatorList, Roles: [ROLES.companymanager, ROLES.groupleader]},

  { path:`/create-presets/`, Component:CreatePreset, Roles: [ROLES.companymanager]},
  { path:`/edit-presets/:id`, Component:EditPreset, Roles: [ROLES.companymanager]},
  { path:`/manage-presets/`, Component:PresetList, Roles: [ROLES.companymanager]},

  // { path:`/create-data-collection/`, Component:CreateDataCollection, Roles: [ROLES.companymanager]},
  // { path:`/edit-data-collection/:id`, Component:EditDataCollection, Roles: [ROLES.companymanager, ROLES.datamanager]},
  // { path:`/manage-data-collection/`, Component:DataCollectionList, Roles: [ROLES.companymanager, ROLES.datamanager]},

  { path:`/create-report/`, Component:CreateReport, Roles: [ROLES.companymanager]},
  { path:`/edit-report/:reportSlug`, Component:EditReport, Roles: [ROLES.companymanager, ROLES.datamanager]},
  { path:`/manage-report/`, Component:ReportList, Roles: [ROLES.companymanager, ROLES.datamanager]},

  { path:`/edit-report/:id/indicator/:indicator`, Component:ExploreIndicator, Roles: [ROLES.companymanager, ROLES.datamanager]},
  
  { path:`/overview/`, Component:Overview, Roles: [ROLES.companymanager, ROLES.datamanager]},

  // { path:`/create-reporting-period/`, Component:CreateReportingPeriod, Roles: [ROLES.companymanager]},
  // { path:`/edit-reporting-period/:id`, Component:EditReportingPeriod, Roles: [ROLES.companymanager, ROLES.datamanager]},
  // { path:`/manage-reporting-period/`, Component:ReportingPeriodList, Roles: [ROLES.companymanager, ROLES.datamanager]},
  // { path:`/compile-indicator/:id`, Component:CompileIndicator, Roles: [ROLES.companymanager, ROLES.datamanager]},
]