import { random } from "lodash"
import React from "react"
import { useState } from "react"
import {Info} from "react-feather"
import { Tooltip } from "reactstrap"

const InfoDialogBox = (props)=>{
    const {placement = "right", target="", className=""} = props
    const [ dialogOpen, setDialogOpen ] = useState(false)

    return (
        <div className={className}>
            <Info className="align-middle" id={target}/>
            <Tooltip target={target} isOpen={dialogOpen} toggle={()=>setDialogOpen(!dialogOpen)}> 
                {props.children}
            </Tooltip>
        </div>
    )
}

export default InfoDialogBox