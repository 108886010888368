import React from 'react';
import { Col, InputGroup, InputGroupText } from "reactstrap"

const PasswordVisibleInput = (props)=>{
    const {visible, setVisible} = props
    return (
        <InputGroup>
            <Col xs="11" className='px-0'>
                {props.children}
            </Col>
            <Col xs="1"  className='px-0'>
                <InputGroupText onClick={()=>setVisible(!visible)} className="password-visibility-box">
                {
                    visible
                    ?<i className='fa fa-eye' />
                    :<i className='fa fa-eye-slash' />
                }
                </InputGroupText>
            </Col>
        </InputGroup>
    )
}
export default PasswordVisibleInput