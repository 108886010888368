import React, { Fragment, useState, useRef } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import {Container,Row,Col,Card,CardHeader,CardBody,CardFooter,Button,Form,FormGroup,Label,Input} from 'reactstrap'
import {Cancel} from '../../constant'
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'

import { useTranslation } from 'react-i18next'
import SweetAlert from 'sweetalert2'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { useForm } from 'react-hook-form'
import LoadingCardSpinner from '../../components/LoadingCardSpinner/LoadingCardSpinner'
import useAuth from '../../hooks/useAuth'
import ROLES from '../../utils/roles'

const CreatePreset = () => {
  const [indicatorsSelection, setIndicatorsSelection] = useState([])
  const [isPublic, setIsPublic] = useState(true)
  const { t } = useTranslation('forms')
  const { auth: { role: userRole }, auth } = useAuth()
  const { register, handleSubmit, errors, getValues } = useForm()
  const { axiosPrivate, usePrivateAxiosHook } = useAxiosPrivate()
  const [{ data: {results: indicatorsData} = {}, loading, error }, refetch] = usePrivateAxiosHook(`indicators/`)
  const [isLoading, setIsLoading] = useState(false)
  const [ indicatorsSelectionError, setIndicatorsSelectionError ] = useState(false)
  const formRef = useRef()

  const onSubmit = data => {
    if (!indicatorsSelection.length) {
      setIndicatorsSelectionError(true)
      return
    } else {
      setIndicatorsSelectionError(false)
    }
    SweetAlert.fire({
      title: t('confirmCreationMsg'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: t('confirm'),
      cancelButtonText: t('cancel'),
      reverseButtons: true
    })
    .then((result) => {
      if (result.value) {
        if (data !== '') {
          try {
            const selectedIndicatorsIdsList = indicatorsSelection.map(item => item.id)
            let body = {
              name: data.name,
              description: data.description,
              isPublic: [ROLES.superadmin, ROLES.admin].includes(userRole) ? true : isPublic,
              indicators: indicatorsSelection.map(item => item.id),
            }
            if ([ROLES.companymanager].includes(userRole)) {
              body = { ...body, id_Company: auth.companyId }
            }
            
            axiosPrivate.post('presets/', body )
              .then(res => {
                if (res.status === 201) {
                  SweetAlert.fire(
                    t('saved'),
                    t('successCreationMsg'),
                    'success'
                  )
                  formRef.current.reset()
                  setIndicatorsSelection([])
                } else {
                  SweetAlert.fire(
                    t('somethingWentWrongMsg'),
                    t('tryAgainMsg'),
                    'warning'
                  )
                }
              })
              .catch(err => {
                // console.log('error: ', err)
                if (err.request.status === 409) {
                  SweetAlert.fire(
                    t('somethingWentWrongMsg'),
                    t('samePresetDataExist'),
                    'warning'
                  )
                } else {
                  SweetAlert.fire(
                    t('somethingWentWrongMsg'),
                    t('tryAgainMsg'),
                    'warning'
                  )
                }
              })
              .finally(() => {
                setIsLoading(false)
              })
            // console.log(body)
          } catch (e) {
            // console.log(e)
          }
        } else {
          errors.showMessages();
          setIsLoading(false)
        }
      }
    })
  }

  const handleSelectChange = value => {
    setIsPublic(value.target.value ==='public')
  }

  return (
    <Fragment>
      <Breadcrumb parent="Preset" title="Create Preset"/>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <LoadingCardSpinner isLoading={loading || isLoading} />
              <CardHeader>
                <h5>{t('createPreset')}</h5>
              </CardHeader>
              <Form className="form theme-form needs-validation" autoComplete='off' onSubmit={handleSubmit(onSubmit)}  innerRef={formRef}>
                <CardBody>
                  <Row>
                    <Col>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('name')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control"
                              name='name'
                              type="text"
                              placeholder={t('name')}
                              innerRef={register({ required: true, maxLength: 50 })}
                            />
                            <span className="">{errors.name?.type === 'required' && t('requiredField')}</span>
                            <span className="">{errors.name?.type === 'maxLength' && t('lengthLimit', {length: 50})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('description')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control"
                              name='description'
                              type="textarea"
                              rows='3'
                              placeholder={t('description')}
                              innerRef={register({ required: true })}
                            />
                            <span className='danger-text-color'>{errors.description && t('requiredField')}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      {![ROLES.superadmin, ROLES.admin].includes(userRole) && <FormGroup className="row" onChange={handleSelectChange}>
                        <Label className="col-sm-3 col-form-label">{t('visibility')}*</Label>
                        <Col sm="9" className="d-flex align-items-center">
                          <Label className="d-flex align-items-center p-l-10 p-r-10 m-0" for="edo-ani">
                            <Input value='public' className="radio_animated" id="edo-ani" type="radio" name="rdo-ani" defaultChecked />
                            Pubblico
                          </Label>
                          <Label className="d-flex align-items-center p-l-10 p-r-10 m-0" for="edo-ani1">
                            <Input value='private' className="radio_animated" id="edo-ani1" type="radio" name="rdo-ani"/>
                            Privato
                          </Label>
                        </Col>
                      </FormGroup>}
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('indicators')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Typeahead
                              style={{ width: '100%' }}
                              id="select-preset-indicators"
                              labelKey="code"
                              multiple
                              onChange={setIndicatorsSelection}
                              options={indicatorsData || []}
                              placeholder="Seleziona più indicatori"
                              selected={indicatorsSelection}
                            />
                            <span className='danger-text-color'>{indicatorsSelectionError && t('requiredField')}</span>
                          </div>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Col sm="9 offset-sm-3">
                    <Button color="primary" className="mr-1">{t('save')}</Button>
                    <Button color="light" type="reset" >{t('reset')}</Button>
                  </Col>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CreatePreset;