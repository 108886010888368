import React, {useEffect, useState } from 'react';
import Layout from './layout/Layout'
import '../index.scss';
import {Routes,Route, useLocation, useNavigate} from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import {routes} from '../route';
import ConfigDB from '../data/customizer/config'
import Logins from '../pages/authentication/login'
import Forgetpwd from '../pages/authentication/forgetpwd'
import Error404 from '../pages/errors/error404'
import PersistLogin from './persistLogin/PersistLogin'
import RequireAuth from './RequireAuth/RequireAuth.jsx'
import Error401 from '../pages/errors/error401'
import ROLES from '../utils/roles'

const App = () => {
  const [anim, setAnim] = useState("")
  const animation = localStorage.getItem("animation") || ConfigDB.data.router_animation || 'fade'
  const abortController = new AbortController()
  // const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
  // const layout = localStorage.getItem('layout') ||  Object.keys(defaultLayoutObj).pop();
  const location = useLocation()
  const nav = useNavigate()
  if(location.pathname.length === 1)
    nav('/homepage/')


  useEffect(() => {
      setAnim(animation)
      console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
      console.disableYellowBox = true;
      return function cleanup() {
          abortController.abort();
        }
      // eslint-disable-next-line
    }, []);

  return (
    <Routes>
      <Route path={`/login`} element={<Logins />} />
      <Route path={`/forget-password`} element={<Forgetpwd />} />
      <Route path={`/unauthorized`} element={<Error401 />} />
        <Route element={<PersistLogin />} >
          <Route element={<RequireAuth allowedRoles={[ROLES.superadmin, ROLES.admin, ROLES.groupleader, ROLES.companymanager, ROLES.datamanager]} />}>
            <Route path="/" element={<Layout />}>
              {routes.map(({ path, Component, Roles }) => (
                <Route key={path} element={<RequireAuth allowedRoles={[ROLES.superadmin, ROLES.admin, ...Roles]} />}>
                  <Route key={path} exact path={`${path}`}
                    element= {
                      <CSSTransition 
                        in
                        timeout={100}
                        classNames={anim} 
                        unmountOnExit
                      >
                        <div><Component/></div>
                      </CSSTransition> 
                    }
                  />
                </Route>
              ))}
            </Route>
            <Route path='*' exact={true} element={<Error404/>} />
          </Route>
      </Route>
    </Routes>
  );
}

export default App;