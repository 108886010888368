import React, { Fragment } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import {Container,Row,Col,Card,CardHeader,CardBody} from 'reactstrap';
import useAuth from '../../../hooks/useAuth'
import { useTranslation } from 'react-i18next'

const  Sample = (props) => {
    const { auth: { username } } = useAuth()
    const { t } = useTranslation('sections')
    return (
      <Fragment>
      <Breadcrumb parent="Homepage" />
        <Container fluid={true}>
          <Row className="second-chart-list third-news-update justify-content-center">
            <Col xl="4 xl-50" lg="12" className="morning-sec box-col-12">
              <Card className="o-hidden profile-greeting">
                <CardBody>
                  <div className="greeting-user text-center">
                    <div className="profile-vector"><img className="img-fluid" src={require("../../../assets/images/albero.png")} alt="" /></div>
                    <h4 className="f-w-600">{t('welcome')} {username}<span className="right-circle"><i className="fa fa-check-circle f-14 middle"></i></span></h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment> 
    )
}

export default Sample;