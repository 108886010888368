import React, { Fragment, useEffect, useState, useMemo } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import {Container,Row,Col,Card,CardHeader,CardBody,CardFooter,Button,Form,FormGroup,Label,Input} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import SweetAlert from 'sweetalert2'
import { useForm } from 'react-hook-form'
import LoadingCardSpinner from '../LoadingCardSpinner/LoadingCardSpinner'
import useAuth from '../../hooks/useAuth'
import ROLES from '../../utils/roles'

const EditUser = () => {
  const { t } = useTranslation('forms')
  const { auth: { role: userRole, id: userId }, auth } = useAuth()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const { axiosPrivate, usePrivateAxiosHook } = useAxiosPrivate()
  const [{ data: userData, loading, error }, refetch] = usePrivateAxiosHook(`user/${userId}/`)
  const { register, handleSubmit, reset, errors, getValues } = useForm({
    defaultValues: useMemo(() => {
      return userData
    }, [userData])
  })
  const { 
    register: registerChangePassword,
    handleSubmit: handleSubmitChangePassword,
    reset: resetChangePassword,
    errors: errorsChangePassword,
    getValues: getValuesChangePassword
  } = useForm()

  useEffect(() => {
    reset(userData)
  }, [userData])

  const editUser = (id, body) => {
    axiosPrivate.patch(`user/${id}/`, body )
      .then(res => {
        // console.log(res);
        if (res.status === 200) {
          SweetAlert.fire(
            t('edited'),
            t('successEditMsg'),
            'success'
          )
          navigate(-1)
        } else {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('tryAgainMsg'),
            'warning'
          )
        }
      })
      .catch(err => {
        // console.log('error: ', err)
        if (err.request.status === 409) {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('sameDataUserExist'),
            'warning'
          )
        } else {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('tryAgainMsg'),
            'warning'
          )
        }
      })
  }

  const onSubmit = data => {
    // console.log(data)
    SweetAlert.fire({
      title: t('confirmEditMsg'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: t('confirm'),
      cancelButtonText: t('cancel'),
      reverseButtons: true
    })
    .then((result) => {
      if (result.value) {
        if (data !== '') {
          setIsLoading(true)
          try {
            const body = {
              name: data.name,
              surname: data.surname,
              telephoneNumber: data.telephoneNumber,
            }
            editUser(userData.id, body)
            // console.log(body)
          } catch (e) {
            // console.log(e)
          }
          setIsLoading(false)
        } else {
          errors.showMessages();
        }
      }
    })
  }
  
  const changePassword = (body) => {
    axiosPrivate.post(`auth/change-password/`, body )
      .then(res => {
        // console.log(res);
        if (res.status === 200) {
          SweetAlert.fire(
            t('edited'),
            t('successEditMsg'),
            'success'
          )
          navigate(-1)
        } else {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('tryAgainMsg'),
            'warning'
          )
        }
      })
      .catch(err => {
        // console.log('error during change pass: ', err)
        if (err.request.status === 409) {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('sameDataUserExist'),
            'warning'
          )
        } else if (err.request.status === 406) {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('oldPasswordIsWrong'),
            'warning'
          )
        } else {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('tryAgainMsg'),
            'warning'
          )
        }
      })
  }

  const onSubmitChangePassword = data => {
    // console.log(data)
    SweetAlert.fire({
      title: t('confirmPasswordChange'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: t('confirm'),
      cancelButtonText: t('cancel'),
      reverseButtons: true
    })
    .then((result) => {
      if (result.value) {
        if (data !== '') {
          // console.log('change password data: ', data)
          // return false
          setIsLoading(true)
          
          try {
            const { old_password, new_password1, new_password2 } = data
            const body = { old_password, new_password1, new_password2 }
            // console.log('body: ', body)
            changePassword(body)
            // console.log(body)
          } catch (e) {
            // console.log(e)
          }
          setIsLoading(false)
        } else {
          errors.showMessages();
        }
      }
    })
  }

  return (
    <Fragment>
      <Breadcrumb parent="Homepage" title="Edit Profile"/>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <LoadingCardSpinner isLoading={isLoading || loading} />
              <CardHeader>
                <h5>{t('editPersonalProfile')}</h5>
              </CardHeader>
              <Form className="form theme-form needs-validation" autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                <CardBody>
                  <Row>
                    <Col>
                    <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('firstName')}*</Label>
                        <Col sm="9">
                        <div className="form-row">
                          <Input
                            className="form-control"
                            name='name'
                            type="text"
                            placeholder="First Name"
                            innerRef={register({ required: true })}
                          />
                          <span className="">{errors.name && t('requiredField')}</span>
                        </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('lastName')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control"
                              name="surname"
                              type="text"
                              placeholder="Last Name"
                              innerRef={register({ required: true })}
                            />
                            <span className="">{errors.surname && t('requiredField')}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('phone')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control m-input digits"
                              name="telephoneNumber"
                              type="number"
                              placeholder="+39 748 734 2935"
                              innerRef={register({ required: true })}
                            />
                            <span className="">{errors.telephoneNumber && t('requiredField')}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('username')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              name="username"
                              className="form-control"
                              type="text"
                              placeholder="Username"
                              innerRef={register({ required: true })} 
                            />
                            <span className="">{errors.username && t('requiredField')}</span>
                          </div>
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col sm="9 offset-sm-3">
                      <Button color="primary" className="mr-1">{t('edit')}</Button>
                      <Button color="light" type="reset" >{t('reset')}</Button>
                    </Col>
                    {/* non editable fields */}
                    <Col className="mt-2">
                      <hr/>
                      <FormGroup className="row" style={ !userData?.company ? { display: 'none' } : {} }>
                        <Label className="col-sm-3 col-form-label">{t('company')}</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control no-border"
                              name='company.companyName'
                              type="text"
                              innerRef={register( )}
                              disabled
                            />
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row" style={ !userData?.holding ? { display: 'none' } : {} }>
                        <Label className="col-sm-3 col-form-label">{t('holding')}</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control no-border"
                              name="holding.companyName"
                              type="text"
                              innerRef={register()}
                              disabled
                            />
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('email')}</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control no-border"
                              name='email'
                              type="text"
                              innerRef={register()}
                              disabled
                            />
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('language')}</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control no-border"
                              name='language.name'
                              type="text"
                              innerRef={register()}
                              disabled
                            />
                          </div>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Form>
              <Form className="form theme-form needs-validation" autoComplete='off' onSubmit={handleSubmitChangePassword(onSubmitChangePassword)}>
                <CardBody>
                  <Row>
                    <Col>
                      <hr/>
                      <h5 className="mt-4 mb-4">{t('editPassword')}</h5>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('oldPassword')}</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control"
                              name="old_password"
                              type="password"
                              placeholder="Password"
                              autoComplete="new-password"
                              innerRef={registerChangePassword({required: true})}
                            />
                            <span className="">{errorsChangePassword.old_password && t('requiredField')}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('newPassword')}</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control"
                              name="new_password1"
                              type="password"
                              placeholder="Password"
                              autoComplete="new-password"
                              innerRef={registerChangePassword({ required: true, minLength: 8 })}
                            />
                            <span className="">{errorsChangePassword.new_password1 && errorsChangePassword.new_password1?.type !== 'minLength' && t('requiredField')}</span>
                            <span className="">{errorsChangePassword.new_password1?.type === 'minLength' && t('minLength', {length: 8})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('repeatPassword')}</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              name='new_password2'
                              className="form-control"
                              type="password"
                              placeholder="Password"
                              autoComplete="new-password"
                              innerRef={
                                registerChangePassword({
                                  minLength: 8,
                                  validate: value => {
                                    const { new_password1 } = getValuesChangePassword()
                                    if ( !new_password1 ) {
                                      return true
                                    } else {
                                      return new_password1 === value
                                    }
                                  },
                                  required: true
                                })
                              }
                            />
                            <span className="">{errorsChangePassword.new_password2 && errorsChangePassword.new_password2?.type !== 'minLength' && t('repeatPasswordRequiredAlert')}</span>
                            <span className="">{errorsChangePassword.new_password2?.type === 'minLength' && t('minLength', {length: 8})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col sm="9 offset-sm-3">
                      <Button color="primary" className="mr-1">{t('saveNewPassword')}</Button>
                      <Button color="light" type="reset" >{t('reset')}</Button>
                    </Col>
                  </Row>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default EditUser;