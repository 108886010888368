import React, { useState, useEffect } from "react";
import { Container,Row,Col,Card,CardHeader, CardBody, InputGroup, InputGroupAddon, InputGroupText,Input } from 'reactstrap';
import DataTable from 'react-data-table-component'
import { Search } from 'react-feather'
import {Link, useNavigate} from 'react-router-dom'
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import {debounce} from 'lodash'

import { useTranslation } from 'react-i18next'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth'
import LoadingCardSpinner from "../../components/LoadingCardSpinner/LoadingCardSpinner";


const ReportIndicatorList = (props) => {
    const {
      handleBuildPathToHeird = ()=>{},
      reportSlug = ''
    } = props

    const { t } = useTranslation(['dataTables', 'forms'])
    const { auth: { role: userRole }, auth } = useAuth()
    const navigate = useNavigate()
    const [data,setData] = useState([])
    const [totalRows, setTotalRows] = useState(0)
    const [loading, setLoading] = useState(false)
    const [perPage, setPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const { axiosPrivate } = useAxiosPrivate()
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)

    //for searching purposes
    const [searchValue, setSearchValue] = useState('')
    const [sortColumn, setSortColumn] = useState('')
    const [sortDescDirection, setSortDescDirection] = useState(false)

    useEffect(() => {
        try {
          getIndicators()
        } catch (e) {
          // console.log(e)
        }
    }, [])

    const getIndicators = async (start = 1, limit = 10, sorting, desc, title) => {
        setLoading(true)
        let params = { start, limit }
        if (sorting) params = { ...params, sorting }
        if (desc) params = { ...params, desc }
        if (title) params = { ...params, title }
    
        const response = await axiosPrivate.get("compilation/"+reportSlug+"/", {params})
            .finally(() => {
            setLoading(false)
            })
        setData(response?.data?.indicators ? [...response.data.indicators] : [])
        setTotalRows(response?.data?.indicators?.length ? response.data.indicators.length : 0)
      }
    
    const handlePageChange = page => {
        setCurrentPage(page)
        getIndicators((page -1) * perPage + 1, perPage)
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        const start = (page - 1) * newPerPage + 1
        const limit = newPerPage
        // await getIndicators(start, limit, sortColumn, sortDescDirection)
        await getIndicators(start, limit)
        setPerPage(newPerPage)
    }

    const onSearch = (query) => {
        getIndicators(1, perPage, false, false, query)
    }

    const debouncedSearch = React.useRef(
        debounce(onSearch, 500)
    ).current

    const handleSort = (column, sortDirection) => {
        const isDescSort = sortDirection === 'desc'
        setSortColumn(column.sortField)
        setSortDescDirection(isDescSort)
        getIndicators(1, perPage, column.sortField, isDescSort)
    }


    const tableColumns = [
        {
            name: t('name'),
            selector: row => row.code,
            sortable: true,
            center:true,
            wrap: true,
            sortField: 'name'
        },
        {
            name: t('description'),
            selector: row => row.description,
            sortable: true,
            center:true,
            wrap: true,
            sortField: 'description'
        },
        {
            name: t('link'),
            selector: row => row.link,
            sortable: false,
            center:true,
            wrap: true,
            sortField: 'link'
        },
        {
            name: t('unitOfMeasurement'),
            selector: row => row.unitOfMeasurement,
            sortable: true,
            center:true,
            wrap: true,
            sortField: 'unitOfMeasurement'
        },
        {
          name: t('company'),
          selector: row => !!row.id_Company ? row.id_Company: <span className="font-italic">{t('n.a.')}</span>,
          sortable: true,
          center:true,
          wrap: true,
          sortField: 'company'
        }
      ]

    
    return (
        <React.Fragment>
          <Container fluid={true}>
            <Row>
              <Col sm="12">
                <Card>
                  <LoadingCardSpinner isLoading={isDeleteLoading || loading} />
                  <CardHeader>
                    <div className='header_filters_container'>
                      <h5 className='datatable_header_title'>{t('associatedIndicators')}</h5>
                      <Row className='justify-content-end'>
                        <Col sm="10"  className="d-flex align-items-center p-t-10 p-b-10">
                          <InputGroup>
                            <Input
                              className="form-control align-self-center"
                              type="search"
                              placeholder={t('search')} 
                              value={searchValue}
                              onChange={event => {
                                setSearchValue(event.target.value)
                                debouncedSearch(event.target.value)
                              }}
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText style={{backgroundColor: 'inherit'}} className="form-control">
                                <Search aria-hidden="true" className="" />
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </Col>
                      </Row>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <DataTable
                      noDataComponent={<h6 className="mb-4 mt-4">{t('noResults')}</h6> }
                      paginationComponentOptions={{rowsPerPageText: t('rowsPerPage'), rangeSeparatorText: t('of')}}
                      data={data}
                      columns={tableColumns}
                      striped={true}
                      center={true}
                      persistTableHead
                      highlightOnHover
                      pointerOnHover
                      pagination
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      paginationTotalRows={totalRows}
                      paginationServer
                      sortServer
                      onSort={handleSort}
                      onRowClicked={(row) => navigate(`/edit-report/${reportSlug}/indicator/${row.slug}`, {state: {...handleBuildPathToHeird(row), indicatorName:row.code} })}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>            
        </React.Fragment>
      )
}

export default ReportIndicatorList