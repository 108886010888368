import React, { Fragment, useState, useMemo, useEffect  } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import {Container,Row,Col,Card,CardHeader,CardBody,CardFooter,Button,Form,FormGroup,Label,Input} from 'reactstrap'
import {EmailAddress,Password,Submit,Cancel} from '../../constant'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import SweetAlert from 'sweetalert2'
import { useForm } from 'react-hook-form'
import LoadingCardSpinner from '../LoadingCardSpinner/LoadingCardSpinner'

const EditHolding = () => {

  const { t } = useTranslation('forms')
  const location = useLocation()
  const navigate = useNavigate()
  const { holdingId, holdingSlug } = location.state
  const [isLoading, setIsLoading] = useState(false)
  const { axiosPrivate, usePrivateAxiosHook } = useAxiosPrivate()
  const [{ data: holdingData, loading, error }, refetch] = usePrivateAxiosHook(`holding/${holdingSlug}/`)
  const { register, handleSubmit, reset, errors, getValues } = useForm({
    defaultValues: useMemo(() => {
      return holdingData
    }, [holdingData])
  })

  useEffect(() => {
    reset(holdingData)
  }, [holdingData])

  const editCompany = (slug, body) => {
    // console.log('holding data: ', body)
    axiosPrivate.patch(`holding/${slug}/`, body )
      .then(res => {
        // console.log('edit company res', res);
        if (res.status === 200) {
          SweetAlert.fire(
            t('edited'),
            t('successEditMsg'),
            'success'
          )
          navigate(-1)
        } else {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('tryAgainMsg'),
            'warning'
          )
        }
      })
      .catch(err => {
        // console.log('error: ', err)
        if (err.request.status === 409) {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('sameHoldingDataExist'),
            'warning'
          )
        } else {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('tryAgainMsg'),
            'warning'
          )
        }
      })
  }

  const onSubmit = data => {
    SweetAlert.fire({
      title: t('confirmEditMsg'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: t('confirm'),
      cancelButtonText: t('cancel'),
      reverseButtons: true
    })
    .then((result) => {
      if (result.value) {
        if (data !== '') {
          setIsLoading(true)
          try {
            const body = {
              VATNumber: data.VATNumber,
              companyName: data.companyName
            }
            editCompany(holdingData.slug, body)
          } catch (e) {
            // console.log(e)
          }
          setIsLoading(false)
        } else {
          errors.showMessages();
        }
      }
    })
  }

  return (
    <Fragment>
      <Breadcrumb parent="Holding" title="Edit Holding"/>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <LoadingCardSpinner isLoading={isLoading || loading}/>
              <CardHeader>
                <h5>{t('editHolding')}</h5>
              </CardHeader>
              <Form className="form theme-form needs-validation" autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                <CardBody>
                  <Row>
                    <Col>
                    <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('businessName')}</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control"
                              name="companyName"
                              type="text"
                              placeholder={t('businessNamePlaceholder')} 
                              innerRef={register({ required: true, maxLength: 200 })}
                              />
                            <span className="">{errors.companyName?.type === 'required' && t('requiredField')}</span>
                            <span className="">{errors.companyName?.type === 'maxLength' && t('lengthLimit', {length: 200})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('VAT')}</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control"
                              name='VATNumber'
                              type="number"
                              placeholder={t('VATPlaceholder')}
                              innerRef={register({ required: true, maxLength: 200 })}
                            />
                            <span className="">{errors.VATNumber?.type === 'required' && t('requiredField')}</span>
                            <span className="">{errors.VATNumber?.type === 'maxLength' && t('lengthLimit', {length: 200})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <Col sm="9 offset-sm-3">
                        <Button color="primary" className="mr-1">{t('edit')}</Button>
                        {/* <Button color="light" type="reset" >{t('reset')}</Button> */}
                      </Col>
                      {/* non editable fields */}
                    </Col>
                  </Row>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default EditHolding;