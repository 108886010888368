import React, { Fragment,useState,useEffect } from 'react';

const Loader = () => {

    return (
        <Fragment>
            <div className={`loader-wrapper`}>
                <div className="loader-box">
                    <div className="loader-19"></div>
                </div>
            </div>
        </Fragment>
    );
}

export default Loader;