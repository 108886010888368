import axios from '../api/axios';
import useAuth from './useAuth';
import Cookies from 'js-cookie'

const useRefreshToken = () => {
    const { setAuth } = useAuth()
    const refresh = async () => {
        const refreshToken = Cookies.get('refresh')
        const response = await axios.get('auth/refresh-token/', { headers: { 'Authorization': 'Bearer ' + refreshToken } });
        setAuth(prev => {
            return {
                ...prev,
                role: response.data.user.role,
                accessToken: response.data.access,
                id: response.data.user.id,
                language: response.data.user.language.acronym,
                username: response.data.user.username,
                holdingSlug: response?.data?.user?.holding?.slug,
                holdingId: response?.data?.user?.holding?.id,
                companySlug: response?.data?.user?.company?.slug,
                companyId: response?.data?.user?.company?.id
            }
        });
        return response.data.access;
    }
    return refresh;
};

export default useRefreshToken;
