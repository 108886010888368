import React, { createContext, useState } from "react"

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});
    const [persist, setPersist] = useState(JSON.parse(localStorage.hasOwnProperty('persist') ? localStorage.getItem("persist") : true));

    return (
      <AuthContext.Provider value={{ auth, setAuth, persist, setPersist }}>
        {children}
      </AuthContext.Provider>
    )
}

export default AuthContext;