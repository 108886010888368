import React, { Fragment, useState, useRef } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container,Row,Col,Card,CardHeader,CardBody,CardFooter,Button,Form,FormGroup,Label,Input } from 'reactstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'

import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import SweetAlert from 'sweetalert2'
import LoadingCardSpinner from '../LoadingCardSpinner/LoadingCardSpinner'
import useAuth from '../../hooks/useAuth'
import ROLES from '../../utils/roles'

const CreateHeadquarters = () => {
  const { auth: { role: userRole }, auth } = useAuth()
  const [companySelection, setCompanySelection] = useState([])
  const { t } = useTranslation('forms')
  const { register, handleSubmit, errors, getValues } = useForm()
  const [companies, setCompanies] = useState([])
  const [ companyError, setCompanyError ] = useState(false)
  const { axiosPrivate, usePrivateAxiosHook } = useAxiosPrivate()
  const [isLoading, setIsLoading] = useState(false)
  const formRef = useRef()
  const [
    { data: {results: companyData} = {}, loading, error },
      refetch
    ] = usePrivateAxiosHook({
      url: function () {
        if ([ROLES.superadmin, ROLES.admin].includes(userRole)) {
          return `company/`
        } else if ([ROLES.groupleader].includes(userRole)) {
          return `/holding/${auth.holdingSlug}/company/`
        }
      }()
    }, { manual: userRole === ROLES.companymanager})

  const onSubmit = data => {
      setCompanyError(false)
      SweetAlert.fire({
        title: t('confirmCreationMsg'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: t('confirm'),
        cancelButtonText: t('cancel'),
        reverseButtons: true
      })
      .then((result) => {
        if (result.value) {
          if (data !== '') {
            // console.log('data user: ', data)
            setIsLoading(true)
            try {
              const body = {
                name: data.name,
                country: data.country,
                city: data.city,
                zipCode: data.zipCode,
                streetName: data.streetName,
                addressNumber: data.addressNumber
              }
              let slug = ''
              if ([ROLES.companymanager].includes(userRole)) {
                slug = auth.companySlug
              } else if ([ROLES.admin, ROLES.superadmin, ROLES.groupleader].includes(userRole)) {
                slug = companySelection[0].slug
              }
              axiosPrivate.post(`company/${slug}/headquarter/`, body )
                .then(res => {
                  if (res.status === 201) {
                    SweetAlert.fire(
                      t('saved'),
                      t('successCreationMsg'),
                      'success'
                    )
                    formRef.current.reset()
                    setCompanySelection([])
                  } else {
                    SweetAlert.fire(
                      t('somethingWentWrongMsg'),
                      t('tryAgainMsg'),
                      'warning'
                    )
                  }
                })
                .catch(err => {
                  // console.log('error: ', err)
                  if (err.request.status === 409) {
                    SweetAlert.fire(
                      t('somethingWentWrongMsg'),
                      t('sameHeadquarterDataExist'),
                      'warning'
                    )
                  } else {
                    SweetAlert.fire(
                      t('somethingWentWrongMsg'),
                      t('tryAgainMsg'),
                      'warning'
                    )
                  }
                })
              // console.log(body)
            } catch (e) {
              // console.log(e)
            }
            setIsLoading(false)
          } else {
            errors.showMessages();
          }
        }
      })
  }

  return (
    <Fragment>
      <Breadcrumb parent="Headquarters" title="Create Headquarter"/>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <LoadingCardSpinner isLoading={loading || isLoading} />
              <CardHeader>
                <h5>{t('createHeadquarter')}</h5>
              </CardHeader>
              <Form className="form theme-form needs-validation" autoComplete='off' onSubmit={handleSubmit(onSubmit)}  innerRef={formRef}>
                <CardBody>
                  <Row>
                    <Col>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('firstName')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control"
                              name='name'
                              type="text"
                              placeholder="Headquarter Name"
                              innerRef={register({ required: true, maxLength: 100 })}
                            />
                            <span className="">{errors.name?.type === 'required' && t('requiredField')}</span>
                            <span className="">{errors.name?.type === 'maxLength' && t('lengthLimit', {length: 100})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      {[ROLES.superadmin, ROLES.admin, ROLES.groupleader].includes(userRole) && <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('company')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Typeahead
                              style={{ width: '100%' }}
                              id="select-preset-indicators"
                              labelKey="companyName"
                              onChange={setCompanySelection}
                              options={companyData || []}
                              placeholder="Seleziona Azienda"
                              selected={companySelection}
                              isValid={!!companySelection.length}
                              isInvalid={!companySelection.length}
                              inputProps={{ required: true }}
                            />
                          </div>
                        </Col>
                      </FormGroup>}
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('country')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control"
                              name='country'
                              type="text"
                              placeholder="State"
                              innerRef={register({ required: true, maxLength: 100 })}
                            />
                            <span className="">{errors.country?.type === 'required' && t('requiredField')}</span>
                            <span className="">{errors.country?.type === 'maxLength' && t('lengthLimit', {length: 100})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('city')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control"
                              name='city'
                              type="text"
                              placeholder="City"
                              innerRef={register({ required: true, maxLength: 100 })}
                            />
                            <span className="">{errors.city?.type === 'required' && t('requiredField')}</span>
                            <span className="">{errors.city?.type === 'maxLength' && t('lengthLimit', {length: 100})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('address')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control"
                              name='streetName'
                              type="text"
                              placeholder="Street Name"
                              innerRef={register({ required: true, maxLength: 100 })}
                            />
                            <span className="">{errors.streetName?.type === 'required' && t('requiredField')}</span>
                            <span className="">{errors.streetName?.type === 'maxLength' && t('lengthLimit', {length: 100})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('zipCode')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                              <Input
                                className="form-control"
                                name='zipCode'
                                type="number"
                                placeholder="ZIP"
                                innerRef={register({ required: true, maxLength: 100 })}
                              />
                              <span className="">{errors.zipCode?.type === 'required' && t('requiredField')}</span>
                              <span className="">{errors.zipCode?.type === 'maxLength' && t('lengthLimit', {length: 100})}</span>
                            </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('addressNumber')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                              <Input
                                className="form-control"
                                name='addressNumber'
                                type="number"
                                placeholder="Adress number"
                                innerRef={register({ required: true, maxLength: 100 })}
                              />
                              <span className="">{errors.addressNumber?.type === 'required' && t('requiredField')}</span>
                              <span className="">{errors.addressNumber?.type === 'maxLength' && t('lengthLimit', {length: 100})}</span>
                            </div>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Col sm="9 offset-sm-3">
                    <Button color="primary" className="mr-1">{t('save')}</Button>
                    <Button color="light" type="reset" >{t('reset')}</Button>
                  </Col>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CreateHeadquarters;