import React from 'react';
import {Container,Row,Col,Form,FormGroup,Label,Input,Button} from 'reactstrap' 
import { NewPassword,RetypePassword,Done,Resend,ResetPassword, RememberPassword, SignIn,Send} from "../../constant";
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import axios from '../../api/axios'
import SweetAlert from 'sweetalert2'
import { useNavigate } from 'react-router-dom'

const Forgetpwd = (props) => {
  const navigation = useNavigate()
  const { axiosPrivate, usePrivateAxiosHook } = useAxiosPrivate()
  const { t } = useTranslation('forms')
  const { t: tReset } = useTranslation('resetPassword')
  const [togglePassword,setTogglePassword] = useState(false)
  const [password,setPassword] = useState("")
  const [isLoadingEmailSend, setIsLoadingEmailSend] = useState(false)
  const [isLoadingPasswordSend, setIsLoadingPasswordSend] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [errCodeMsg, setErrCodeMsg] = useState('')
  const [showPasswordForm, setShowPasswordForm] = useState(false)

  const { 
    register: registerEmailForm,
    handleSubmit: handleSubmitEmailForm,
    errors: errorsEmailForm,
    getValues: getValuesEmailForm,
    watch: watchEmailForm,
    reset: resetEmailForm
  } = useForm()

  const { 
    register: registerPwdForm,
    handleSubmit: handleSubmitPwdForm,
    errors: errorsPwdForm,
    getValues: getValuesPwdForm,
    watch: watchPwdForm,
    reset: resetPwdForm
  } = useForm()

  const checkEmailRequest = (body) => {
    setErrMsg('')
    setIsLoadingEmailSend(true)
    axios.post('auth/recovery-password/email/',
      JSON.stringify(body),
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        setShowPasswordForm(true)
      })
      .catch(err => {
        if (err.response?.status === 409) {
          setErrMsg(tReset('mailNotExist'))
        } else {
          setErrMsg(tReset('noServerResponse'))
        }
      })
      .finally(() => {
        setIsLoadingEmailSend(false)
      })
  }

  const sendEmail = (data) => {
    const body = {
      email: data.email
    }
    checkEmailRequest(body)
  }

  const changePasswordRequest = (body) => {
    setErrCodeMsg('')
    setIsLoadingPasswordSend(true)
    axios.post('auth/recovery-password/change/',
      JSON.stringify(body),
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        SweetAlert.fire(
          t('edited'),
          t('successEditMsg'),
          'success'
        )
        navigation('/login/')
      })
      .catch(err => {
        if (err.response?.status === 404) {
          setErrCodeMsg(tReset('wrongCode'))
        } else {
          setErrCodeMsg(tReset('noServerResponse'))
        }
      })
      .finally(() => {
        setIsLoadingPasswordSend(false)
      })
  }

  const changePassword = (data) => {
    const body = {
      code: data.code,
      new_password1: data.password,
      new_password2: data.passwordMatch
    }
    changePasswordRequest(body)
  }

  const handleChange = (e) => {
    setPassword(e.target.value)
  }
  const HideShowPassword  = (tPassword) => {
    setTogglePassword(!tPassword)
  }

  const renderEmailForm = () => (
    <Form className="theme-form" onSubmit={handleSubmitEmailForm(sendEmail)}>
      <h4>{tReset('resetPassword')}</h4>
      <span className="danger-text-color">{!!errMsg && errMsg}</span>
      <FormGroup>
        <Label className="col-form-label">{tReset('enterEmail')}</Label>
        <Row>
          <Col md="12">
            <Input
              className="form-control mb-1"
              type="email"
              placeholder="example@mail.com"
              name='email'
              innerRef={registerEmailForm({ required: true })}
            />
            <span className="danger-text-color">{errorsEmailForm.email && t('requiredField')}</span>
          </Col>
          <Col xs="12">
            <Button
              color="primary"
              className="btn-block m-t-10"
              type="submit"
              disabled={isLoadingEmailSend}
            >
              {tReset('sendEmailCode')}
            </Button>
          </Col>
        </Row>
      </FormGroup>
    </Form>
  )

  const renderNewPasswordForm = () => (
    <Form onSubmit={handleSubmitPwdForm(changePassword)}>
      <div className="text-center mt-4 mb-4">
        <span className="reset-password-link">{tReset('dontReceiveCode')}{tReset('resend')}
        </span>
      </div>
      <h6 className="mt-4">{tReset('createPassword')}</h6>
      <span className="danger-text-color">{!!errCodeMsg && errCodeMsg}</span>
      <FormGroup>
        <Label className="col-form-label pt-0">{tReset('enterCode')}</Label>
        <Row>
          <Col>
            <Input
              className="form-control text-center opt-text"
              type="text"
              name="code"
              placeholder="6-digits"
              maxLength="6"
              minLength="6"
              innerRef={registerPwdForm({ required: true })}  
            />
            <span className="danger-text-color">{errorsPwdForm.code && t('requiredField')}</span>
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Label className="col-form-label">{t('newPassword')}</Label>
        <Input
          className="form-control"
          name="password"
          type={togglePassword ? "text" : "password" }
          placeholder="Password"
          innerRef={registerPwdForm({ required: true, minLength: 8 })}
        />
        <span className="danger-text-color">{errorsPwdForm.password && errorsPwdForm.password?.type !== 'minLength' && t('requiredField')}</span>
        <span className="danger-text-color">{errorsPwdForm.password?.type === 'minLength' && t('minLength', {length: 8})}</span>
        <div className="" onClick={() => HideShowPassword(togglePassword)}><span className={togglePassword ? "" : "show"}></span></div>
      </FormGroup>
      <FormGroup>
        <Label className="col-form-label">{t('repeatPassword')}</Label>
        <Input
          name='passwordMatch'
          className="form-control"
          type="password"
          placeholder="Password"
          innerRef={registerPwdForm({ required: true, minLength: 8, validate: value => {
            const { password } = getValuesPwdForm()
            return password === value
          }})}
        />
        <span className="danger-text-color">{errorsPwdForm.passwordMatch && errorsPwdForm.passwordMatch?.type !== 'minLength' && t('repeatPasswordRequiredAlert')}</span>
        <span className="danger-text-color">{errorsPwdForm.passwordMatch?.type === 'minLength' && t('minLength', {length: 8})}</span>
      </FormGroup>
      <FormGroup className="mb-0">
        <Button
          color="primary"
          className="btn-block"
          type="submit"
          disabled={isLoadingPasswordSend}
        >
          {tReset('change')}
        </Button>
      </FormGroup>
    </Form>
  )

  return (
    <Container fluid={true}>
    <Row>
        <Col xs="12">     
          <div className="login-card">
            <div>
              <div className="login-main"> 
                {renderEmailForm()}
                {showPasswordForm && renderNewPasswordForm()}
                <p className="mt-4 mb-0 d-flex justify-content-center">{tReset('alreadyHavePassword')}
                  <Link className="ml-2" to="/login/">{tReset('signIn')}</Link>
                </p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Forgetpwd;