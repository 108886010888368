import React, {useState,Fragment} from 'react';
import {Container,Row,Col,Card,CardHeader,CardBody,InputGroup, InputGroupAddon, InputGroupText,Input, Button} from 'reactstrap'

import 'react-datepicker/dist/react-datepicker.css'

import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate,useParams, Link } from 'react-router-dom'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import LoadingCardSpinner from '../../../components/LoadingCardSpinner/LoadingCardSpinner'
import useAuth from '../../../hooks/useAuth'
import { useEffect } from 'react';
import Breadcrumbs from '../../../layout/breadcrumb';
import { debounce, last } from 'lodash';
import { Search, ChevronLeft } from 'react-feather';
import DataTable from 'react-data-table-component';
import CompileReportsTable from './CompileReportsTable';
import { t } from 'i18next';

const ExploreIndicator = (props)=>{
  const location = useLocation()
  console.log(location.state);

  const {id:reportSlug, indicator:indicatorSlug} = useParams()
  const indicatorName = location.state.indicatorName
  const [subindicatorSlugs, setSubindicatorSlugs] = useState([])
  const [subindicatorNames, setSubindicatorNames] = useState([])
  const [description, setDescription] = useState("")

  const { t } = useTranslation('forms')
  const { auth: { role: userRole }, auth } = useAuth()
  const navigate = useNavigate()
  const [isTableLoading, setIsTableLoading] = useState(false)
  const { axiosPrivate } = useAxiosPrivate()

  useEffect(()=>{
    setIsTableLoading(true)
    let _ = location.state.pathToHeird
    setSubindicatorSlugs(_.subindicators.slugs)
    setSubindicatorNames(_.subindicators.names)
    setDescription(_.info.description)
  },[location, location.state])
  useEffect(()=>{
    getSubindicators && getSubindicators()
    setIsTableLoading(false)
  },[subindicatorSlugs])

  const buildPathToHeird = (currentSubindicator) => {
    return {
      pathToHeird:{
        subindicators:{
          slugs: [...subindicatorSlugs, currentSubindicator.slug],
          names: [...subindicatorNames, currentSubindicator.name]
        },
        info:{
          description:currentSubindicator.description
        }
      } 
    }
  }

  
  const buildPathToIndicator = ()=>{
    return {
      pathToHeird:{
        subindicators:{
          slugs: [],
          names: []
        },
        info:{
          description:""
        }
      } 
    }
  }


  //**************TABLE LOGIC**************//
  const [data,setData] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState(false)
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)

  const [isDeleteLoading, setIsDeleteLoading] = useState(false)

  //for searching purposes
  const [searchValue, setSearchValue] = useState('')
  const [sortColumn, setSortColumn] = useState('')
  const [sortDescDirection, setSortDescDirection] = useState(false)

  function isIndicatorScreening() { return subindicatorSlugs.length === 0}

  const getSubindicators = async (start = 1, limit = 10, sorting, desc, title) => {
    setLoading(true)
    let params = { start, limit }
    if (sorting) params = { ...params, sorting }
    if (desc) params = { ...params, desc }
    if (title) params = { ...params, title }

    const endpoint = isIndicatorScreening()? "indicator/"+indicatorSlug+"/subindicators/": "subindicator/"+last(subindicatorSlugs)+"/subindicators/"
    const response = await axiosPrivate.get(endpoint, {params})
      .finally(() => {
        setLoading(false)
      })
    
    setData(response?.data?.results ? [...response.data.results] : [])
    setTotalRows(response?.data?.count ? response.data.count : 0)
  }

  const handlePageChange = page => {
    setCurrentPage(page)
    getSubindicators((page - 1) * perPage + 1, perPage)
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    const start = (page - 1) * newPerPage + 1
    const limit = newPerPage
    // await getSubindicators(start, limit, sortColumn, sortDescDirection)
    await getSubindicators(start, limit)
    setPerPage(newPerPage)
  }

  const onSearch = (query) => {
      getSubindicators(1, perPage, false, false, query)
  }

  const debouncedSearch = React.useRef(
      debounce(onSearch, 500)
  ).current

  const handleSort = (column, sortDirection) => {
      const isDescSort = sortDirection === 'desc'
      setSortColumn(column.sortField)
      setSortDescDirection(isDescSort)
      getSubindicators(1, perPage, column.sortField, isDescSort)
  }

  const tableColumns = [
    {
        name: t('name'),
        selector: row => row.name,
        sortable: true,
        center:true,
        wrap: true,
        sortField: 'name'
    },
    {
        name: t('description'),
        selector: row => row.description,
        sortable: true,
        center:true,
        wrap: true,
        sortField: 'description'
    },
    {
        name: t('unitOfMeasurement'),
        selector: row => row.unitOfMeasurement,
        sortable: true,
        center:true,
        wrap: true,
        sortField: 'unitOfMeasurement'
    },
    {
      name: t('company'),
      selector: row => !!row.id_Company ? row.id_Company: <span className="font-italic">{t('n.a.')}</span>,
      sortable: true,
      center:true,
      wrap: true,
      sortField: 'company'
    }
  ]
  //******************END******************//
  return (
    <Fragment>
      <Breadcrumbs parent="Indicator" title="Compile subindicators"/>
      <Container fluid={true} >
        <Row>
          <Col sm="12">
            <Card>
              <LoadingCardSpinner isLoading={isDeleteLoading || loading} />
              <CardHeader>
                <div className='header_filters_container'>
                  <div className="datatable_header">
                    <Link className="datatable_header_caption mb-1 cursor-pointer" to={-1}>
                      <ChevronLeft />
                      <span>{t('back')}</span>
                    </Link>
                    <h5 className='mb-2'> {t(indicatorName)} </h5>
                    {/* <a className="unstyle-anchor" onClick={()=>{navigate('/edit-report/'+reportSlug, {state:{...buildPathToIndicator(), indicatorName: indicatorName}} )}}>
                      <h5 className='datatable_header_title mb-2 cursor-pointer'> {t(indicatorName)} </h5>
                    </a> */}
                    <h6 className='datatable_header_subtitle'>{t(subindicatorNames.join(' / '))}</h6>
                    <h6 className='font-weight-normal mt-2'>{description && t("description") + ": " + t(description)}</h6>

                  </div>
                </div>
              </CardHeader>
              {
                !isIndicatorScreening() && <CompileReportsTable 
                  subindicatorSlug = {last(subindicatorSlugs)} 
                  actualReportSlug = {reportSlug}
                />
              }
            </Card>
          </Col>
        </Row>
      </Container>
      
      <Container fluid={true} >
        <Row>
          <Col sm="12">
            <Card>
              <LoadingCardSpinner isLoading={isDeleteLoading || loading} />
              <CardHeader>
                <Container fluid={true} >
                  <Row className='justify-content-between'>
                    <Col sm="6" md="3" className='header_filters_container my-3'>
                      <h5 className='datatable_header_title mb-2'> {t('subindicatorsList')} </h5>
                    </Col>
                    <Col sm="6" md="3" className='my-3 d-flex justify-content-center'>
                      { !isIndicatorScreening() && <NextFieldButton subindicatorSlug={last(subindicatorSlugs)} forward={{reportSlug, indicatorSlug, indicatorName}}/>}
                    </Col>
                    <Col sm="6" md="3" className='my-3'>
                      <InputGroup>
                        <Input
                          className="form-control align-self-center"
                          type="search"
                          placeholder={t('search')} 
                          value={searchValue}
                          onChange={event => {
                            setSearchValue(event.target.value)
                            debouncedSearch(event.target.value)
                          }}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText style={{backgroundColor: 'inherit'}} className="form-control">
                            <Search aria-hidden="true" className="" />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </Row>
                </Container>
              </CardHeader>
              <CardBody>
                <DataTable
                  noDataComponent={<h6 className="mb-4 mt-4">{t('noResults')}</h6> }
                  paginationComponentOptions={{rowsPerPageText: t('rowsPerPage'), rangeSeparatorText: t('of')}}
                  data={data}
                  columns={tableColumns}
                  striped={true}
                  center={true}
                  persistTableHead
                  highlightOnHover
                  pointerOnHover
                  pagination
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  paginationTotalRows={totalRows}
                  paginationServer
                  sortServer
                  onSort={handleSort}
                  // forward current path to subindicator
                  onRowClicked={(row) => navigate(`/edit-report/${reportSlug}/indicator/${indicatorSlug}`, { state: {...buildPathToHeird(row), indicatorName:indicatorName} })}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      
    </Fragment>
  );
}

const NextFieldButton = ({subindicatorSlug,forward:{reportSlug, indicatorSlug, indicatorName}}) =>{
  const { axiosPrivate } = useAxiosPrivate()
  const { t } = useTranslation('forms')
  const navigate = useNavigate()
  const [skipPathToHeird, setSkipPathToHeird] = useState(null)
  const [compiled, setCompiled] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(()=>{
    axiosPrivate.get('subindicator/'+subindicatorSlug+'/subindicators/')
      .then(res => {
        let skip = res.data.skip
        if(!skip) setCompiled(true)
        else {
          try{
            let slugs = []
            let names = []
            let description = skip.description
            for(let current = skip; !!current; current = current.parent){
              slugs = [current.slug, ...slugs]
              names = [current.name, ...slugs]
            }
            setSkipPathToHeird({
              pathToHeird:{
                subindicators:{
                  slugs,
                  names
                },
                info:{
                  description
                }
              } 
            })
          }catch(e){ 
            console.log(e);
            setError(e)
          }finally{ 
            setLoading(false)
          }
        }
      })
  },[subindicatorSlug])
  
  return (
    <>
      {
        loading
        ? <span className='font-italic'>{t('loading')}</span>
        : error
          ? <span className='font-italic' style={{"color":"red"}}>{t('pleaseReload')}</span>
          : compiled
            ? <span className='font-italic'>{t('compiledIndicator')}</span>
            : !!skipPathToHeird && <Button 
                className='btn-sm'
                onClick={()=>
                  navigate(
                    `/edit-report/${reportSlug}/indicator/${indicatorSlug}`, 
                    { state: {...skipPathToHeird, indicatorName:indicatorName} }
                  )
              }> {t('continue')} </Button>
      }
    </>
  )
  

}


export default ExploreIndicator