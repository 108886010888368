import { Box, FileText, Users, Edit, Package, User, Edit2, Sliders } from 'react-feather'

const usersPaths = {
    menutitle:"usersTitle",
    menucontent:"",
    Items:[
        { path: `/create-user`,icon:User, title: 'createUsers', type: 'link' },
        { path: `/manage-user`,icon:Users, title: 'manageUsers', type: 'link' }
    ]
}

const companyPaths = {
    title: 'companies',icon:Box, type: 'sub',badge: "badge badge-danger",active:false, children: [
        { path: `/create-company`,icon:Edit, title: 'createCompany', type: 'link' },
        { path: `/manage-company`,icon:Package, title: 'manageCompany', type: 'link' },
    ]
}

const holdingPaths = {
    title: 'holding',icon:Box, type: 'sub',badge: "badge badge-danger",active:false, children: [
        { path: `/create-holding`,icon:Edit, title: 'createHolding', type: 'link' },
        { path: `/manage-holding`,icon:Package, title: 'manageHolding', type: 'link' },
    ]
}

const headquarterPaths = {
    title: 'headquarters',icon:Box, type: 'sub',badge: "badge badge-danger",active:false, children: [
        { path: `/create-headquarters`,icon:Edit, title: 'createHeadquarters', type: 'link' },
        { path: `/manage-headquarters`,icon:Package, title: 'manageHeadquarters', type: 'link' },
    ]
}

const indicatorsPath = (create = true, manage = true) => {
    const items = []
    if (create) items.push({ path: `/create-indicators`,icon:Sliders, title: 'createIndicator', type: 'link' })
    if (manage) items.push({ path: `/manage-indicators`,icon:Sliders, title: 'manageIndicators', type: 'link' })
    return {
        title: 'indicators',icon:Edit2, type: 'sub',badge: "badge badge-danger",active:false, children: items
    }
}

const subindicatorsPath = (create = true, manage = true) => {
    const items = []
    if (create) items.push({ path: `/create-subindicators`,icon:Sliders, title: 'createSubindicators', type: 'link' })
    if (manage) items.push({ path: `/manage-subindicators`,icon:Sliders, title: 'manageSubindicators', type: 'link' })
    return { title: 'subindicators',icon:Edit2, type: 'sub',badge: "badge badge-danger",active:false, children: items }
}

const presetPath = {
    title: 'preset',icon:Sliders, type: 'sub',badge: "badge badge-danger",active:false, children: [
        { path: `/create-presets`,icon:Sliders, title: 'createPreset', type: 'link' },
        { path: `/manage-presets`,icon:Sliders, title: 'managePreset', type: 'link' },
    ]
}

const reportPath = (create = true, manage = true) => {
    const items = []
    if (create) items.push({ path: `/create-report`, title: 'createReport', type: 'link' })
    if (manage) items.push({ path: `/manage-report`, title: 'manageReport', type: 'link' })
    return { title: 'Report',icon:FileText, type: 'sub',badge: "badge badge-danger",active:false, children: items }
}

const companyAnagraphy = (company = true, holding = true, headquarter = true) => {
    const items = []
    if (company) items.push(companyPaths)
    if (holding) items.push(holdingPaths)
    if (headquarter) items.push(headquarterPaths)
    return {
        menutitle:"Anagrafica Aziende",
        menucontent:"",
        Items: items
    }
}

const indicatorsAnagraphy = (indicators = true, subindicator = true, preset = true, createIndicators = true, manageIndicators = true, createSubindicators = true, manageSubindicators = true) => {
    const items = []
    if (indicators) items.push(indicatorsPath(createIndicators, manageIndicators))
    if (subindicator) items.push(subindicatorsPath(createSubindicators, manageSubindicators))
    if (preset) items.push(presetPath)
    return {
        menutitle:"Indicatori",
        menucontent:"",
        Items: items
    }
}
const dataAnagraphy = (report = true, createReport = true, manageReport = true, visitOverview = true) => {
    const items = []
    if (report) items.push(reportPath(createReport, manageReport))
    if (visitOverview) items.push({ path: `/overview`,icon:FileText, title: 'overview', type: 'link' })
    return {
        menutitle:"Dati",
        menucontent:"",
        Items: items
    }
}
export const MENUITEMS_ADMIN = [
    companyAnagraphy(),
    usersPaths,
    indicatorsAnagraphy(),
    dataAnagraphy()
]

export const MENUITEMS_GROUPLEADER = [
    companyAnagraphy(true, false, true),
    usersPaths,
    indicatorsAnagraphy(true, true, false, false, true, false)
]

export const MENUITEMS_COMPANYMANAGER = [
    companyAnagraphy(false, false, true),
    usersPaths,
    indicatorsAnagraphy(),
    dataAnagraphy()
]

export const MENUITEMS_DATAMANAGER = [
    dataAnagraphy(true, false, false, false)
]

export const MENUITEMS_OLD = [
    {
        menutitle:"Anagrafica Utenti",
        menucontent:"",
        Items:[
            { path: `/create-user`,icon:User, title: 'Creazione utenti', type: 'link' },
            { path: `/manage-user`,icon:Users, title: 'Gestione utenti', type: 'link' }
        ]

    },
    {
        menutitle:"Anagrafica Aziende",
        menucontent:"",
        Items:[
            {
                title: 'Aziende',icon:Box, type: 'sub',badge: "badge badge-danger",active:false, children: [
                    { path: `/create-company`,icon:Edit, title: 'Creazione aziende', type: 'link' },
                    { path: `/manage-company`,icon:Package, title: 'Gestione aziende', type: 'link' },
                ]
            },
            {
                title: 'Holding',icon:Box, type: 'sub',badge: "badge badge-danger",active:false, children: [
                    { path: `/create-holding`,icon:Edit, title: 'Creazione holding', type: 'link' },
                    { path: `/manage-holding`,icon:Package, title: 'Gestione holding', type: 'link' },
                ]
            },
            {
                title: 'Sedi',icon:Box, type: 'sub',badge: "badge badge-danger",active:false, children: [
                    { path: `/create-headquarters`,icon:Edit, title: 'Creazione sedi', type: 'link' },
                    { path: `/manage-headquarters`,icon:Package, title: 'Gestione sedi', type: 'link' },
                ]
            },
        ]
    },
    {
        menutitle:"Indicatori",
        menucontent:"",
        Items:[
            {
                title: 'Indicatori',icon:Edit2, type: 'sub',badge: "badge badge-danger",active:false, children: [
                    { path: `/create-indicators`,icon:Sliders, title: 'Creazione Indicatore', type: 'link' },
                    { path: `/manage-indicators`,icon:Sliders, title: 'Gestione Indicatori', type: 'link' },
                ]
            },
            {
                title: 'Sotto Indicatori',icon:Edit2, type: 'sub',badge: "badge badge-danger",active:false, children: [
                    { path: `/create-subindicators`,icon:Sliders, title: 'Creazione Sotto Indicatore', type: 'link' },
                    { path: `/manage-subindicators`,icon:Sliders, title: 'Gestione Sotto Indicatori', type: 'link' },
                ]
            },
            {
                title: 'Preset',icon:Sliders, type: 'sub',badge: "badge badge-danger",active:false, children: [
                    { path: `/create-presets`,icon:Sliders, title: 'Creazione Preset', type: 'link' },
                    { path: `/manage-presets`,icon:Sliders, title: 'Gestione Preset', type: 'link' },
                ]
            },
        ]
    },
    {
        menutitle:"Dati",
        menucontent:"",
        Items:[
            {
                title: 'Raccolta Dati',icon:FileText, type: 'sub',badge: "badge badge-danger",active:false, children: [
                    { path: `/create-data-collection`, title: 'Crea Raccolta Dati', type: 'link' },
                    { path: `/manage-data-collection`, title: 'Gestione Raccolta Dati', type: 'link' },
                ]
            },
            {
                title: 'Rendicontazione',icon:FileText, type: 'sub',badge: "badge badge-danger",active:false, children: [
                    { path: `/create-reporting-period`, title: 'Crea Periodo Rendicontazione', type: 'link' },
                    { path: `/manage-reporting-period`, title: 'Gestione Periodo Rendicontazione', type: 'link' },
                ]
            }
        ]
    }
]
