import React, { Fragment,useState,useEffect, forwardRef, useRef} from 'react';
import DataTable from 'react-data-table-component'
import { Container,Row,Col,Card,CardHeader, CardBody, FormGroup, Label, Form, Button, Input, CardFooter, Tooltip} from 'reactstrap'
import { useTranslation } from 'react-i18next'

import { useForm } from 'react-hook-form'
// import DatePicker from "react-datepicker";
import Breadcrumb from '../../layout/breadcrumb'
import InfoDialogBox from "../../layout/info/InfoDialogBox"
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import LoadingCardSpinner from '../../components/LoadingCardSpinner/LoadingCardSpinner'
import { Typeahead } from 'react-bootstrap-typeahead';
import SweetAlert from 'sweetalert2'
import { compact } from 'lodash';
import { Sliders, X, Info } from 'react-feather';
import { Collapse } from '@mui/material'

const Overview = () =>  { 
  const { t } = useTranslation('dataTables')
  const { register, handleSubmit, errors, control, watch, reset,setValue} = useForm()
  const { axiosPrivate } = useAxiosPrivate()
  
  const [data,setData] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState(false)
  const [tableLoading, setTableLoading] = useState(false)
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [downloadAvailable, setDownloadAvailable] = useState(false)

  // FILTER FORM STATE LOGIC
  const { startDate, endDate } = watch(['startDate', 'endDate'], ['',''])
  const [ companiesList, setCompaniesList ] = useState([])
  const [ multiSelectionsCompanies, setMultiSelectionsCompanies ] = useState([])
  const [ indicatorsList, setIndicatorsList ] = useState([])
  const [ multiSelectionsIndicators, setMultiSelectionsIndicators ] = useState([])
  const [ hqsList, setHQsList ] = useState([])
  const [ multiSelectionsHQs, setMultiSelectionsHQs ] = useState([])
  const [ hqLoading, setHQLoading ] = useState(false)
  const [ dynTableHeaders, setDynTableHeaders ] = useState([])
  const [ showFilters, setShowFilters ] = useState(false)
  
  // componentDidMount
  useEffect(() => {
    try {
      updateAvailableCompanies()
      updateAvailableIndicators()
      getOverview()
    } catch (e) {
      // console.log(e)
    }
  }, [])

  // getting companies
  const updateAvailableCompanies = ()=>{
    axiosPrivate
      .get('company/')
      .then(res =>{
        setCompaniesList([...res.data.results])
      })
  }

  const updateAvailableIndicators = () =>{
    axiosPrivate
      .get('indicators/')
      .then(res=>{
        setIndicatorsList([...res.data.results])
      })
  }

  // getting headquarters
  const updateAvailableHQs = async () => {
    await setHQLoading(true)
    let availableHQs = []
    for(let {slug:companySlug} of multiSelectionsCompanies)
      await axiosPrivate
        .get('company/' + companySlug + "/headquarter/" )
        .then(res=>{
          availableHQs.push(...res.data.results)
        })
    let hqsSlugs = availableHQs.map(hq=>hq.slug)
    let newMultiSelectionsHQs = multiSelectionsHQs.filter((item) => hqsSlugs.includes(item.slug))
    await setHQsList(availableHQs)
    await setMultiSelectionsHQs(newMultiSelectionsHQs)
    setHQLoading(false)
    
  }

  // update headers
  const updateHeaders = (headers) => {
    if(!headers) setDynTableHeaders([])
    let newHeaders = []
    for(let idx in headers){
      newHeaders.push({
        name:<p>{t(headers[idx])}</p>,
        selector: row => <p>{row[idx]}</p>,
        center:false,
        wrap: true,
        width:"200px",
        style: {
          "white-space": "initial"
        }
      })
    }
    setDynTableHeaders(newHeaders)
  }

  useEffect(()=>{
    setDownloadAvailable(totalRows > 0)
  },[totalRows])

  // GETTING DATA HANDLER
  // old endpoint
  // const getOverview = async (companiesSlug = [], start = startDate, end = endDate, page_number = currentPage, page_size = perPage) => {
  //   setLoading(true)
  //   let companiesMapped = companiesSlug.map(item=>item.slug)
  //   let params = { companiesSlug:companiesMapped.length > 0 ? companiesMapped.join(','): "", start, end}
  //   if(page_number)  params = {...params, page_number}
  //   if(page_size)  params = {...params, page_size}
  //   axiosPrivate
  //     .get("/compilation/overview", {params})
  //     .then(res => {
  //       if(res.status !== 200) throw new Error()
  //       let __ = compact(res?.data?.data ?? [] )
  //       updateHeaders(res?.data?.header ?? [])
  //       setData(__)
  //       setTotalRows(__.length ?? 0)
  //     })
  //     .catch(err=>{
  //       // console.log(err);
  //       SweetAlert.fire(
  //         t('somethingWentWrongMsg'),
  //         t('tryAgainMsg'),
  //         'warning'
  //       )
  //     })
  //     .finally(()=>{
  //       setLoading(false)
  //     })
  // }
  const getOverview = async (companiesSlug = [], headquartersSlug = [], indicatorsSlug = [], page_number = currentPage, page_size = perPage) => {
    setTableLoading(true)
    let companiesMapped = companiesSlug.map(item=>item.slug)
    let headquartersMapped = headquartersSlug.map(item=>item.slug)
    let indicatorsMapped = indicatorsSlug.map(item=>item.code)
    let params = { 
      companiesSlug: companiesMapped.length > 0 ? companiesMapped.join(','): "",
      headquartersSlug: headquartersMapped.length > 0 ? headquartersMapped.join(','): "",
      indicatorsSlug: indicatorsMapped.length > 0 ? indicatorsMapped.join(','): ""
    }
    if(page_number)  params = {...params, page_number}
    if(page_size)  params = {...params, page_size}
    axiosPrivate
      .get("/compilation/overview", {params})
      .then(res => {
        if(res.status !== 200) throw new Error()
        updateHeaders(res?.data?.header ?? [])
        setData(compact(res?.data?.data ?? [] ))
        setTotalRows(res?.data?.count ?? 0)
      })
      .catch(err=>{
        // console.log(err);
        SweetAlert.fire(
          t('somethingWentWrongMsg'),
          t('tryAgainMsg'),
          'warning'
        )
      })
      .finally(()=>{
        setTableLoading(false)
      })
  }

  // table handling
  const handlePageChange = page => {
    console.log("PAGE CHANGED");
    setCurrentPage(page)
  }

  const handlePerRowsChange = (newPerPage) => {
    console.log("PER PAGE CHANGED");
    setPerPage(newPerPage)
  }

  useEffect(()=>{
    getOverview(multiSelectionsCompanies, multiSelectionsHQs, multiSelectionsIndicators, currentPage, perPage)
  },[currentPage, perPage])

  //// FILTER FORM LOGIC
  // old logic with starting-ending date
  // const onSubmit = ({startDate, endDate, companiesSlug}) => {
  //   if(startDate || (companiesSlug?.length ?? 0) !== 0 || endDate)
  //     getOverview(companiesSlug)
  // }
  const onSubmit = ({companiesSlug, headquartersSlug, indicatorsSlug}) => {
    getOverview(companiesSlug, headquartersSlug, indicatorsSlug)
  }

  // dynamic update of multiselection input for better performance
  useEffect(()=>{
    setValue('companiesSlug', multiSelectionsCompanies)
    updateAvailableHQs()
  },[multiSelectionsCompanies])

  
  useEffect(()=>{
    setValue('indicatorsSlug', multiSelectionsIndicators)
    updateAvailableIndicators()
  },[multiSelectionsIndicators])
  
  
  useEffect(()=>{
    setValue('headquartersSlug', multiSelectionsHQs)  
  },[multiSelectionsHQs])

  // manual reset of multiselection field
  const handleReset = ()=>{
    reset()
    setMultiSelectionsCompanies([])
    setMultiSelectionsIndicators([])
    getOverview()
  }

  /// custom date picker input component
  const CustomDatePickerInput = forwardRef(({ value, onChange, onClick, placeholder, id }, ref) => (
    <Input onChange={onChange} value={value} placeholder={placeholder} type="text" className="form-control digits" id={id} onClick={onClick} ref={ref}/>
  ));

  // handling download button
  const downloadRef = useRef()
  const handleDownload = () => {
    let companiesMapped = multiSelectionsCompanies.map(item=>item.slug)
    let headquartersMapped = multiSelectionsHQs.map(item=>item.slug)
    let indicatorsMapped = multiSelectionsIndicators.map(item=>item.code)
    let csv = ""

    axiosPrivate
      .get("/compilation/overview", {
        params:{ 
          companiesSlug:companiesMapped.length > 0 ? companiesMapped.join(','): "", 
          headquartersSlug:headquartersMapped.length > 0 ? headquartersMapped.join(','): "",
          indicatorsSlug: indicatorsMapped.length > 0 ? indicatorsMapped.join(','): ""
        } 
      })
      .then(res => {
        if(res.status === 200 && downloadAvailable) { 
          let unhandled = res.data
          csv = unhandled.header.join(";") + '\n'
          for(let row of compact(unhandled.data))
            csv += row.join(";") + '\n'
          
          downloadRef.current.href = 'data:application/octet-stream;charset=utf-8,' + encodeURIComponent(csv)
          //triggers download
          downloadRef.current.click()
        }else{
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('tryAgainMsg'),
            'warning'
          )
        }
      }).catch(e=>{
        SweetAlert.fire(
          t('somethingWentWrongMsg'),
          t('tryAgainMsg'),
          'warning'
        )
      })
  }

  const toggleFilterView = (e)=>{
    setShowFilters(!showFilters)
  }

  return (
    <Fragment>
      <Breadcrumb parent="Report" title="Report List"/>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <LoadingCardSpinner isLoading={loading} />
              <CardHeader>
                <Container className='header_filters_container' fluid={true}>
                  <Row className='col-12 w-100 px-0'>
                    <Col xs="8" md="9" xl="10" className='px-0'>
                      <h5 
                       className='datatable_header_title align-middle' 
                       style={{'lineHeight':'44px'}}
                      >
                        {t('overview')}
                      </h5>
                    </Col>
                    <Col xs="4" md="3" xl="2" className='px-0'>
                      {
                        !showFilters
                        ?<FormGroup className='m-0'>
                            <label 
                             className='align-center filters-button-label'
                             htmlFor="show-filter-button"
                             style={{'lineHeight':'44px'}} 
                            >
                              {t('showFilters')} : 
                            </label>
                            <button id="show-filter-button" className='custom-filter-button d-flex align-items-center justify-content-center' onClick={(toggleFilterView)}><Sliders/></button>
                        </FormGroup>
                        :<FormGroup  className='m-0'>
                            <label 
                             className='align-center filters-button-label'
                             htmlFor="show-filter-button"
                             style={{'lineHeight':'44px'}} 
                            >
                              {t('hideFilters')} : 
                            </label>
                            <button id="show-filter-button" className='custom-filter-button d-flex align-items-center justify-content-center' onClick={(toggleFilterView)}><X/></button>
                        </FormGroup>
                      }
                    </Col>
                  </Row>
                </Container>
              </CardHeader>
              <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <CardBody>
                  <Collapse in={showFilters} >
                    <div>
                      <Row className='mb-2'>
                        <>
                          {/* <Col md="4" sm="6">
                            <FormGroup floating="true">  
                              <Label htmlFor="startDate" className='inline-flex'>
                                {t('startDate')}
                                <InfoDialogBox className="ml-2" placement="right" target="startDateLabel">
                                  {t("infoMsgStartDate")}
                                </InfoDialogBox>
                              </Label>
                              <Controller
                                control={control}
                                name="startDate"
                                defaultValue=''
                                render={( field ) => (
                                  <DatePicker
                                    className="form-control digits"
                                    selected={field.value}
                                    onChange={field.onChange}
                                    isClearable
                                    // minDate={new Date()}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText={t('clickToSelectADate')}
                                    selectsStart
                                    startDate={field.value}
                                    endDate={endDate}
                                    customInput={<CustomDatePickerInput id="startDate" label={t('startDate')}/>}
                                  />
                                )}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4" sm="6">
                            <FormGroup className='form-floating'>
                              <Label htmlFor="endDate" className='inline-flex'>
                              {t('endDate')}
                                <InfoDialogBox className="ml-2" placement="right" target="startDateLabel">
                                  {t("infoMsgEndDate")}
                                </InfoDialogBox>
                              </Label>
                              <Controller
                                control={control}
                                name="endDate"
                                defaultValue=''
                                render={( field ) => (
                                  <DatePicker
                                    className="form-control digits"
                                    selected={field.value}
                                    onChange={field.onChange}
                                    isClearable
                                    // minDate={new Date()}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText={t('clickToSelectADate')}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={field.value}
                                    customInput={<CustomDatePickerInput id="endDate" label={t('endDate')}/>}
                                  />
                                )}
                              />
                            </FormGroup>
                          </Col> */}
                        </>
                        <Col sm="12" md="4">
                          <FormGroup floating="true">
                            <Label htmlFor="select-companies" className='inline-flex' >
                              {t('companies')}
                              <InfoDialogBox className="ml-2" placement="right" target="select-companies-label">
                                {t("infoMsgCompaniesList")}
                              </InfoDialogBox>
                            </Label>
                            <Typeahead
                              id="select-companies"
                              labelKey="companyName"
                              multiple
                              options={companiesList || []}
                              placeholder={t('companies')}
                              selected={multiSelectionsCompanies}
                              {...register('companiesSlug') }
                              onChange={setMultiSelectionsCompanies}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="12" md="4">
                          <FormGroup floating="true">
                            <Label htmlFor="select-hqs" className='inline-flex'>
                              {t('headquarter')}
                              <InfoDialogBox className="ml-2" placement="right" target="select-hqs-label">
                                {t("infoMsgHeadquartersList") }
                              </InfoDialogBox>
                            </Label>
                            <Typeahead
                              id="select-hqs"
                              labelKey="name"
                              multiple
                              options={hqsList || []}
                              placeholder={t('headquarter')}
                              selected={multiSelectionsHQs}
                              {...register('headquartersSlug') }
                              onChange={setMultiSelectionsHQs}
                            ><LoadingCardSpinner isLoading={hqLoading} /></Typeahead>
                          </FormGroup>
                        </Col>
                        <Col sm="12" md="4">
                          <FormGroup floating="true">
                            <Label htmlFor="select-hqs" className='inline-flex'>
                              {t('indicators')}
                              <InfoDialogBox className="ml-2" placement="right" target="select-hqs-label">
                                {t("infoMsgIndicatorsList") }
                              </InfoDialogBox>
                            </Label>
                            <Typeahead
                              id="select-indicators"
                              labelKey="code"
                              multiple
                              options={indicatorsList || []}
                              placeholder={t('indicators')}
                              selected={multiSelectionsIndicators}
                              {...register('indicatorsSlug') }
                              onChange={setMultiSelectionsIndicators}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className='mb-4'>
                        <Col className='offset-sm-7 offset-md-8' sm="5" md="4">
                          <Button color="primary" className="float-right">{t('search')}</Button>
                          <Button color="light" className='mr-1 float-right' onClick={handleReset} type="reset" >{t('reset')}</Button>
                        </Col>
                      </Row>
                    </div>
                  </Collapse>

                  <Row>
                    <LoadingCardSpinner isLoading={tableLoading}/>
                    <DataTable
                      fixedHeader={true}
                      noDataComponent={<h6 className="mb-4 mt-4">{t('noResults')}</h6> }
                      data={data}
                      columns={dynTableHeaders}
                      striped={true}
                      center={true}
                      persistTableHead
                      highlightOnHover
                      paginationComponentOptions={{rowsPerPageText: t('rowsPerPage'), rangeSeparatorText: t('of')}}
                      pagination
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      paginationTotalRows={totalRows}
                      paginationServer
                    />
                  </Row>
                </CardBody>
                <CardFooter>
                  <Row className='mb-4'>
                    <Col className='offset-sm-7 offset-md-8' sm="5" md="4">
                      <Button disabled={!downloadAvailable} color="primary" className="float-right" onClick={handleDownload}>{t('downloadCSV')}</Button>
                      <a style={{display:"none"}} ref={downloadRef} href="" download={"overview.csv"}>hidden</a>
                    </Col>
                  </Row>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>            
    </Fragment>
  )
}

export default Overview;