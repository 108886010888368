import React, { Fragment,useState,useEffect,useCallback,useMemo} from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import differenceBy from 'lodash/differenceBy';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component'
import { Search } from 'react-feather'
import {Link, useNavigate} from 'react-router-dom'
import { Container,Row,Col,Card,CardHeader, InputGroup, InputGroupAddon, InputGroupText, CardBody} from 'reactstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { Input } from 'reactstrap'
import SweetAlert from 'sweetalert2'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import LoadingCardSpinner from '../LoadingCardSpinner/LoadingCardSpinner'
import {debounce} from 'lodash'
import ROLES from '../../utils/roles'
import useAuth from '../../hooks/useAuth'
import { useTranslation } from 'react-i18next'

const HeadquartersList = () =>  {
  const { t } = useTranslation('dataTables')
  const { auth: { role: userRole }, auth } = useAuth()
  const navigate = useNavigate()
  const [data,setData] = useState([])
  const [companySelection, setCompanySelection] = useState([])

  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState(false)
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const { axiosPrivate } = useAxiosPrivate()
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)

  const [searchValue, setSearchValue] = useState('')
  const [sortColumn, setSortColumn] = useState('')
  const [sortDescDirection, setSortDescDirection] = useState(false)

  useEffect(() => {
    try {
      getHeadquarters()
    } catch (e) {
      // console.log(e)
    }
  }, [])


  const getHeadquarters = async (start = 1, limit = 10, sorting, desc, name) => {
    setLoading(true)
    let params = { start, limit }
    if (sorting) params = { ...params, sorting }
    if (desc) params = { ...params, desc }
    if (name) params = { ...params, name }
    let req = 'headquarter/'
    if ([ROLES.superadmin, ROLES.admin].includes(userRole)) {
      req = 'headquarter/'
    } else if ([ROLES.groupleader].includes(userRole)) {
      req = `holding/${auth.holdingSlug}/headquarters/`
    } else if ([ROLES.companymanager].includes(userRole)) {
      req = `company/${auth.companySlug}/headquarter/`
    }
    const response = await axiosPrivate.get(req,
      {
        params
      }
    )
    .catch(e => {
      // console.log(e)
    })
    .finally(() => {
      setLoading(false)
    })
    setData(response?.data?.results ? [...response.data.results] : [])
    setTotalRows(response?.data?.results ? response.data.count : 0)
  }

  const deleteHeadquarters = async (slug) => {
    setIsDeleteLoading(true)
    axiosPrivate.delete(`headquarter/${slug}/`)
      .then(res => {
        setData(prev => prev.filter(headquarter => headquarter.slug !== slug))
        if (totalRows - ((currentPage - 1) * perPage) === 1 && totalRows !== 1) {
          handlePageChange(currentPage - 1)
        } else {
          handlePageChange(currentPage)
        }
        SweetAlert.fire(
          t('successfullyDeleted'),
          '',
          'success'
        )
      })
      .catch(err => {
        if (err.request.status === 404) {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('maybeWasAlreadyDeleted'),
            'warning'
          )
        } else {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('tryAgainMsg'),
            'warning'
          )
        }
      })
      .finally(() => {
        setIsDeleteLoading(false)
      })
  }

  
  const onHeadquarterDeletePress = async (slug) => {
    SweetAlert.fire({
      title: t('areYouSureToDelete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    })
      .then((result) => {
        if (result.value) {
          deleteHeadquarters(slug)
        }
      })
  }

  const tableColumns = [
    {
        name: t('headquarterName'),
        selector: row => row.name,
        sortable: true,
        center:true,
        wrap: true,
        sortField: 'name'
    },
    {
        name: t('company'),
        selector: row => row.company?.companyName,
        sortable: true,
        center:true,
        wrap: true,
        sortField: 'id_Company'
    },
    {
        name: t('country'),
        selector: row => row.country,
        sortable: true,
        center:true,
        wrap: true,
        sortField: 'country'
    },
    {
        name: t('city'),
        selector: row => row.city,
        sortable: true,
        center:true,
        wrap: true,
        sortField: 'city'
    },
    {
        name: t('zipCode'),
        selector: row => row.zipCode,
        sortable: true,
        center:true,
        wrap: true,
        sortField: 'zipCode'
    },
    {
        name: t('address'),
        selector: row => `${row.streetName}, ${row.addressNumber}`,
        sortable: true,
        center:true,
        wrap: true,
        sortField: 'streetName'
    },
    {
      name: '',
      selector: row => renderRowBtns(row),
      sortable: false,
      center:true,
      ignoreRowClick: true
    }
  ]

  const renderRowBtns = row => {
    return (
      <>
        <Link
          to={`/edit-headquarter/${row.slug}`}
          state={{ headquarterSlug: row.slug }}
          className="p-1"
          color="primary"
          size="sm"
         >
          <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'primary' }}></i>
        </Link>
        <span onClick={() => onHeadquarterDeletePress(row.slug)}><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></span>
      </>
      )
  }

    const handlePageChange = page => {
      setCurrentPage(page)
      getHeadquarters((page -1) * perPage + 1, perPage, sortColumn, sortDescDirection, searchValue)
    }
  
    const handlePerRowsChange = async (newPerPage, page) => {
      const start = (page - 1) * newPerPage + 1
      const limit = newPerPage
      await getHeadquarters(start, limit, sortColumn, sortDescDirection, searchValue)
      setPerPage(newPerPage)
    }

    const onSearch = (query) => {
      getHeadquarters(1, perPage, false, false, query)
    }
  
    const debouncedSearch = React.useRef(
      debounce(onSearch, 500)
    ).current  

    const handleSort = (column, sortDirection) => {
      const isDescSort = sortDirection === 'desc'
      setSortColumn(column.sortField)
      setSortDescDirection(isDescSort)
      getHeadquarters(1, perPage, column.sortField, isDescSort)
    }

    return (
      <Fragment>
        <Breadcrumb parent="Headquarters" title="Headquarters List"/>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <LoadingCardSpinner isLoading={isDeleteLoading || loading} />
                <CardHeader>
                  <div className='header_filters_container'>
                    <h5 className='datatable_header_title'>{t('headquarters')}</h5>
                    <Row className='justify-content-end'>
                      {/* <Col sm="5" className="p-t-10 p-b-10">
                        <Typeahead
                            id="select-preset-indicators"
                            labelKey="label"
                            onChange={setCompanySelection}
                            options={companies}
                            placeholder="Seleziona Azienda"
                            selected={companySelection}
                          />
                      </Col> */}
                      <Col sm="10" className="d-flex align-items-center p-t-10 p-b-10">
                        <InputGroup>
                          <Input
                            className="form-control align-self-center"
                            type="search"
                            placeholder={t('search')}
                            value={searchValue}
                            onChange={event => {
                              setSearchValue(event.target.value)
                              debouncedSearch(event.target.value)
                            }}
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText style={{backgroundColor: 'inherit'}} className="form-control">
                              <Search aria-hidden="true" className="" />
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>
                    </Row>
                  </div>
                </CardHeader>
                <CardBody>
                  <DataTable
                    noDataComponent={<h6 className="mb-4 mt-4">{t('noResults')}</h6> }
                    paginationComponentOptions={{rowsPerPageText: t('rowsPerPage'), rangeSeparatorText: t('of')}}
                    data={data}
                    columns={tableColumns}
                    striped={true}
                    center={true}
                    persistTableHead
                    highlightOnHover
                    pointerOnHover
                    pagination
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationTotalRows={totalRows}
                    paginationServer
                    sortServer
                    onSort={handleSort}
                    onRowClicked={(row) => navigate(`/edit-headquarter/${row.id}`, { state: { headquarterSlug: row.slug } })}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>            
      </Fragment>
    );

};

export default HeadquartersList;