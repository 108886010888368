import React, { Fragment } from 'react';
import sad from '../../assets/images/other-images/sad.png';
import {Container,Button,Media,Col} from "reactstrap"
import { useNavigate } from "react-router-dom";

const ErrorCardMessage = ({ error }) => {
  const navigate = useNavigate();

  const refreshPage = () => {
    navigate(0)
  }

  return error && (
    <Fragment>
      <div className="error-card-layer">
        <div className="error-wrapper card-container">
          <Container>
            <Media body className="img-100" src={sad} alt="" />
            <Col md="8 offset-md-2">
              <p className="sub-content">
                {
                    "Non siamo riusciti a recuperare i dati necessari per questa pagina. Controlla la tua connessione e se l'errore persiste contatta uno dei nostri tecnici."
                }
              </p>
            </Col>
            <Button color="primary" size='lg' onClick={refreshPage}>{'Reload page'}</Button>
          </Container>
        </div>
      </div>
    </Fragment>
  )
}

export default ErrorCardMessage