import React, { Fragment,useState,useEffect,useCallback,useMemo} from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import DataTable from 'react-data-table-component'
import { Search } from 'react-feather'
import {Link, useNavigate} from 'react-router-dom'
import { Container,Row,Col,Card,CardHeader, CardBody, InputGroup, InputGroupAddon, InputGroupText, Button, ModalBody, ModalHeader, ModalFooter, Modal} from 'reactstrap';

import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { Input } from 'reactstrap'

import SweetAlert from 'sweetalert2'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import LoadingCardSpinner from '../../components/LoadingCardSpinner/LoadingCardSpinner'
import {debounce} from 'lodash'
import useAuth from '../../hooks/useAuth'
import ROLES from '../../utils/roles'
import { useTranslation } from 'react-i18next'

const SubIndicatorList = () =>  {
  const { t } = useTranslation('dataTables')
  const navigate = useNavigate()
  const { auth: { role: userRole }, auth } = useAuth()
  const [data,setData] = useState([])
  const [parentSelection, setParentSelection] = useState([])

  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState(false)
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const { axiosPrivate } = useAxiosPrivate()
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)

  const [searchValue, setSearchValue] = useState('')
  const [sortColumn, setSortColumn] = useState('')
  const [sortDescDirection, setSortDescDirection] = useState(false)

  useEffect(() => {
    try {
      getSubindicators()
    } catch (e) {
      // console.log(e)
    }
  }, [])


  const getSubindicators = async (start = 1, limit = 10, sorting, desc, name) => {
    setLoading(true)
    let params = { start, limit }
    if (sorting) params = { ...params, sorting }
    if (desc) params = { ...params, desc }
    if (name) params = { ...params, name }
    const response = await axiosPrivate.get('subindicators/',
      {
        params
      }
    )
    .finally(() => {
      setLoading(false)
    })
    setData(response?.data?.results ? [...response.data.results] : [])
    setTotalRows(response?.data?.results ? response.data.count : 0)
  }

  const deleteSubindicator = async (slug) => {
    setIsDeleteLoading(true)
    axiosPrivate.delete(`subindicator/${slug}/`)
      .then(res => {
        setData(prev => prev.filter(subindicator => subindicator.slug !== slug))
        // check to understand if element is last on the page and in this case after delete switch page behind by one
        if (totalRows - ((currentPage - 1) * perPage) === 1 && totalRows !== 1) {
          handlePageChange(currentPage - 1)
        } else {
          handlePageChange(currentPage)
        }
        SweetAlert.fire(
          t('successfullyDeleted'),
          '',
          'success'
        )
      })
      .catch(err => {
        if (err.request.status === 404) {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('maybeWasAlreadyDeleted'),
            'warning'
          )
        } else {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('tryAgainMsg'),
            'warning'
          )
        }
      })
      .finally(() => {
        setIsDeleteLoading(false)
      })
  }

  const onSubindicatorDeletePress = async (slug) => {
    SweetAlert.fire({
      title: t('areYouSureToDelete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    })
    .then((result) => {
      if (result.value) {
        deleteSubindicator(slug)
      }
    })
  }

  const tableColumns = [
    {
        name: t('name'),
        selector: row => row.name,
        sortable: true,
        center:true,
        sortField: 'name',
        wrap: true
    },
    {
        name: t('description'),
        selector: row => row.description,
        sortable: true,
        center:true,
        sortField: 'description',
        wrap: true
    },
    {
        name: t('parent'),
        selector: row => row.parent?.code || row.parent?.name,
        sortable: true,
        center:true,
        sortField: 'parent',
        wrap: true
    },
    {
        name: t('unitOfMeasurement'),
        selector: row => row.unitOfMeasurement,
        sortable: true,
        center:true,
        sortField: 'unitOfMeasurement',
        wrap: true
    },
    {
      name: '',
      selector: row => renderRowBtns(row),
      sortable: false,
      center:true,
      ignoreRowClick: true,
      omit: [ROLES.groupleader].includes(userRole)
    }
  ]

  const renderRowBtns = (row) => {
    // TODO: handle company manager posssibility to edit subindicator if it's of his company
    if (row.id_Company === auth.companyId || [ROLES.superadmin, ROLES.admin].includes(userRole)) {
    return (
      <>
        <Link
          to={`/edit-subindicators/${row.slug}`}
          state={{ subindicatorSlug: row.slug, isEditEnabled: true }}
          className="p-1"
          color="primary"
          size="sm"
        >
          <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'primary' }}></i>
        </Link>
        <span onClick={() => onSubindicatorDeletePress(row.slug)}><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></span>
      </>
      )
    }
  }

    const handlePageChange = page => {
      setCurrentPage(page)
      getSubindicators((page -1) * perPage + 1, perPage)
    }
  
    const handlePerRowsChange = async (newPerPage, page) => {
      const start = (page - 1) * newPerPage + 1
      const limit = newPerPage
      await getSubindicators(start, limit, sortColumn, sortDescDirection)
      setPerPage(newPerPage)
    }
  
    const onSearch = (query) => {
      getSubindicators(1, perPage, false, false, query)
    }
  
    const debouncedSearch = React.useRef(
      debounce(onSearch, 500)
    ).current  

    const handleSort = (column, sortDirection) => {
      const isDescSort = sortDirection === 'desc'
      setSortColumn(column.sortField)
      setSortDescDirection(isDescSort)
      getSubindicators(1, perPage, column.sortField, isDescSort)
    }

    return (
      <Fragment>
        <Breadcrumb parent="Sub. Indicators" title="Sub. Indicators list"/>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <LoadingCardSpinner isLoading={isDeleteLoading || loading} />
                <CardHeader>
                  <div className='header_filters_container'>
                    <h5 className='datatable_header_title'>{t('subIndicatorsList')}</h5>
                    <Row className='justify-content-end'>
                      {/* <Col sm="5" className="p-t-10 p-b-10">
                        <Typeahead
                          id="select-preset-indicators"
                          labelKey="label"
                          onChange={setParentSelection}
                          options={subIndicatorParent}
                          placeholder="Appartenenza"
                          selected={parentSelection}
                        />
                      </Col> */}
                      <Col sm="10" className="d-flex align-items-center p-t-10 p-b-10">
                        <InputGroup>
                          <Input
                            className="form-control align-self-center"
                            type="search"
                            placeholder={t('search')}
                            value={searchValue}
                            onChange={event => {
                              setSearchValue(event.target.value)
                              debouncedSearch(event.target.value)
                            }}
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText style={{backgroundColor: 'inherit'}} className="form-control">
                              <Search aria-hidden="true" className="" />
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>
                    </Row>
                  </div>
                </CardHeader>
                <CardBody>
                  <DataTable
                    noDataComponent={<h6 className="mb-4 mt-4">{t('noResults')}</h6> }
                    paginationComponentOptions={{rowsPerPageText: t('rowsPerPage'), rangeSeparatorText: t('of')}}
                    data={data}
                    columns={tableColumns}
                    striped={true}
                    center={true}
                    persistTableHead
                    highlightOnHover
                    pointerOnHover
                    pagination
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationTotalRows={totalRows}
                    paginationServer
                    sortServer
                    onSort={handleSort}
                    onRowClicked={(row) => navigate(`/edit-subindicators/${row.slug}`, { state: { subindicatorSlug: row.slug, isEditEnabled: row.id_Company === auth.companyId || [ROLES.superadmin, ROLES.admin].includes(userRole) } })}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>            
      </Fragment>
    );

};

export default SubIndicatorList;