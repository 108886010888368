import React from 'react'

const LoadingCardSpinner = ({ isLoading }) => {
    return isLoading && (
        <div className='loader-wrapper loader-card-layer'>
            <div className="loader-box">
                <div className="loader-19"></div>
            </div>
        </div>
    )
}

export default LoadingCardSpinner