import React, { Fragment, useState, useRef } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import {Container,Row,Col,Card,CardHeader,CardBody,CardFooter,Button,Form,FormGroup,Label,Input} from 'reactstrap'
import {Cancel} from '../../constant'
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'

import { useTranslation } from 'react-i18next'
import SweetAlert from 'sweetalert2'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { useForm } from 'react-hook-form'
import LoadingCardSpinner from '../../components/LoadingCardSpinner/LoadingCardSpinner'
import useAuth from '../../hooks/useAuth'
import ROLES from '../../utils/roles'

const CreateIndicator = () => {
  const { t } = useTranslation('forms')
  const { auth: { role: userRole }, auth } = useAuth()
  const { register, handleSubmit, errors, getValues } = useForm()
  const { axiosPrivate, usePrivateAxiosHook } = useAxiosPrivate()
  const [{ data: languageData, loading, error }, refetch] = usePrivateAxiosHook(`languages/`)
  const [languageSelection, setLanguageSelection] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [ languageError, setLanguageError ] = useState(false)
  const formRef = useRef()

  const onSubmit = data => {
    if (!languageSelection.length) {
      setLanguageError(true)
      return
    } else {
      setLanguageError(false)
    }
    SweetAlert.fire({
      title: t('confirmCreationMsg'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: t('confirm'),
      cancelButtonText: t('cancel'),
      reverseButtons: true
    })
    .then((result) => {
      if (result.value) {
        if (data !== '') {
          setIsLoading(true)
          try {
            let body = {
              code: data.code,
              description: data?.description ? data.description : '',
              link: data?.link ? data.link : '',
              unitOfMeasurement: data?.unitOfMeasurement ? data.unitOfMeasurement : '',
              id_Language: languageSelection[0].id,
              id_Company: auth?.companyId ? auth.companyId : null
            }
            axiosPrivate.post('indicators/', body )
              .then(res => {
                if (res.status === 201) {
                  SweetAlert.fire(
                    t('saved'),
                    t('successCreationMsg'),
                    'success'
                  )
                  formRef.current.reset()
                  setLanguageSelection([])
                } else {
                  SweetAlert.fire(
                    t('somethingWentWrongMsg'),
                    t('tryAgainMsg'),
                    'warning'
                  )
                }
              })
              .catch(err => {
                // console.log('error: ', err)
                if (err.request.status === 409) {
                  SweetAlert.fire(
                    t('somethingWentWrongMsg'),
                    t('sameIndicatorDataExist'),
                    'warning'
                  )
                } else {
                  SweetAlert.fire(
                    t('somethingWentWrongMsg'),
                    t('tryAgainMsg'),
                    'warning'
                  )
                }
              })
              .finally(() => {
                setIsLoading(false)
              })
            // console.log(body)
          } catch (e) {
            // console.log(e)
          }
        } else {
          errors.showMessages()
          setIsLoading(false)
        }
      }
    })
  }

  return (
    <Fragment>
      <Breadcrumb parent="Indicators" title="Create Indicator"/>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <LoadingCardSpinner isLoading={loading || isLoading} />
              <CardHeader>
                <h5>{t('createIndicators')}</h5>
              </CardHeader>
              <Form className="form theme-form needs-validation" autoComplete='off' onSubmit={handleSubmit(onSubmit)}  innerRef={formRef}>
                <CardBody>
                  <Row>
                    <Col>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('code')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control"
                              name='code'
                              type="text"
                              placeholder={t('code')}
                              innerRef={register({ required: true, maxLength: 50 })}
                            />
                            <span className="">{errors.code?.type === "required" && t('requiredField')}</span>
                            <span className="">{errors.code?.type === 'maxLength' && t('lengthLimit', {length: 50})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('description')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control"
                              name='description'
                              type="textarea"
                              rows='3'
                              placeholder={t('description')}
                              innerRef={register()}
                            />
                            <span className='danger-text-color'>{errors.description && t('requiredField')}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">URL*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control"
                              name='link'
                              type="url"
                              placeholder="https://www.example.com/"
                              innerRef={register({ maxLength: 200 })}
                            />
                            <span className="">{errors.link?.type === 'required' && t('requiredField')}</span>
                            <span className="">{errors.link?.type === 'maxLength' && t('lengthLimit', {length: 200})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('unitOfMeasurement')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control"
                              name='unitOfMeasurement'
                              type="text"
                              placeholder="W/h"
                              innerRef={register({ maxLength: 20 })}
                            />
                            <span className="">{errors.unitOfMeasurement?.type === 'required' && t('requiredField')}</span>
                            <span className="">{errors.unitOfMeasurement?.type === 'maxLength' && t('lengthLimit', {length: 20})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('language')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Typeahead
                              style={{ width: '100%' }}
                              id="select-language"
                              labelKey="name"
                              onChange={setLanguageSelection}
                              options={languageData || []}
                              placeholder={t('languagePlaceholder')}
                              selected={languageSelection}
                              isValid={!!languageSelection.length}
                              isInvalid={!languageSelection.length}
                            />
                            <span className='danger-text-color'>{languageError && t('requiredField')}</span>
                          </div>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Col sm="9 offset-sm-3">
                    <Button color="primary" className="mr-1">{t('save')}</Button>
                    <Button color="light" type="reset" >{t('reset')}</Button>
                  </Col>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CreateIndicator;