import axios from 'axios';
import config from '../config/config.json'

export default axios.create({
    baseURL: config.baseApiUrl
});

export const axiosPrivate = axios.create({
    baseURL: config.baseApiUrl,
    headers: { 'Content-Type': 'application/json' }
});