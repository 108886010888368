import Cookies from 'js-cookie'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useAuth from "./useAuth";

const useLogout = () => {
    const { setAuth } = useAuth();
    const { axiosPrivate } = useAxiosPrivate()

    const logout = async () => {
        try {
            const response = await axiosPrivate.get('auth/logout/')
        } catch (err) {
            console.error(err)
        } finally {
            Cookies.remove('refresh')
            localStorage.removeItem("persist")
            setAuth({})
        }
    }

    return logout;
}

export default useLogout