import React, { Fragment } from "react"
import { Breadcrumb, BreadcrumbItem } from "reactstrap"
import { CornerDownLeft } from "react-feather"
import { Link, useNavigate } from "react-router-dom"

const GoBack = (props) => {
    const nav = useNavigate()
    const { to = -1, replace = false, state, text = ""} = props
    const handleNavigation = () => {
        nav(to,{replace, state})
    }
    return (
        <Fragment>
            <Breadcrumb>
                <BreadcrumbItem>
                    <a onClick={handleNavigation} style={{cursor:'pointer'}}><CornerDownLeft/> {text} </a>
                </BreadcrumbItem>
            </Breadcrumb>
        </Fragment>
    )
}

export default GoBack