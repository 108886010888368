import React, { useState, useEffect } from 'react'
import { Outlet } from "react-router-dom"
import useRefreshToken from '../../hooks/useRefreshToken'
import useAuth from '../../hooks/useAuth'
import Loader from "../../layout/loader"
import Cookies from 'js-cookie'

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const { auth, persist } = useAuth();
    useEffect(() => {
        let isMounted = true;

        const verifyRefreshToken = async () => {
            try {
                await refresh();
            }
            catch (err) {
                console.error(err);
            }
            finally {
                isMounted && setIsLoading(false);
            }
        }

        // Avoids unwanted call to verifyRefreshToken
        const refreshToken = Cookies.get('refresh')
        !auth?.accessToken && persist && !!refreshToken ? verifyRefreshToken() : setIsLoading(false);

        return () => isMounted = false;
    }, [auth.accessToken, persist, refresh])

    return (
      <>
        {!persist
                ? <Outlet />
                : isLoading
                    ? <Loader />
                    : <Outlet />
            }
      </>
    )
}

export default PersistLogin