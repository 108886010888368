import React, { Fragment, useState, useRef } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { useForm } from 'react-hook-form'
import { Container,Row,Col,Card,CardHeader,CardBody,CardFooter,Button,Form,FormGroup,Label,Input, InputGroup, InputGroupText } from 'reactstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import { useTranslation } from 'react-i18next'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import SweetAlert from 'sweetalert2'
import LoadingCardSpinner from '../LoadingCardSpinner/LoadingCardSpinner'
import ErrorCardMessage from '../ErrorCardMessage/ErrorCardMessage'
import useAuth from '../../hooks/useAuth'
import ROLES from '../../utils/roles'
import PasswordVisibleInput from '../customInput/PasswordVisibleInput';

const CreateUser = () => {
  const { t } = useTranslation('forms')
  const { auth: { role: userRole }, auth } = useAuth()
  const { register, handleSubmit, errors, getValues, watch, reset } = useForm()
  const {role: selectedUserRole} = watch()

  const formRef = useRef()
  const { axiosPrivate, usePrivateAxiosHook } = useAxiosPrivate()
  const [isLoading, setIsLoading] = useState(false)

  // language state
  const [{ data: languageData, loading: languageLoading, error: languageError }, refetchLanguage] = usePrivateAxiosHook({url: `languages/`})
  const [languageSelection, setLanguageSelection] = useState([])

  // holding state
  const [{ data: {results: holdingData} = {}, loading: holdingLoading, error: holdingError }, refetchHolding] = usePrivateAxiosHook({url: `holding/`}, { manual: userRole !== ROLES.admin && userRole !== ROLES.superadmin})
  const [holdingSelection, setHoldingSelection] = useState([])

  //passwords visibility states
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false)


  // company state
  const [
    { data: {results: companyData} = {}, loading: companyLoading, error: companyError },
    refetchCompany
  ] = usePrivateAxiosHook({
    url: function () {
      if ([ROLES.superadmin, ROLES.admin].includes(userRole)) {
        return `company/`
      } else if ([ROLES.groupleader].includes(userRole)) {
        return `/holding/${auth.holdingSlug}/company/`
      }
    }()
  }, { manual: userRole === ROLES.companymanager})


  const [companySelection, setCompanySelection] = useState([])

  const onSubmit = data => {
    SweetAlert.fire({
      title: t('confirmCreationMsg'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: t('confirm'),
      cancelButtonText: t('cancel'),
      reverseButtons: true
    })
    .then((result) => {
      if (result.value) {
        if (data !== '') {
          setIsLoading(true)
          try {
            const body = {
              name: data.name,
              surname: data.surname,
              email: data.email,
              telephoneNumber: data.telephoneNumber,
              username: data.username,
              password: data.password,
              role: data.role,
              id_Language: languageSelection[0].id
            }
            let query = 'users/'
            if (selectedUserRole === 'companymanager' || selectedUserRole === 'datamanager') {
              const slug = [ROLES.companymanager].includes(userRole) ? auth.companySlug : companySelection[0].slug
              query = `company/${slug}/users/`
            } else if (selectedUserRole === 'groupleader') {
              query = `holding/${holdingSelection[0].slug}/users/`
            }
            axiosPrivate.post(query, body )
              .then(res => {
                if (res.status === 201) {
                  SweetAlert.fire(
                    t('saved'),
                    t('successCreationMsg'),
                    'success'
                  )
                  formRef.current.reset()
                  reset()
                  setCompanySelection([])
                } else {
                  SweetAlert.fire(
                    t('somethingWentWrongMsg'),
                    t('tryAgainMsg'),
                    'warning'
                  )
                }
              })
              .catch(err => {
                // console.log('error: ', err)
                if (err.request.status === 409) {
                  SweetAlert.fire(
                    t('somethingWentWrongMsg'),
                    t('sameDataUserExist'),
                    'warning'
                  )
                } else {
                  SweetAlert.fire(
                    t('somethingWentWrongMsg'),
                    t('tryAgainMsg'),
                    'warning'
                  )
                }
              })
              .finally(() => {
                setIsLoading(false)
              })
            // console.log(body)
          } catch (e) {
            // console.log(e)
          }
        } else {
          errors.showMessages();
          setIsLoading(false)
        }
      }
    })
  }

  return (
    <Fragment>
      <Breadcrumb parent="Users" title="Create User"/>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <ErrorCardMessage error={languageError || holdingError} />
              <LoadingCardSpinner isLoading={isLoading || languageLoading || companyLoading} />
              <CardHeader>
                <h5>{t('createUser')}</h5>
              </CardHeader>
              <Form autoComplete='off' className="form theme-form needs-validation" onSubmit={handleSubmit(onSubmit)} innerRef={formRef}>
                <CardBody>
                  <Row>
                    <Col>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('firstName')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control"
                              name='name'
                              type="text"
                              placeholder="First Name"
                              innerRef={register({ required: true, maxLength: 50 })}
                            />
                            <span className="">{errors.name?.type === 'required' && t('requiredField')}</span>
                            <span className="">{errors.name?.type === 'maxLength' && t('lengthLimit', {length: 50})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('lastName')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control"
                              name="surname"
                              type="text"
                              placeholder="Last Name"
                              innerRef={register({ required: true, maxLength: 50 })}
                            />
                            <span className="">{errors.surname?.type === 'required' && t('requiredField')}</span>
                            <span className="">{errors.surname?.type === 'maxLength' && t('lengthLimit', {length: 50})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('email')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control"
                              name='email'
                              type="email"
                              placeholder="example@mail.com"
                              innerRef={register({ required: true, maxLength: 200 })}
                            />
                            <span className="">{errors.email?.type === 'required' && t('requiredField')}</span>
                            <span className="">{errors.email?.type === 'maxLength' && t('lengthLimit', {length: 200})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('phone')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control m-input digits"
                              name="telephoneNumber"
                              type="number"
                              placeholder="+39 748 734 2935"
                              innerRef={register({ maxLength: 20 })}
                            />
                            {/* <span className="">{errors.telephoneNumber?.type === 'required' && t('requiredField')}</span> */}
                            <span className="">{errors.telephoneNumber?.type === 'maxLength' && t('lengthLimit', {length: 20})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('language')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Typeahead
                              style={{ width: '100%' }}
                              id="select-language"
                              labelKey="name"
                              onChange={setLanguageSelection}
                              options={languageData || []}
                              placeholder={t('languagePlaceholder')}
                              selected={languageSelection}
                              isValid={!!languageSelection.length}
                              isInvalid={!languageSelection.length}
                              inputProps={{ required: true}}
                            />
                            <span className='danger-text-color'>{languageError && t('requiredField')}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('role')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              name="role"
                              type="select"
                              defaultValue=""
                              className="form-control"
                              placeholder="Seleziona Ruolo"
                              innerRef={register({ required: 'select one option' })}
                            >
                              <option disabled={true} value="">{t('role')}</option>
                              {[ROLES.superadmin].includes(userRole) && <option value='superadmin'>{"Super Admin"}</option>}
                              {[ROLES.superadmin].includes(userRole) && <option value='admin'>{"Admin"}</option>}
                              {[ROLES.superadmin, ROLES.admin].includes(userRole) && <option value='groupleader'>{"Capogruppo"}</option>}
                              {[ROLES.superadmin, ROLES.admin, ROLES.groupleader].includes(userRole) && <option value='companymanager'>{"Responsabile Azienda"}</option>}
                              <option value='datamanager'>{"Referente Dati"}</option>
                            </Input>
                            <span className='danger-text-color'>{errors.role && t('requiredField')}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      {!!selectedUserRole && (selectedUserRole === 'companymanager' || selectedUserRole === 'datamanager') && [ROLES.superadmin, ROLES.admin, ROLES.groupleader].includes(userRole) && 
                        <FormGroup className="row">
                          <Label className="col-sm-3 col-form-label">{t('company')}*</Label>
                          <Col sm="9">
                            <div className="form-row">
                              <Typeahead
                                style={{ width: '100%' }}
                                id="select-preset-indicators"
                                labelKey="companyName"
                                onChange={setCompanySelection}
                                options={companyData || []}
                                placeholder="Seleziona Azienda"
                                selected={companySelection}
                                isValid={!!companySelection.length}
                                isInvalid={!companySelection.length}
                                inputProps={{ required: true }}
                              />
                            </div>
                          </Col>
                        </FormGroup>
                      }
                      {!!selectedUserRole && selectedUserRole === 'groupleader' &&<FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('holding')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Typeahead
                              style={{ width: '100%' }}
                              id="select-preset-indicators"
                              labelKey="companyName"
                              onChange={setHoldingSelection}
                              options={holdingData || []}
                              placeholder={t('holdingRequiredPlaceholder')}
                              selected={holdingSelection}
                              isValid={!!holdingSelection.length}
                              isInvalid={!holdingSelection.length}
                              inputProps={{ required: true }}
                            />
                          </div>
                        </Col>
                      </FormGroup>}
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('username')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              name="username"
                              className="form-control"
                              type="text"
                              placeholder="Username"
                              innerRef={register({ required: true, maxLength:30 })} 
                            />
                            <span className="">{errors.username?.type === "required" && t('requiredField')}</span>
                            <span className="">{errors.username?.type === 'maxLength' && t('lengthLimit', {length: 30})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('password')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <PasswordVisibleInput
                              visible={passwordVisible}
                              setVisible={setPasswordVisible}
                            >
                              <Input
                                className="form-control"
                                name="password"
                                type={passwordVisible?"text":"password"}
                                placeholder="Password"
                                innerRef={register({ required: true, minLength: 8 })}
                              />
                              <span className="">{errors.password && errors.password?.type !== 'minLength' && t('requiredField')}</span>
                              <span className="">{errors.password?.type === 'minLength' && t('minLength', {length: 8})}</span>
                            </PasswordVisibleInput>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('repeatPassword')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                          
                            <PasswordVisibleInput
                              visible={passwordConfirmVisible}
                              setVisible={setPasswordConfirmVisible}
                            >
                              <Input
                                name='passwordMatch'
                                className="form-control"
                                type={passwordConfirmVisible?"text":"password"}
                                placeholder="Password"
                                innerRef={register({ required: true, minLength: 8, validate: value => {
                                  const { password } = getValues()
                                  return password === value
                                }})}
                              />
                              <span className="">{errors.passwordMatch && errors.passwordMatch?.type !== 'minLength' && t('repeatPasswordRequiredAlert')}</span>
                              <span className="">{errors.passwordMatch?.type === 'minLength' && t('minLength', {length: 8})}</span>
                            </PasswordVisibleInput>
                          </div>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Col sm="9 offset-sm-3">
                    <Button
                      color="primary" 
                      className="mr-1"
                    >
                      {t('save')}
                    </Button>
                    <Button color="light" type="reset" >{t('reset')}</Button>
                  </Col>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CreateUser;