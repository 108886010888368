import React, { Fragment, useState, useMemo, useEffect } from 'react'
import Breadcrumb from '../../layout/breadcrumb'
import {Container,Row,Col,Card,CardHeader,CardBody,Button,Form,FormGroup,Label,Input} from 'reactstrap'
import DatePicker from "react-datepicker"
import 'react-datepicker/dist/react-datepicker.css'

import SweetAlert from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { useForm, Controller } from 'react-hook-form'
import LoadingCardSpinner from '../../components/LoadingCardSpinner/LoadingCardSpinner'
import useAuth from '../../hooks/useAuth'
import ROLES from '../../utils/roles'
import ReportIndicatorList from './ReportIndicatorList'
import GoBack from '../../layout/navigation/GoBack'

const parseTimeValue = (time) => {
  if(!time) return ""
  const timeArr = time.split('-').map(item => Number(item))
  return new Date(timeArr[2], timeArr[1] - 1, timeArr[0])
}

const EditReport = () => {
  const { t } = useTranslation('forms')
  const { auth: { role: userRole }, auth } = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const { reportSlug } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const { axiosPrivate, usePrivateAxiosHook } = useAxiosPrivate()
  const [{ data: {compilation:reportData = {}} = {}, loading, error }, refetch] = usePrivateAxiosHook(`compilation/${reportSlug}/`)
  const { register, handleSubmit, reset, errors, control, watch } = useForm({
    defaultValues: useMemo(() => {
      return reportData
    }, [reportData])
  })

  useEffect(() => {
    if (!!reportData){
      resetFormValues()
    }
  }, [reportData])

  function resetFormValues () {
    reset({...reportData, end: parseTimeValue(reportData.end), start: parseTimeValue(reportData.start)})
  }

  const editReport = (slug, body) => {
    axiosPrivate.patch(`compilation/${slug}/`, body )
      .then(res => {
        // console.log('edit company res', res);
        if (res.status === 200) {
          SweetAlert.fire(
            t('edited'),
            t('successEditMsg'),
            'success'
          )
          navigate(-1)
        } else {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('tryAgainMsg'),
            'warning'
          )
        }
      })
      .catch(err => {
        // console.log('error: ', err)
        if (err.request.status === 409) {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('sameReportExist'),
            'warning'
          )
        } else {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('tryAgainMsg'),
            'warning'
          )
        }
      })
  }

  const onSubmit = data => {
    SweetAlert.fire({
      title: t('confirmEditMsg'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: t('confirm'),
      cancelButtonText: t('cancel'),
      reverseButtons: true
    })
    .then((result) => {
      if (result.value) {
        if (data !== '') {
          setIsLoading(true)
          try {
            const body = {
              name: data.name,
              description: data.description
            }
            editReport(reportData.slug, body)
          } catch (e) {
            // console.log(e)
          }
          setIsLoading(false)
        } else {
          errors.showMessages();
        }
      }
    })
  }

  // NEW create copy logic
  const handleCreateCopy = () => {
    navigate('/create-report/',{state:{from:location.pathname, sourceReport:reportSlug, copymode:true},replace:true})
  }

  // OLD create copy logic
  const handleCreateCopyOld = ()=>{
    // console.log("Creating copy of:", reportData);
    setIsLoading(true)
    axiosPrivate.post('compilation/'+reportData.slug+"/copy")
      .then(res=>{
        if([200,201].includes(res.status) && res.data?.name){
          SweetAlert.fire(
            t('copyCreated'),
            t('createCopySuccessMsg',{newName:res.data?.name}),
            'success'
          )
        }else if(res.status < 500){
          SweetAlert.fire(
            t('somethingWentWrong'),
            t('tryAgainMsg'),
            'warning'
          )
        }else{
          SweetAlert.fire(
            t('somethingWentWrong'),
            t('serverErrorMsg'),
            'warning'
          )

        }
      })
      .catch(err=>{
        console.log(err);
        SweetAlert.fire(
          t('somethingWentWrong'),
          t('tryAgainMsg'),
          'warning'
        )
      })
      .finally(()=>{ setIsLoading(false) })
  } 

  const buildPathToHeird = (indicator)=>{
    return {
      pathToHeird:{
        subindicators:{
          slugs: [],
          names: []
        },
        info:{
          description:indicator.description
        }
      } 
    }
  }

  return (
    <Fragment>
      <Breadcrumb parent="Report List" title="Edit Report">
        <GoBack
          to="/manage-report"
          replace={true}
          text={t('returnToManageReports')}
        />
      </Breadcrumb>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <LoadingCardSpinner isLoading={loading || isLoading} />
              <CardHeader>
                <h5>{t('editReport')}</h5>
              </CardHeader>
              <Form className="form theme-form needs-validation" autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                <CardBody>
                  <Row>
                    <Col>
                    <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('name')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className={`form-control ${[ROLES.datamanager].includes(userRole) && 'no-border'}`}
                              name='name'
                              type="text"
                              placeholder={t('name')}
                              innerRef={register({ required: true, maxLength: 100 })}
                              disabled={[ROLES.datamanager].includes(userRole)}
                            />
                            <span className="">{errors.name?.type === 'required' && t('requiredField')}</span>
                            <span className="">{errors.name?.type === 'maxLength' && t('lengthLimit', {length: 100})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('description')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className={`form-control ${[ROLES.datamanager].includes(userRole) && 'no-border'}`}
                              name='description'
                              type="textarea"
                              rows='3'
                              placeholder={t('description')}
                              innerRef={register({ required: true })}
                              disabled={[ROLES.datamanager].includes(userRole)}
                            />
                            <span className='danger-text-color'>{errors.description && t('requiredField')}</span>
                          </div>
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col sm="9 offset-sm-3">
                      {
                        ![ROLES.datamanager].includes(userRole) && 
                        <>
                          <Button color="primary" className="mr-1">{t('edit')}</Button>
                          <Button color="light" onClick={resetFormValues} >{t('reset')}</Button>
                        </>
                      }
                    </Col>
                    {/* non editable fields */}
                    <Col className="mt-2">
                      <hr/>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('startDate')}</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Controller
                              control={control}
                              name="start"
                              defaultValue=''
                              render={( field ) => (
                                <DatePicker
                                  className="form-control digits no-border"
                                  selected={field.value}
                                  dateFormat="dd/MM/yyyy"
                                  disabled
                                />
                              )}
                            />
                            <span className='danger-text-color'>{errors.startDate && t('requiredField')}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('endDate')}</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Controller
                              control={control}
                              name="end"
                              defaultValue=''
                              render={( field ) => (
                                <DatePicker
                                  className="form-control digits no-border"
                                  selected={field?.value}
                                  dateFormat="dd/MM/yyyy"
                                  disabled
                                />
                              )}
                            />
                            <span className='danger-text-color'>{errors.endDate && t('requiredField')}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('company')}</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control no-border"
                              name='headquarter.company.companyName'
                              type="text"
                              innerRef={register()}
                              disabled
                            />
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('headquarter')}</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control no-border"
                              name="headquarter.name"
                              type="text"
                              innerRef={register()}
                              disabled
                            />
                          </div>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                  {![ROLES.datamanager].includes(userRole) &&   //????
                  <Row className='justify-content-center'>
                    <Col className='9 offset-sm-3'>
                      <Button onClick={handleCreateCopy} color="secondary">{t('createACopy')}</Button>
                    </Col>
                  </Row>
                  }
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
      <ReportIndicatorList 
        reportSlug={reportSlug} 
        handleBuildPathToHeird = {buildPathToHeird}
      />
      
    </Fragment>
  );
};

export default EditReport;