import React, { Fragment, useState, useRef } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import {Container,Row,Col,Card,CardHeader,CardBody,CardFooter,Button,Form,FormGroup,Label,Input} from 'reactstrap'
import {Cancel} from '../../constant'
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'

import { useTranslation } from 'react-i18next'
import SweetAlert from 'sweetalert2'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { useForm } from 'react-hook-form'
import LoadingCardSpinner from '../../components/LoadingCardSpinner/LoadingCardSpinner'
import useAuth from '../../hooks/useAuth'
import ROLES from '../../utils/roles'

const CreateSubIndicator = () => {
  const { t } = useTranslation('forms')
  const { auth: { role: userRole }, auth } = useAuth()
  const { register, handleSubmit, errors, getValues } = useForm()
  const { axiosPrivate, usePrivateAxiosHook } = useAxiosPrivate()
  const [{ data: {results: indicatorsData} = {}, loading, error }, refetchIndicators]= 
    usePrivateAxiosHook({
      url: `indicators/`,
      params: {
        id_Company: [ROLES.companymanager, ROLES.datamanager].includes(userRole) ? auth.companyId : null
      }
    })
  const [{ data: {results: subindicatorsData} = {}, loading: subIndicatorsFetchLoading, error: subindicatorsErrorFetch }, refetchSubindicator] = 
    usePrivateAxiosHook({
      url: `subindicators/`,
      params: {
        id_Company: [ROLES.companymanager, ROLES.datamanager].includes(userRole) ? auth.companyId : null
      }
    })
  const [isLoading, setIsLoading] = useState(false)
  const formRef = useRef()

  // checkbox type states
  const [selectedSubindicator, setSelectedSubindicator] = useState([])
  const [selectedIndicator, setSelectedIndicator] = useState([])
  const [selectDateType, setSelectDateType] = useState('indicator')
  // checkbox typeaheads errors  
  const [indicatorSelectionError, setIndicatorSelectionError] = useState(false)
  const [subindicatorSelectionError, setSubindicatorSelectionError] = useState(false)


  const handleSelectChange = value => {
    setSelectDateType(value.target.value)
  }

  const onSubmit = data => {
    if (selectDateType === 'indicator') {
      if (!selectedIndicator.length) {
        setIndicatorSelectionError(true)
        return
      } else {
        setIndicatorSelectionError(false)
      }
    } else if (selectDateType === 'subindicator') {
      if (!selectedSubindicator.length) {
        setSubindicatorSelectionError(true)
        return
      } else {
        setSubindicatorSelectionError(false)
      }
    }
    SweetAlert.fire({
      title: t('confirmCreationMsg'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: t('confirm'),
      cancelButtonText: t('cancel'),
      reverseButtons: true
    })
    .then((result) => {
      if (result.value) {
        if (data !== '') {
          setIsLoading(true)
          try {
            let body = {
              name: data.name,
              description: data?.description ? data.description : '',
              unitOfMeasurement: data?.unitOfMeasurement ? data.unitOfMeasurement : '',
              compilable:true
            }
            const reqLink = selectDateType === 'indicator' ? `indicator/${selectedIndicator[0].slug}/subindicators/` : `/subindicator/${selectedSubindicator[0].slug}/subindicators/`
            axiosPrivate.post(reqLink, body )
              .then(res => {
                if (res.status === 201) {
                  SweetAlert.fire(
                    t('saved'),
                    t('successCreationMsg'),
                    'success'
                  )
                  formRef.current.reset()
                  // refetch data to be updated
                  refetchSubindicator()
                  refetchIndicators()
                  setSelectedIndicator([])
                  setSelectedSubindicator([])
                } else {
                  SweetAlert.fire(
                    t('somethingWentWrongMsg'),
                    t('tryAgainMsg'),
                    'warning'
                  )
                }
              })
              .catch(err => {
                // console.log('error: ', err)
                if (err.request.status === 409) {
                  SweetAlert.fire(
                    t('somethingWentWrongMsg'),
                    t('sameSubindicatorDataExist'),
                    'warning'
                  )
                } else {
                  SweetAlert.fire(
                    t('somethingWentWrongMsg'),
                    t('tryAgainMsg'),
                    'warning'
                  )
                }
              })
              .finally(() => {
                setIsLoading(false)
              })
          } catch (e) {
            // console.log(e)
          }
        } else {
          errors.showMessages();
          setIsLoading(false)
        }
      }
    })
  }
  return (
    <Fragment>
      <Breadcrumb parent="Sub. Indicators" title="Create Sub. Indicator"/>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <LoadingCardSpinner isLoading={loading || isLoading || subIndicatorsFetchLoading} />
              <CardHeader>
                <h5>{t('createSubindicators')}</h5>
              </CardHeader>
              <Form className="form theme-form needs-validation" autoComplete='off' onSubmit={handleSubmit(onSubmit)}  innerRef={formRef}>
                <CardBody>
                  <Row>
                    <Col>
                    <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('name')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control"
                              name='name'
                              type="text"
                              placeholder={t('name')}
                              innerRef={register({ required: true, maxLength: 200 })}
                            />
                            <span className="">{errors.name?.type === 'required' && t('requiredField')}</span>
                            <span className="">{errors.name?.type === 'maxLength' && t('lengthLimit', {length: 200})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('description')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control"
                              name='description'
                              type="textarea"
                              rows='3'
                              placeholder={t('description')}
                              innerRef={register()}
                            />
                            <span className='danger-text-color'>{errors.description && t('requiredField')}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('unitOfMeasurement')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control"
                              name='unitOfMeasurement'
                              type="text"
                              placeholder="W/h"
                              innerRef={register({ maxLength: 20 })}
                            />
                            <span className="">{errors.unitOfMeasurement?.type === 'required' && t('requiredField')}</span>
                            <span className="">{errors.unitOfMeasurement?.type === 'maxLength' && t('lengthLimit', {length: 20})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup onChange={handleSelectChange} className="row">
                        <Label className="col-sm-3 col-form-label">{t('subindicatorParentType')}*</Label>
                        <Col sm="9" className="d-flex align-items-center">
                          <Label className="d-flex align-items-center p-l-10 p-r-10 m-0" for="edo-ani">
                            <Input className="radio_animated" value="indicator" id="edo-ani" type="radio" name="rdo-ani" defaultChecked />
                            {t('indicator')}
                          </Label>
                          <Label className="d-flex align-items-center p-l-10 p-r-10 m-0" for="edo-ani1">
                            <Input className="radio_animated" value="subindicator" id="edo-ani1" type="radio" name="rdo-ani"/>
                            {t('subindicator')}
                          </Label>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row" style={ selectDateType === 'subindicator' ? { display: 'none' } : {} }>
                        <Label className="col-sm-3 col-form-label">{t('parent')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Typeahead
                              style={{ width: '100%' }}
                              id="indicator"
                              labelKey='code'
                              onChange={setSelectedIndicator}
                              options={indicatorsData || []}
                              placeholder={t('subindicatorParentIndicatorPlaceholder')}
                              selected={selectedIndicator}
                              isValid={!!selectedIndicator.length}
                              isInvalid={!selectedIndicator.length}
                            />
                            <span className='danger-text-color'>{indicatorSelectionError && t('requiredField')}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row" style={ selectDateType === 'indicator' ? { display: 'none' } : {} }>
                        <Label className="col-sm-3 col-form-label">{t('parent')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Typeahead
                              style={{ width: '100%' }}
                              id="subindicator"
                              labelKey='name'
                              onChange={setSelectedSubindicator}
                              options={subindicatorsData || []}
                              placeholder={t('subindicatorParentSubindicatorPlaceholder')}
                              selected={selectedSubindicator}
                              isValid={!!selectedSubindicator.length}
                              isInvalid={!selectedSubindicator.length}
                            />
                            <span className='danger-text-color'>{subindicatorSelectionError && t('requiredField')}</span>
                          </div>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Col sm="9 offset-sm-3">
                    <Button color="primary" className="mr-1">{t('save')}</Button>
                    <Button color="light" type="reset" >{t('reset')}</Button>
                  </Col>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default CreateSubIndicator