import React, { Fragment } from 'react';
import sad from '../../assets/images/other-images/sad.png';
import { Link, useNavigate } from 'react-router-dom';
import {Container,Button,Media,Col} from "reactstrap"

const Error401 = () => {
    const navigate = useNavigate()
    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="error-wrapper">
                    <Container>
                        <Media body className="img-100" src={sad} alt="" />
                        <div className="error-heading">
                        <h2 className="headline font-warning">{"401"}</h2>
                        </div>
                        <Col md="8 offset-md-2">
                        <p className="sub-content">{"You are not authorized currently to access this page, ask your admin for more information"}</p>
                        </Col>
                        <Button onClick={() => navigate(-1)} color="warning-gradien" size='lg'>Go Back</Button>
                    </Container>
                </div>
            </div>
        </Fragment>
    );
};

export default Error401;