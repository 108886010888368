import React, { Fragment, useState, useMemo, useEffect } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import {Container,Row,Col,Card,CardHeader,CardBody,CardFooter,Button,Form,FormGroup,Label,Input} from 'reactstrap'
import {Cancel} from '../../constant'
import 'react-bootstrap-typeahead/css/Typeahead.css'

import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import SweetAlert from 'sweetalert2'
import { useForm } from 'react-hook-form'
import LoadingCardSpinner from '../../components/LoadingCardSpinner/LoadingCardSpinner'
import ROLES from '../../utils/roles'
import useAuth from '../../hooks/useAuth'

const EditPreset = () => {
  const { t } = useTranslation('forms')
  const { auth: { role: userRole } } = useAuth()
  const [isPublic, setIsPublic] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const { presetSlug, isEditEnabled } = location.state
  const [isLoading, setIsLoading] = useState(false)
  const { axiosPrivate, usePrivateAxiosHook } = useAxiosPrivate()
  const [{ data: presetData, loading, error }, refetch] = usePrivateAxiosHook(`preset/${presetSlug}/`)
  const { register, handleSubmit, reset, errors, getValues } = useForm({
    defaultValues: useMemo(() => {
      return presetData
    }, [presetData])
  })

  useEffect(() => {
    reset(presetData)
    setIsPublic(presetData?.isPublic)
  }, [presetData])

  const editSubindicator = (slug, body) => {
    axiosPrivate.patch(`preset/${slug}/`, body )
      .then(res => {
        if (res.status === 200) {
          SweetAlert.fire(
            t('edited'),
            t('successEditMsg'),
            'success'
          )
          navigate(-1)
        } else {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('tryAgainMsg'),
            'warning'
          )
        }
      })
      .catch(err => {
        if (err.request.status === 409) {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('samePresetDataExist'),
            'warning'
          )
        } else {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('tryAgainMsg'),
            'warning'
          )
        }
      })
  }

  const onSubmit = data => {
    // console.log(data)
    SweetAlert.fire({
      title: t('confirmEditMsg'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: t('confirm'),
      cancelButtonText: t('cancel'),
      reverseButtons: true
    })
    .then((result) => {
      if (result.value) {
        if (data !== '') {
          setIsLoading(true)
          try {
            let body = {
              name: data.name,
              description: data.description
            }
            if (![ROLES.superadmin, ROLES.admin].includes(userRole)) {
              body = { ...body, isPublic: isPublic }
            }
            editSubindicator(presetData.slug, body)
          } catch (e) {
            // console.log(e)
          }
          setIsLoading(false)
        } else {
          errors.showMessages();
        }
      }
    })
  }

  const handleSelectChange = value => {
    setIsPublic(value.target.value ==='public')
  }

  const renderVisibilityRadios = () => {
    if (![ROLES.superadmin, ROLES.admin].includes(userRole)) {
      if (isEditEnabled) {
        return (
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label">{t('visibility')}*</Label>
            <Col sm="9" className="d-flex align-items-center">
              <Label className="d-flex align-items-center p-l-10 p-r-10 m-0" for="edo-ani">
                <Input onChange={handleSelectChange} value='public' className="radio_animated" id="edo-ani" type="radio" name="rdo-ani" checked={isPublic} />
                {t('public')}
              </Label>
              <Label className="d-flex align-items-center p-l-10 p-r-10 m-0" for="edo-ani1">
                <Input onChange={handleSelectChange} value='private' className="radio_animated" id="edo-ani1" type="radio" name="rdo-ani" checked={!isPublic}/>
                {t('private')}
              </Label>
            </Col>
          </FormGroup>
        )
      } else {
        return (
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label">{t('visibility')}</Label>
            <Col sm="9">
              <div className="form-row">
                <Input
                  className="form-control no-border"
                  name='isPublic'
                  type="text"
                  value={isPublic ? t('public') : t('private')}
                  disabled
                />
              </div>
            </Col>
          </FormGroup>
        )
      } 
    }
  }

  return (
    <Fragment>
      <Breadcrumb parent="Preset" title="Edit Preset"/>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <LoadingCardSpinner isLoading={loading || isLoading} />
              <CardHeader>
                <h5>{t('editPreset')}</h5>
              </CardHeader>
              <Form className="form theme-form needs-validation" autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                <CardBody>
                  <Row>
                    <Col>
                    <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('name')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className={`form-control ${!isEditEnabled && 'no-border'}`}
                              name='name'
                              type="text"
                              placeholder={t('name')}
                              innerRef={register({ required: true, maxLength: 50 })}
                              disabled={!isEditEnabled}
                            />
                            <span className="">{errors.name?.type === 'required' && t('requiredField')}</span>
                            <span className="">{errors.name?.type === 'maxLength' && t('lengthLimit', {length: 50})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('description')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className={`form-control ${!isEditEnabled && 'no-border'}`}
                              name='description'
                              type="textarea"
                              rows='3'
                              placeholder={t('description')}
                              innerRef={register({ required: true })}
                              disabled={!isEditEnabled}
                            />
                            <span className='danger-text-color'>{errors.description && t('requiredField')}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      {renderVisibilityRadios()}
                      {isEditEnabled && 
                        <Col sm="9 offset-sm-3">
                          <Button color="primary" className="mr-1">{t('edit')}</Button>
                          {/* <Button color="light" type="reset" >{t('reset')}</Button> */}
                        </Col>
                      }
                      {/* non editable fields */}
                    </Col>
                  </Row>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default EditPreset;