import React, { Fragment,useState,useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './components/app';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store'
import {BrowserRouter as Router,Routes,Route,Navigate} from 'react-router-dom'
import { CSSTransition,TransitionGroup } from 'react-transition-group'
import {routes} from './route';
import ConfigDB from './data/customizer/config'
import './assets/i18n/i18n'
import Logins from './pages/authentication/login'
import Error404 from './pages/errors/error404'
import { AuthProvider } from './context/AuthProvider';
import axios from './api/axios'

const Root = (props) =>  {

  useEffect(() => {
    if(localStorage.getItem("layout_version") === "dark-only"){
      document.body.className = "dark-only"
    }
  }, [])

    return(
      <Fragment>
        <Provider store={store}>
          <Router basename={`/`}>
            <AuthProvider>
              <Routes>
                <Route path={'/*'} element={ <App/> } />
                <Route path='*' exact={true} element={<Error404/>} />
              </Routes>
            </AuthProvider>
          </Router>
        </Provider>
      </Fragment>
      )
}
ReactDOM.render(<Root/>,
  document.getElementById('root')
);

serviceWorker.unregister();
