import React, { Fragment, useState, useEffect, useMemo } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import {Container,Row,Col,Card,CardHeader,CardBody,CardFooter,Button,Form,FormGroup,Label,Input} from 'reactstrap'

import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import SweetAlert from 'sweetalert2'
import { useForm } from 'react-hook-form'
import LoadingCardSpinner from '../../components/LoadingCardSpinner/LoadingCardSpinner'


const EditIndicator = () => {

  const { t } = useTranslation('forms')
  const location = useLocation()
  const navigate = useNavigate()
  const { indicatorSlug, isEditEnabled } = location.state
  const [isLoading, setIsLoading] = useState(false)
  const { axiosPrivate, usePrivateAxiosHook } = useAxiosPrivate()
  const [{ data: indicatorData, loading, error }, refetch] = usePrivateAxiosHook(`indicator/${indicatorSlug}/`)
  const { register, handleSubmit, reset, errors, getValues } = useForm({
    defaultValues: useMemo(() => {
      return indicatorData
    }, [indicatorData])
  })

  useEffect(() => {
    reset(indicatorData)
  }, [indicatorData])


  const editIndicator = (slug, body) => {
    axiosPrivate.patch(`indicator/${slug}/`, body )
      .then(res => {
        if (res.status === 200) {
          SweetAlert.fire(
            t('edited'),
            t('successEditMsg'),
            'success'
          )
          navigate(-1)
        } else {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('tryAgainMsg'),
            'warning'
          )
        }
      })
      .catch(err => {
        // console.log('error: ', err)
        if (err.request.status === 409) {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('sameIndicatorDataExist'),
            'warning'
          )
        } else {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('tryAgainMsg'),
            'warning'
          )
        }
      })
  }

  const onSubmit = data => {
    // console.log(data)
    SweetAlert.fire({
      title: t('confirmEditMsg'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: t('confirm'),
      cancelButtonText: t('cancel'),
      reverseButtons: true
    })
    .then((result) => {
      if (result.value) {
        if (data !== '') {
          // console.log('req data body', data);
          setIsLoading(true)
          try {
            const body = {
              code: data.code,
              description: data?.description ? data.description : '',
              link: data?.link ? data.link : '',
              unitOfMeasurement: data?.unitOfMeasurement ? data.unitOfMeasurement : ''
            }
            editIndicator(indicatorData.slug, body)
            // console.log(body)
          } catch (e) {
            // console.log(e)
          }
          setIsLoading(false)
        } else {
          errors.showMessages();
        }
      }
    })
  }

  return (
    <Fragment>
      <Breadcrumb parent="Indicators" title="Edit Indicator"/>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <LoadingCardSpinner isLoading={loading || isLoading} />
              <CardHeader>
                <h5>{ isEditEnabled ? t('editIndicators') : t('previewIndicator')}</h5>
              </CardHeader>
              <Form className="form theme-form needs-validation" autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                <CardBody>
                  <Row>
                    <Col>
                    <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('code')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className={`form-control ${!isEditEnabled && 'no-border'}`}
                              name='code'
                              type="text"
                              placeholder="Codice"
                              innerRef={register({ required: true, maxLength: 50 })}
                              disabled={!isEditEnabled}
                            />
                            <span className="">{errors.code?.type === "required" && t('requiredField')}</span>
                            <span className="">{errors.code?.type === 'maxLength' && t('lengthLimit', {length: 50})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('description')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className={`form-control ${!isEditEnabled && 'no-border'}`}
                              name='description'
                              type= "textarea"
                              rows='3'
                              placeholder={t('description')}
                              innerRef={register()}
                              disabled={!isEditEnabled}
                            />
                            <span className='danger-text-color'>{errors.description && t('requiredField')}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">URL*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className={`form-control ${!isEditEnabled && 'no-border'}`}
                              name='link'
                              type="url"
                              placeholder="https://www.example.com/"
                              innerRef={register({ maxLength: 200 })}
                              disabled={!isEditEnabled}
                            />
                            <span className="">{errors.link?.type === 'required' && t('requiredField')}</span>
                            <span className="">{errors.link?.type === 'maxLength' && t('lengthLimit', {length: 200})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('unitOfMeasurement')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className={`form-control ${!isEditEnabled && 'no-border'}`}
                              name='unitOfMeasurement'
                              type="text"
                              placeholder="W/h"
                              innerRef={register({ maxLength: 20 })}
                              disabled={!isEditEnabled}
                            />
                            <span className="">{errors.unitOfMeasurement?.type === 'required' && t('requiredField')}</span>
                            <span className="">{errors.unitOfMeasurement?.type === 'maxLength' && t('lengthLimit', {length: 20})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      {
                        isEditEnabled && 
                        <Col sm="9 offset-sm-3">
                          <Button color="primary" className="mr-1">{t('edit')}</Button>
                          {/* <Button color="light" type="reset" >{t('reset')}</Button> */}
                        </Col>
                      }
                      {/* non editable fields */}
                    </Col>
                  </Row>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default EditIndicator;