import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import man from '../../assets/images/dashboard/profile.jpg'
import { LogIn, User, Bell } from 'react-feather';
import useLogout from "../../hooks/useLogout"
import useAuth from '../../hooks/useAuth'
import { useTranslation } from 'react-i18next'

import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage,
  translate,
} from 'react-switch-lang';

import {Account,LogOut} from '../../constant'

import useAxiosPrivate from '../../hooks/useAxiosPrivate'

import en from '../../assets/i18n/en.json'
import it from '../../assets/i18n/it.json'

setTranslations({ en, it });
setDefaultLanguage('it')
setLanguageCookie();

const Rightbar = (props) => {   
  const { i18n } = useTranslation()
  const { t } = useTranslation('sections')
  const navigate = useNavigate()
  const logout = useLogout()
  const { auth: { language, role, username } } = useAuth()
  const { usePrivateAxiosHook } = useAxiosPrivate()

  // get notifications
  const [{ data: notificationsData, loading: notificationsLoading, error: notificationsError }, refetchNotifications] 
    = usePrivateAxiosHook({url: `notifications/`})
  const [langdropdown, setLangdropdown] = useState(false)
  const [moonlight, setMoonlight] = useState(false)
  const [selected, setSelected] = useState("it")
  const [notificationDropDown, setNotificationDropDown] = useState(false)

  const handleSetLanguage = (key) => {
    i18n.changeLanguage(key)
    setLanguage(key)
    setSelected(key)
    localStorage.setItem('language', key)
  }

  const handleLogout = async () => {
    await logout()
    navigate(`/login`)
  }

  useEffect(() => {
    if(localStorage.getItem("layout_version") === "dark-only"){
      document.body.className = "dark-only"
      setMoonlight(true)
    }
    if(localStorage.getItem("language")){
      handleSetLanguage(localStorage.getItem("language"))
    }
  }, []);

  const LanguageSelection = (language) => {
    if (language) {
      setLangdropdown(!language)
    } else {
      setLangdropdown(!language)
    }
  }

  const MoonlightToggle = (light) => {
    if (light) {
      setMoonlight(!light)
      document.body.className = "light"
      localStorage.setItem('layout_version', 'light');
    } else {
      setMoonlight(!light)
      document.body.className = "dark-only"
      localStorage.setItem('layout_version', 'dark-only');
    }
  }

  const renderNotification = (item, index) => {
    return (
      <li key={index}>
        <p>
          <i className="fa fa-circle-o mr-3 font-primary"></i>
          {t('notificationMessage', { count: item.reporting_period_count, collection: item.name })}
        </p>
      </li>
    )
  }

  return (
    <Fragment>
      <div className="nav-right col-8 pull-right right-header p-0">
        <ul className="nav-menus">
          <li className="language-nav">
            <div className={`translate_wrapper ${langdropdown ? 'active' : ''}`}>
              <div className="current_lang">
                <div className="lang" onClick={() => LanguageSelection(langdropdown)}>
                  <i className={`flag-icon flag-icon-${selected === "en" ? "us" : selected === "du" ? "de" : selected}`}></i>
                  <span className="lang-txt">{selected}</span>
                </div>
              </div>
              <div className={`more_lang ${langdropdown ? 'active' : ''}`}>
                <div className="lang" onClick={() => handleSetLanguage('it')}><i className="flag-icon flag-icon-it"></i><span className="lang-txt">Italian<span> {"(IT)"}</span></span></div>
                <div className="lang" onClick={() => handleSetLanguage('en')}><i className="flag-icon flag-icon-us"></i><span className="lang-txt">English<span> {"(EN)"}</span></span></div>
              </div>
            </div>
          </li>
          <li className="onhover-dropdown">
            <div className="notification-box" onClick={() => setNotificationDropDown(!notificationDropDown)}><Bell /><span className="badge badge-pill badge-secondary">{notificationsData?.length || 0}</span></div>
            <ul className={`notification-dropdown onhover-show-div ${notificationDropDown ? "active" : ""}`}>
              <li>
                <Bell />
                <h6 className="f-18 mb-0">{t('notifications')}</h6>
              </li>
              {
                !!notificationsData?.length ? notificationsData.map((item, index) => renderNotification(item, index)) : 
                <li>
                  <p>{t('emptyNotifications')}<span className="pull-right"></span></p>
                </li>
              }
            </ul>
          </li>
          <li>
            <div className="mode" onClick={() => MoonlightToggle(moonlight)}><i className={`fa ${moonlight ? 'fa-lightbulb-o' : 'fa-moon-o'}`}></i></div>
          </li>
          <li className="profile-nav onhover-dropdown p-0">
            <div className="media profile-media">
              <img className="b-r-10" src={man} alt="" />
              <div className="media-body"><span>{username}</span>
                <p className="mb-0 font-roboto">{role} <i className="middle fa fa-angle-down"></i></p>
              </div>
            </div>
            <ul className="profile-dropdown onhover-show-div">
              <li><Link to='/user-profile/'><User /><span>{Account} </span></Link></li>
              <li onClick={handleLogout}><LogIn/><span>{LogOut}</span></li>
            </ul>
          </li>
        </ul>
      </div>
    </Fragment>

  );
}
export default translate(Rightbar);