import React, { Fragment } from 'react';
import { Outlet } from "react-router-dom"
import Header from '../../layout/header'
import Sidebar from '../../layout/sidebar'
import Footer from '../../layout/footer'
import ThemeCustomize from "../../layout/theme-customizer";
import {ToastContainer} from 'react-toastify'

const Layout = () => {
    return (
        <Fragment>
            <div className="page-wrapper compact-wrapper" id="pageWrapper">
                <Header/>
                <div className="page-body-wrapper sidebar-icon">
                <Sidebar/>
                <div className="page-body">
                    <Outlet />
                </div>
                <Footer/>
                <ThemeCustomize/>
                </div>
            </div>
            <ToastContainer/>
        </Fragment>
    )
}

export default Layout
