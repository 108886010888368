import React, { Fragment,useState,useEffect} from 'react';
import DataTable from 'react-data-table-component'
import { Search } from 'react-feather'
import { Link, useNavigate } from 'react-router-dom'
import { Input, Container,Row,Col,Card,CardHeader, CardBody, InputGroup, InputGroupAddon, InputGroupText} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import SweetAlert from 'sweetalert2'

import Breadcrumb from '../../layout/breadcrumb'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import LoadingCardSpinner from '../../components/LoadingCardSpinner/LoadingCardSpinner'
import {debounce} from 'lodash'
import useAuth from '../../hooks/useAuth'
import ROLES from '../../utils/roles'

const ReportsList = () =>  {
  const { t } = useTranslation('dataTables')
  const navigate = useNavigate()
  const [data,setData] = useState([])
  const { auth: { role: userRole }, auth } = useAuth()

  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState(false)
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const { axiosPrivate } = useAxiosPrivate()
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)

  const [searchValue, setSearchValue] = useState('')
  const [sortColumn, setSortColumn] = useState('')
  const [sortDescDirection, setSortDescDirection] = useState(false)
  
  useEffect(() => {
    try {
      getReports()
    } catch (e) {
      // console.log(e)
    }
  }, [])


  const getReports = async (start = 1, limit = 10, sorting, desc, name) => {
    setLoading(true)
    let params = { start, limit }
    if (sorting) params = { ...params, sorting }
    if (desc) params = { ...params, desc }
    if (name) params = { ...params, name }
    let req = 'compilations/'
    // if ([ROLES.superadmin, ROLES.admin].includes(userRole)) {
    //   req = 'compilations/'
    // } else if ([ROLES.companymanager, ROLES.datamanager].includes(userRole)) {
    //   req = `company/${auth.companySlug}/datacollections/` //!!!!!!!!!!!!
    // }
    const response = await axiosPrivate.get(req,{params})
      .finally(() => {
        setLoading(false)
      })
    setData(response?.data?.compilations ? [...response.data.compilations] : [])
    setTotalRows(response?.data?.compilations ? response.data.compilations.length : 0)
  }

  useEffect(()=>{
    // console.log(data.map(r=>r.slug));
  },[data])

  const deleteReport = async (slug) => {
    setIsDeleteLoading(true)
    axiosPrivate.delete(`compilation/${slug}/`)
      .then(res => {
        setData(prev => prev.filter(report => report.slug !== slug))
        // check to understand if element is last on the page and in this case after delete switch page behind by one
        if (totalRows - ((currentPage - 1) * perPage) === 1 && totalRows !== 1) {
          handlePageChange(currentPage - 1)
        } else {
          handlePageChange(currentPage)
        }
        SweetAlert.fire(
          t('successfullyDeleted'),
          '',
          'success'
        )
      })
      .catch(err => {
        if (err.request.status === 404) {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('maybeWasAlreadyDeleted'),
            'warning'
          )
        } else {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('tryAgainMsg'),
            'warning'
          )
        }
      })
      .finally(() => {
        setIsDeleteLoading(false)
      })
  }

  const onReportDeletePress = async (slug) => {
    SweetAlert.fire({
      title: t('areYouSureToDelete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    })
    .then((result) => {
      if (result.value) {
        deleteReport(slug)
      }
    })
  }

  const tableColumns = [
    {
        name: t('name'),
        selector: row => row.name,
        sortable: true,
        center:true,
        wrap: true,
        sortField: 'name'
    },
    {
        name: t('description'),
        selector: row => row.description,
        sortable: true,
        center:true,
        wrap: true,
        sortField: 'description'
    },
    {
        name: t('company'),
        selector: row => row.company.companyName,
        sortable: true,
        center:true,
        wrap: true,
        sortField: 'id_Company'
    },
    {
        name: t('headquarter'),
        selector: row => row.headquarter.name,
        sortable: true,
        center:true,
        wrap: true,
        sortField: 'id_Headquarter'
    },
    {
        name: t('start'),
        selector: row => row.start,
        sortable: true,
        center:true,
        wrap: true,
        sortField: 'start'
    },
    {
        name: t('end'),
        selector: row => row.end,
        sortable: true,
        center:true,
        wrap: true,
        sortField: 'end'
    },
    {
      name: '',
      selector: row => renderRowBtns(row),
      sortable: false,
      center:true,
      ignoreRowClick: true
    }
  ]

  const handlePageChange = page => {
    setCurrentPage(page)
    getReports((page -1) * perPage + 1, perPage)
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    const start = (page - 1) * newPerPage + 1
    const limit = newPerPage
    await getReports(start, limit, sortColumn, sortDescDirection)
    setPerPage(newPerPage)
  }

  const onSearch = (query) => {
    getReports(1, perPage, false, false, query)
  }

  const debouncedSearch = React.useRef(
    debounce(onSearch, 500)
  ).current

  const handleSort = (column, sortDirection) => {
    const isDescSort = sortDirection === 'desc'
    setSortColumn(column.sortField)
    setSortDescDirection(isDescSort)
    getReports(1, perPage, column.sortField, isDescSort)
  }
  const renderRowBtns = (row) => {
    return (
      <>
        <Link
          to={`/edit-report/${row.slug}`}
          state={{ reportSlug: row.slug }}
          className="p-1"
          color="primary"
          size="sm"
        >
          <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'primary' }}></i>
        </Link>
        {![ROLES.datamanager].includes(userRole) && <span onClick={() => onReportDeletePress(row.slug)}><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></span>}
      </>
    )
  }
  return (
    <Fragment>
      <Breadcrumb parent="Report" title="Report List"/>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <LoadingCardSpinner isLoading={isDeleteLoading || loading} />
              <CardHeader>
                <div className='header_filters_container'>
                  <h5 className='datatable_header_title'>{t('reports')}</h5>
                  <Row className='justify-content-end'>
                    <Col sm="10" className="d-flex align-items-center">
                      <InputGroup>
                        <Input
                          className="form-control align-self-center"
                          type="search"
                          placeholder={t('search')}
                          value={searchValue}
                          onChange={event => {
                            setSearchValue(event.target.value)
                            debouncedSearch(event.target.value)
                          }}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText style={{backgroundColor: 'inherit'}} className="form-control">
                            <Search aria-hidden="true" className="" />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </Row>
                </div>
              </CardHeader>
              <CardBody>
                <DataTable
                  noDataComponent={<h6 className="mb-4 mt-4">{t('noResults')}</h6> }
                  data={data}
                  columns={tableColumns}
                  striped={true}
                  center={true}
                  persistTableHead
                  highlightOnHover
                  pointerOnHover
                  pagination
                  paginationComponentOptions={{rowsPerPageText: t('rowsPerPage'), rangeSeparatorText: t('of')}}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  paginationTotalRows={totalRows}
                  paginationServer
                  sortServer
                  onSort={handleSort}
                  onRowClicked={(row) => navigate(`/edit-report/${row.slug}`)}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>            
    </Fragment>
  )
}

export default ReportsList;