import React, { Fragment, useState, useMemo, useEffect } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import {Container,Row,Col,Card,CardHeader,CardBody,CardFooter,Button,Form,FormGroup,Label,Input} from 'reactstrap'
import {EmailAddress, Cancel} from '../../constant'
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'

import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import SweetAlert from 'sweetalert2'
import { useForm } from 'react-hook-form'
import LoadingCardSpinner from '../../components/LoadingCardSpinner/LoadingCardSpinner'

const EditSubIndicator = () => {
  const { t } = useTranslation('forms')
  const location = useLocation()
  const navigate = useNavigate()
  const { subindicatorSlug, isEditEnabled } = location.state
  const [isLoading, setIsLoading] = useState(false)
  const { axiosPrivate, usePrivateAxiosHook } = useAxiosPrivate()
  const [{ data: subindicatorData, loading, error }, refetch] = usePrivateAxiosHook(`subindicator/${subindicatorSlug}/`)
  const { register, handleSubmit, reset, errors, getValues } = useForm({
    defaultValues: useMemo(() => {
      return subindicatorData
    }, [subindicatorData])
  })

  useEffect(() => {
    reset(subindicatorData)
  }, [subindicatorData])

  // console.log(subindicatorData);

  const editSubindicator = (slug, body) => {
    axiosPrivate.patch(`subindicator/${slug}/`, body )
      .then(res => {
        // console.log('edit company res', res);
        if (res.status === 200) {
          SweetAlert.fire(
            t('edited'),
            t('successEditMsg'),
            'success'
          )
          navigate(-1)
        } else {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('tryAgainMsg'),
            'warning'
          )
        }
      })
      .catch(err => {
        // console.log('error: ', err)
        if (err.request.status === 409) {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('sameSubindicatorDataExist'),
            'warning'
          )
        } else {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('tryAgainMsg'),
            'warning'
          )
        }
      })
  }

  const onSubmit = data => {
    // console.log(data)
    SweetAlert.fire({
      title: t('confirmEditMsg'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: t('confirm'),
      cancelButtonText: t('cancel'),
      reverseButtons: true
    })
    .then((result) => {
      if (result.value) {
        if (data !== '') {
          // console.log('req data body', data)
          setIsLoading(true)
          try {
            const body = {
              name: data.name,
              description: data?.description ? data.description : '',
              unitOfMeasurement: data?.unitOfMeasurement ? data.unitOfMeasurement : ''
            }
            editSubindicator(subindicatorData.slug, body)
            // console.log(body)
          } catch (e) {
            // console.log(e)
          }
          setIsLoading(false)
        } else {
          errors.showMessages();
        }
      }
    })
  }

  return (
    <Fragment>
      <Breadcrumb parent="Sub. Indicators" title="Edit Sub. Indicator"/>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <LoadingCardSpinner isLoading={loading || isLoading} />
              <CardHeader>
                <h5>{ isEditEnabled ? t('editSubindicators') : t('previewSubindicators')}</h5>
              </CardHeader>
              <Form className="form theme-form needs-validation" autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                <CardBody>
                  <Row>
                    <Col>
                    <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('name')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className={`form-control ${!isEditEnabled && 'no-border'}`}
                              name='name'
                              type="text"
                              placeholder={t('code')}
                              innerRef={register({ required: true, maxLength: 200 })}
                              disabled={!isEditEnabled}
                            />
                            <span className="">{errors.name?.type === 'required' && t('requiredField')}</span>
                            <span className="">{errors.name?.type === 'maxLength' && t('lengthLimit', {length: 200})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('description')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className={`form-control ${!isEditEnabled && 'no-border'}`}
                              name='description'
                              type="textarea"
                              rows='3'
                              placeholder={t('description')}
                              innerRef={register()}
                              disabled={!isEditEnabled}
                            />
                            <span className='danger-text-color'>{errors.description && t('requiredField')}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('unitOfMeasurement')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className={`form-control ${!isEditEnabled && 'no-border'}`}
                              name='unitOfMeasurement'
                              type="text"
                              placeholder="W/h"
                              innerRef={register({ maxLength: 20 })}
                              disabled={!isEditEnabled}
                            />
                            <span className="">{errors.unitOfMeasurement?.type === 'required' && t('requiredField')}</span>
                            <span className="">{errors.unitOfMeasurement?.type === 'maxLength' && t('lengthLimit', {length: 20})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      {isEditEnabled && 
                        <Col sm="9 offset-sm-3">
                          <Button color="primary" className="mr-1">{t('edit')}</Button>
                          {/* <Button color="light" type="reset" >{t('reset')}</Button> */}
                        </Col>
                      }
                      {/* non editable fields */}
                      <Col className="mt-2" style={ !subindicatorData?.parent ? { display: 'none' } : {} }>
                        <hr/>
                        <>
                          <h5 className="mt-4 mb-4">{t('parent')}: {subindicatorData?.parent?.code ? t('indicator') : t('subindicator')}</h5>
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label">{t('code')}</Label>
                            <Col sm="9">
                              <div className="form-row">
                                <Input
                                  className="form-control no-border"
                                  name={subindicatorData?.parent?.code ? 'parent.code' : 'parent.name'}
                                  type="text"
                                  innerRef={register()}
                                  disabled
                                />
                              </div>
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label">{t('description')}</Label>
                            <Col sm="9">
                              <div className="form-row">
                                <Input
                                  className="form-control no-border"
                                  name='parent.description'
                                  type="text"
                                  innerRef={register()}
                                  disabled
                                />
                              </div>
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label">{t('unitOfMeasurement')}</Label>
                            <Col sm="9">
                              <div className="form-row">
                                <Input
                                  className="form-control no-border"
                                  name='parent.unitOfMeasurement'
                                  type="text"
                                  innerRef={register()}
                                  disabled
                                />
                              </div>
                            </Col>
                          </FormGroup>
                          <FormGroup className="row" style={ !subindicatorData?.parent?.link ? { display: 'none' } : {} }>
                            <Label className="col-sm-3 col-form-label">{t('link')}</Label>
                            <Col sm="9">
                              <div className="form-row">
                                <Input
                                  className="form-control no-border"
                                  name='parent.link'
                                  type="text"
                                  innerRef={register()}
                                  disabled
                                />
                              </div>
                            </Col>
                          </FormGroup>
                        </>
                      </Col>
                    </Col>
                  </Row>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default EditSubIndicator;