import React, { Fragment, useState, useRef, useEffect } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import {Container,Row,Col,Card,CardHeader,CardBody,CardFooter,Button,Form,FormGroup,Label,Input} from 'reactstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { Typeahead } from 'react-bootstrap-typeahead'

import { useTranslation } from 'react-i18next'
import SweetAlert from 'sweetalert2'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { useForm } from 'react-hook-form'
import LoadingCardSpinner from '../LoadingCardSpinner/LoadingCardSpinner'
import useAuth from '../../hooks/useAuth'
import ROLES from '../../utils/roles'

const CreateCompany = () => {
  const { auth: { role: userRole }, auth } = useAuth()
  const { t } = useTranslation('forms')
  const { register, handleSubmit, errors } = useForm()
  const { axiosPrivate, usePrivateAxiosHook } = useAxiosPrivate()
  const [{ data: {results : holdingData} = {}, loading, error }, refetch] = usePrivateAxiosHook({url: `holding/`}, { manual: userRole !== ROLES.admin && userRole !== ROLES.superadmin})
  const [holdingSelection, setHoldingSelection] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const formRef = useRef()

  const onSubmit = data => {
      SweetAlert.fire({
        title: t('confirmCreationMsg'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: t('confirm'),
        cancelButtonText: t('cancel'),
        reverseButtons: true
      })
      .then((result) => {
        if (result.value) {
          if (data !== '') {
            setIsLoading(true)
            try {
              let body = {
                VATNumber: data.VATNumber,
                companyName: data.companyName
              }
              if (!!holdingSelection.length) {
                body = { ...body, id_Holding: holdingSelection[0].id }
              }
              // let query = 
              if ([ROLES.groupleader].includes(userRole)) {
                body = { ...body, id_Holding: auth.holdingId }
              }
              // TODO: handle company creation by groupleader
              // console.log(body)
              axiosPrivate.post('company/', body )
                .then(res => {
                  if (res.status === 201) {
                    SweetAlert.fire(
                      t('saved'),
                      t('successCreationMsg'),
                      'success'
                    )
                    formRef.current.reset()
                    setHoldingSelection([])
                  } else {
                    SweetAlert.fire(
                      t('somethingWentWrongMsg'),
                      t('tryAgainMsg'),
                      'warning'
                    )
                  }
                })
                .catch(err => {
                  // console.log('error: ', err)
                  if (err.request.status === 409) {
                    SweetAlert.fire(
                      t('somethingWentWrongMsg'),
                      t('sameCompanyDataExist'),
                      'warning'
                    )
                  } else {
                    SweetAlert.fire(
                      t('somethingWentWrongMsg'),
                      t('tryAgainMsg'),
                      'warning'
                    )
                  }
                })
                .finally(() => {
                  setIsLoading(false)
                })
            } catch (e) {
              // console.log(e)
            }
          } else {
            errors.showMessages();
            setIsLoading(false)
          }
        }
      })
  }

  return (
    <Fragment>
      <Breadcrumb parent="Company" title="Create Company"/>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <LoadingCardSpinner isLoading={isLoading || loading} />
              <CardHeader>
                <h5>{t('createCompany')}</h5>
              </CardHeader>
              <Form className="form theme-form needs-validation" autoComplete='off' onSubmit={handleSubmit(onSubmit)}  innerRef={formRef}>
                <CardBody>
                  <Row>
                    <Col>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('businessName')}</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control"
                              name="companyName"
                              type="text"
                              placeholder={t('businessNamePlaceholder')} 
                              innerRef={register({ required: true, maxLength: 200 })}
                              />
                            <span className="">{errors.companyName?.type === 'required' && t('requiredField')}</span>
                            <span className="">{errors.companyName?.type === 'maxLength' && t('lengthLimit', {length: 200})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('VAT')}</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control"
                              name='VATNumber'
                              type="number"
                              placeholder={t('VATPlaceholder')}
                              innerRef={register({ required: true, maxLength: 100 })}
                            />
                            <span className="">{errors.VATNumber?.type === 'required' && t('requiredField')}</span>
                            <span className="">{errors.VATNumber?.type === 'maxLength' && t('lengthLimit', {length: 100})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      {[ROLES.superadmin, ROLES.admin].includes(userRole) &&<FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('holding')}</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Typeahead
                              style={{ width: '100%' }}
                              id="select-preset-indicators"
                              labelKey="companyName"
                              onChange={setHoldingSelection}
                              options={holdingData || []}
                              placeholder={t('holdingNotRequiredPlaceholder')}
                              selected={holdingSelection}
                              isValid={!!holdingSelection.length}
                              isInvalid={!holdingSelection.length}
                            />
                          </div>
                        </Col>
                      </FormGroup>}
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Col sm="9 offset-sm-3">
                    <Button color="primary" className="mr-1">{t('save')}</Button>
                    <Button color="light" type="reset" >{t('cancel')}</Button>
                  </Col>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CreateCompany;