import React, { Fragment, useState, useRef } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import {Container,Row,Col,Card,CardHeader,CardBody,CardFooter,Button,Form,FormGroup,Label,Input} from 'reactstrap'
import {Cancel} from '../../constant'
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'

import { useTranslation } from 'react-i18next'
import SweetAlert from 'sweetalert2'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { useForm } from 'react-hook-form'
import LoadingCardSpinner from '../LoadingCardSpinner/LoadingCardSpinner'

const CreateHolding = () => {
  const [multiSelections, setMultiSelections] = useState([])

  const { t } = useTranslation('forms')
  const { register, handleSubmit, errors, getValues } = useForm()
  const { axiosPrivate, usePrivateAxiosHook } = useAxiosPrivate()
  const [
    { data: {results: companyData} = {}, loading, error }
    , refetch
  ] = usePrivateAxiosHook({ url: `company/`,  params: { not_holding: true } })

  const [isLoading, setIsLoading] = useState(false)
  const formRef = useRef()

  const onSubmit = data => {
    SweetAlert.fire({
      title: t('confirmCreationMsg'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: t('confirm'),
      cancelButtonText: t('cancel'),
      reverseButtons: true
    })
    .then((result) => {
      if (result.value) {
        if (data !== '') {
          setIsLoading(true)
          try {
            let body = {
              VATNumber: data.VATNumber,
              companyName: data.companyName
            }
            const companyIds = multiSelections.map(item => item.id)
            if (!!companyIds.length) {
              body = { ...body, companies: companyIds }
            }
            axiosPrivate.post('holding/', body )
              .then(res => {
                if (res.status === 201) {
                  SweetAlert.fire(
                    t('saved'),
                    t('successCreationMsg'),
                    'success'
                  )
                  refetch()
                  formRef.current.reset()
                  setMultiSelections([])
                } else {
                  SweetAlert.fire(
                    t('somethingWentWrongMsg'),
                    t('tryAgainMsg'),
                    'warning'
                  )
                }
              })
              .catch(err => {
                // console.log('error: ', err)
                if (err.request.status === 409) {
                  SweetAlert.fire(
                    t('somethingWentWrongMsg'),
                    t('sameHoldingDataExist'),
                    'warning'
                  )
                } else {
                  SweetAlert.fire(
                    t('somethingWentWrongMsg'),
                    t('tryAgainMsg'),
                    'warning'
                  )
                }
              })
              .finally(() => {
                setIsLoading(false)
              })
            // console.log(body)
          } catch (e) {
            // console.log(e)
          }
        } else {
          errors.showMessages();
          setIsLoading(false)
        }
      }
    })
}

  return (
    <Fragment>
      <Breadcrumb parent="Holding" title="Create Holding"/>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
            <LoadingCardSpinner isLoading={isLoading || loading} />
              <CardHeader>
                <h5>{t('createHolding')}</h5>
              </CardHeader>
              <Form className="form theme-form needs-validation" autoComplete='off' onSubmit={handleSubmit(onSubmit)}  innerRef={formRef}>
                <CardBody>
                  <Row>
                    <Col>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('businessName')}</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control"
                              name="companyName"
                              type="text"
                              placeholder={t('businessNamePlaceholder')} 
                              innerRef={register({ required: true, maxLength: 200 })}
                              />
                            <span className="">{errors.companyName?.type === 'required' && t('requiredField')}</span>
                            <span className="">{errors.companyName?.type === 'maxLength' && t('lengthLimit', {length: 200})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('VAT')}</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control"
                              name='VATNumber'
                              type="number"
                              placeholder={t('VATPlaceholder')}
                              innerRef={register({ required: true, maxLength: 200 })}
                            />
                            <span className="">{errors.VATNumber?.type === 'required' && t('requiredField')}</span>
                            <span className="">{errors.VATNumber?.type === 'maxLength' && t('lengthLimit', {length: 200})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('companies')}</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Typeahead
                              style={{ width: '100%', borderColor: 'black !important' }}
                              id="select-holding-companies"
                              labelKey="companyName"
                              multiple
                              onChange={setMultiSelections}
                              options={companyData || []}
                              placeholder="Seleziona Aziende"
                              selected={multiSelections}
                              isValid={!!multiSelections.length}
                            />
                          </div>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Col sm="9 offset-sm-3">
                    <Button color="primary" className="mr-1">{t('save')}</Button>
                    <Button color="light" type="reset" >{t('reset')}</Button>
                  </Col>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CreateHolding;