import React, { Fragment, useState, useMemo, useEffect } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import {Container,Row,Col,Card,CardHeader,CardBody,CardFooter,Button,Form,FormGroup,Label,Input} from 'reactstrap'
import {Cancel} from '../../constant'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import SweetAlert from 'sweetalert2'
import { useForm } from 'react-hook-form'
import LoadingCardSpinner from '../LoadingCardSpinner/LoadingCardSpinner'

const EditHeadquarters = () => {
  const { t } = useTranslation('forms')
  const location = useLocation()
  const navigate = useNavigate()
  const { headquarterSlug } = location.state
  const [isLoading, setIsLoading] = useState(false)
  const { axiosPrivate, usePrivateAxiosHook } = useAxiosPrivate()
  const [{ data: headquarterData, loading, error }, refetch] = usePrivateAxiosHook(`headquarter/${headquarterSlug}/`)
  const { register, handleSubmit, reset, errors, getValues } = useForm({
    defaultValues: useMemo(() => {
      return headquarterData
    }, [headquarterData])
  })

  useEffect(() => {
    reset(headquarterData)
  }, [headquarterData])


  const editHeadquarter = (slug, body) => {
    axiosPrivate.patch(`headquarter/${slug}/`, body )
      .then(res => {
        // console.log('edit company res', res);
        if (res.status === 200) {
          SweetAlert.fire(
            t('edited'),
            t('successEditMsg'),
            'success'
          )
          navigate(-1)
        } else {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('tryAgainMsg'),
            'warning'
          )
        }
      })
      .catch(err => {
        // console.log('error: ', err)
        if (err.request.status === 409) {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('sameHeadquarterDataExist'),
            'warning'
          )
        } else {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('tryAgainMsg'),
            'warning'
          )
        }
      })
  }

  const onSubmit = data => {
    // console.log('req data: ', data)
    SweetAlert.fire({
      title: t('confirmEditMsg'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: t('confirm'),
      cancelButtonText: t('cancel'),
      reverseButtons: true
    })
    .then((result) => {
      if (result.value) {
        if (data !== '') {
          setIsLoading(true)
          // console.log('req data body', data);
          try {
            const body = {
              name: data.name,
              country: data.country,
              city: data.city,
              zipCode: data.zipCode,
              streetName: data.streetName,
              addressNumber: data.addressNumber
            }
            editHeadquarter(headquarterData.slug, body)
            // console.log('data Body: ', body)
          } catch (e) {
            // console.log(e)
          }
          setIsLoading(false)
        } else {
          errors.showMessages();
        }
      }
    })
  }

  return (
    <Fragment>
      <Breadcrumb parent="Headquarters" title="Edit Headquarter"/>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <LoadingCardSpinner isLoading={isLoading || loading} />
              <CardHeader>
                <h5>{t('editHeadquarter')}</h5>
              </CardHeader>
              <Form className="form theme-form needs-validation" autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                <CardBody>
                  <Row>
                    <Col>
                    <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('name')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control"
                              name='name'
                              type="text"
                              placeholder={t('name')}
                              innerRef={register({ required: true, maxLength: 100 })}
                            />
                            <span className="">{errors.name?.type === 'required' && t('requiredField')}</span>
                            <span className="">{errors.name?.type === 'maxLength' && t('lengthLimit', {length: 100})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('country')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control"
                              name='country'
                              type="text"
                              placeholder={t('country')}
                              innerRef={register({ required: true, maxLength: 100 })}
                            />
                            <span className="">{errors.country?.type === 'required' && t('requiredField')}</span>
                            <span className="">{errors.country?.type === 'maxLength' && t('lengthLimit', {length: 100})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('city')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control"
                              name='city'
                              type="text"
                              placeholder={t('city')}
                              innerRef={register({ required: true, maxLength: 100 })}
                            />
                            <span className="">{errors.city?.type === 'required' && t('requiredField')}</span>
                            <span className="">{errors.city?.type === 'maxLength' && t('lengthLimit', {length: 100})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('address')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control"
                              name='streetName'
                              type="text"
                              placeholder={t('address')}
                              innerRef={register({ required: true, maxLength: 100 })}
                            />
                            <span className="">{errors.streetName?.type === 'required' && t('requiredField')}</span>
                            <span className="">{errors.streetName?.type === 'maxLength' && t('lengthLimit', {length: 100})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('zipCode')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                              <Input
                                className="form-control"
                                name='zipCode'
                                type="number"
                                placeholder={t('zipCode')}
                                innerRef={register({ required: true, maxLength: 100 })}
                              />
                              <span className="">{errors.zipCode?.type === 'required' && t('requiredField')}</span>
                              <span className="">{errors.zipCode?.type === 'maxLength' && t('lengthLimit', {length: 100})}</span>
                            </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('addressNumber')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                              <Input
                                className="form-control"
                                name='addressNumber'
                                type="number"
                                placeholder={t('addressNumber')}
                                innerRef={register({ required: true, maxLength: 100 })}
                              />
                              <span className="">{errors.addressNumber?.type === 'required' && t('requiredField')}</span>
                              <span className="">{errors.addressNumber?.type === 'maxLength' && t('lengthLimit', {length: 100})}</span>
                            </div>
                        </Col>
                      </FormGroup>
                      <Col sm="9 offset-sm-3">
                        <Button color="primary" className="mr-1">{t('edit')}</Button>
                        {/* <Button color="light" type="reset" >{t('reset')}</Button> */}
                      </Col>

                      {/* non editable fields */}
                      <Col className="mt-2" style={ !headquarterData?.company ? { display: 'none' } : {} }>
                        <hr/>
                        <>
                          <h5 className="mt-4 mb-4">{t('company')}</h5>
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label">{t('name')}</Label>
                            <Col sm="9">
                              <div className="form-row">
                                <Input
                                  className="form-control no-border"
                                  name='company.companyName'
                                  type="text"
                                  innerRef={register()}
                                  disabled
                                />
                              </div>
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label">{t('VAT')}</Label>
                            <Col sm="9">
                              <div className="form-row">
                                <Input
                                  className="form-control no-border"
                                  name='company.VATNumber'
                                  type="text"
                                  innerRef={register()}
                                  disabled
                                />
                              </div>
                            </Col>
                          </FormGroup>
                        </>
                      </Col>
                    </Col>
                  </Row>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default EditHeadquarters;