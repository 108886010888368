import React, { Fragment,useState,useEffect,useCallback,useMemo} from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import differenceBy from 'lodash/differenceBy';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component'
import { Search } from 'react-feather'
import {Link, useNavigate} from 'react-router-dom'
import { Container,Row,Col,Card,CardHeader, CardBody, InputGroup, InputGroupAddon, InputGroupText, Button, ModalBody, ModalHeader, ModalFooter, Modal} from 'reactstrap';
import { Input } from 'reactstrap'
import SweetAlert from 'sweetalert2'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import LoadingCardSpinner from '../LoadingCardSpinner/LoadingCardSpinner'
import {debounce} from 'lodash'
import { useTranslation } from 'react-i18next'

const HoldingList = () =>  {
  const { t } = useTranslation('dataTables')
  const navigate = useNavigate()
  const [data,setData] = useState([])
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  const [loading, setLoading] = useState(false)
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const { axiosPrivate } = useAxiosPrivate()
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)

  const [searchValue, setSearchValue] = useState('')
  const [sortColumn, setSortColumn] = useState('')
  const [sortDescDirection, setSortDescDirection] = useState(false)

  const tableColumns = [
    {
        name: t('holdingName'),
        selector: row => row.companyName,
        sortable: true,
        center:true,
        wrap: true,
        sortField: 'companyName'
    },
    {
        name: t('VATNumber'),
        selector: row => row.VATNumber,
        sortable: true,
        center:true,
        wrap: true,
        sortField: 'VATNumber'
    },
    {
      name: '',
      selector: row => renderRowBtns(row),
      sortable: false,
      center:true,
      ignoreRowClick: true
    }
  ]

  useEffect(() => {
    try {
      getHoldings()
    } catch (e) {
      // console.log(e)
    }
  }, [])

  const getHoldings = async (start = 1, limit = 10, sorting, desc, name) => {
    setLoading(true)
    let params = { start, limit }
    if (name) params = { ...params, name }
    if (sorting) params = { ...params, sorting }
    if (desc) params = { ...params, desc }
    const response = await axiosPrivate.get('holding/',
      {
        params
      }
    )
    setData(response?.data?.results ? [...response.data.results] : [])
    setTotalRows(response?.data?.results ? response.data.count : 0)
    setLoading(false)
  }

  const deleteHolding = async (slug) => {
    setIsDeleteLoading(true)
    axiosPrivate.delete(`holding/${slug}/`)
      .then(res => {
        setData(prev => prev.filter(holding => holding.slug !== slug))
        // check to understand if element is last on the page and in this case after delete switch page behind by one
        if (totalRows - ((currentPage - 1) * perPage) === 1 && totalRows !== 1) {
          handlePageChange(currentPage - 1)
        } else {
          handlePageChange(currentPage)
        }
        SweetAlert.fire(
          t('successfullyDeleted'),
          '',
          'success'
        )
      })
      .catch(err => {
        if (err.request.status === 404) {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('maybeWasAlreadyDeleted'),
            'warning'
          )
        } else {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('tryAgainMsg'),
            'warning'
          )
        }
      })
      .finally(() => {
        setIsDeleteLoading(false)
      })
  }

  const onHoldingDeletePress = async (slug) => {
    SweetAlert.fire({
      title: t('areYouSureToDelete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    })
      .then((result) => {
        if (result.value) {
          deleteHolding(slug)
        }
      })
  }

  const renderRowBtns = (row) => {
    return (
      <>
        <Link
          to={`/edit-holding/${row.slug}`}
          state={{ holdingId: row.id, holdingSlug: row.slug }}
          className="p-1"
          color="primary"
          size="sm"
        >
          <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'primary' }}></i>
        </Link>
        <span onClick={() => onHoldingDeletePress(row.slug)}><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></span>
      </>
      )
  }
  
  const handleRowSelected = useCallback(state => {
      setSelectedRows(state.selectedRows);
    }, []);

    const contextActions = useMemo(() => {
      const handleDelete = () => {
        
        if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.name)}?`)) {
          setToggleCleared(!toggleCleared);
          setData(differenceBy(data, selectedRows, 'name'));
          toast.success("Successfully Deleted !")
        }
      };
  
      return <button key="delete" className="btn btn-danger" onClick={handleDelete}>Delete</button>;
    }, [data, selectedRows, toggleCleared]);

    const handlePageChange = page => {
      setCurrentPage(page)
      getHoldings((page -1) * perPage + 1,  perPage, sortColumn, sortDescDirection, searchValue)
    }
  
    const handlePerRowsChange = async (newPerPage, page) => {
      const start = (page - 1) * newPerPage + 1
      const limit = newPerPage
      await getHoldings(start, limit, sortColumn, sortDescDirection, searchValue)
      setPerPage(newPerPage)
    }

    const onSearch = (query) => {
      getHoldings(1, perPage, false, false, query)
    }
  
    const debouncedSearch = React.useRef(
      debounce(onSearch, 500)
    ).current  
  
    const handleSort = (column, sortDirection) => {
      const isDescSort = sortDirection === 'desc'
      setSortColumn(column.sortField)
      setSortDescDirection(isDescSort)
      getHoldings(1, perPage, column.sortField, isDescSort)
    }

    return (
      <Fragment>
        <Breadcrumb parent="Holding" title="Holding list"/>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <LoadingCardSpinner isLoading={isDeleteLoading || loading} />
                <CardHeader>
                  <div className='header_filters_container'>
                    <h5 className='datatable_header_title'>{t('holding')}</h5>
                    <Row className='justify-content-end'>
                      <Col sm="10" className="d-flex align-items-center p-t-10 p-b-10">
                        <InputGroup>
                          <Input
                            className="form-control align-self-center"
                            type="search"
                            placeholder={t('search')}
                            value={searchValue}
                            onChange={event => {
                              setSearchValue(event.target.value)
                              debouncedSearch(event.target.value)
                            }}
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText style={{backgroundColor: 'inherit'}} className="form-control">
                              <Search aria-hidden="true" />
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>
                    </Row>
                  </div>
                </CardHeader>
                <CardBody>
                  <DataTable
                    noDataComponent={<h6 className="mb-4 mt-4">{t('noResults')}</h6> }
                    paginationComponentOptions={{rowsPerPageText: t('rowsPerPage'), rangeSeparatorText: t('of')}}
                    data={data}
                    columns={tableColumns}
                    striped={true}
                    center={true}
                    persistTableHead
                    contextActions={contextActions}
                    onSelectedRowsChange={handleRowSelected}
                    clearSelectedRows={toggleCleared}
                    highlightOnHover
                    pointerOnHover
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationTotalRows={totalRows}
                    paginationServer
                    pagination
                    sortServer
                    onSort={handleSort}
                    onRowClicked={(row) => navigate(`/edit-holding/${row.slug}`, { state: { holdingId: row.id, holdingSlug: row.slug } })}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>            
      </Fragment>
    );

};

export default HoldingList;