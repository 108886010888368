import React, { Fragment, useEffect, useState, useMemo } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import {Container,Row,Col,Card,CardHeader,CardBody,Button,Form,FormGroup,Label,Input} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import SweetAlert from 'sweetalert2'
import { useForm } from 'react-hook-form'
import LoadingCardSpinner from '../LoadingCardSpinner/LoadingCardSpinner'

const EditUser = () => {
  const { t } = useTranslation('forms')
  const location = useLocation()
  const navigate = useNavigate()
  const { userId } = location.state
  const [isLoading, setIsLoading] = useState(false)
  const { axiosPrivate, usePrivateAxiosHook } = useAxiosPrivate()
  const [{ data: userData, loading, error }, refetch] = usePrivateAxiosHook(`user/${userId}/`)
  const { register, handleSubmit, reset, errors, getValues } = useForm({
    defaultValues: useMemo(() => {
      return userData
    }, [userData])
  })

  useEffect(() => {
    reset(userData)
  }, [userData])

  const editUser = (id, body) => {
    axiosPrivate.patch(`user/${id}/`, body )
      .then(res => {
        // console.log(res);
        if (res.status === 200) {
          SweetAlert.fire(
            t('edited'),
            t('successEditMsg'),
            'success'
          )
          navigate(-1)
        } else {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('tryAgainMsg'),
            'warning'
          )
        }
      })
      .catch(err => {
        // console.log('error: ', err)
        if (err.request.status === 409) {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('sameDataUserExist'),
            'warning'
          )
        } else {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('tryAgainMsg'),
            'warning'
          )
        }
      })
  }

  const onSubmit = data => {
    // console.log(data)
    SweetAlert.fire({
      title: t('confirmEditMsg'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: t('confirm'),
      cancelButtonText: t('cancel'),
      reverseButtons: true
    })
    .then((result) => {
      if (result.value) {
        if (data !== '') {
          setIsLoading(true)
          try {
            const body = {
              name: data.name,
              surname: data.surname,
              telephoneNumber: data.telephoneNumber,
            }
            editUser(userData.id, body)
            // console.log(body)
          } catch (e) {
            // console.log(e)
          }
          setIsLoading(false)
        } else {
          errors.showMessages();
        }
      }
    })
  }

  return (
    <Fragment>
      <Breadcrumb parent="Users" title="Create User"/>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <LoadingCardSpinner isLoading={isLoading || loading} />
              <CardHeader>
                <h5>{t('editUser')}</h5>
              </CardHeader>
              <Form className="form theme-form needs-validation" autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                <CardBody>
                  <Row>
                    <Col>
                    <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('firstName')}*</Label>
                        <Col sm="9">
                        <div className="form-row">
                          <Input
                            className="form-control"
                            name='name'
                            type="text"
                            placeholder="First Name"
                            innerRef={register({ required: true, maxLength: 50 })}
                          />
                          <span className="">{errors.name?.type === 'required' && t('requiredField')}</span>
                          <span className="">{errors.name?.type === 'maxLength' && t('lengthLimit', {length: 50})}</span>
                        </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('lastName')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control"
                              name="surname"
                              type="text"
                              placeholder="Last Name"
                              innerRef={register({ required: true, maxLength: 50 })}
                            />
                            <span className="">{errors.surname?.type === 'required' && t('requiredField')}</span>
                            <span className="">{errors.surname?.type === 'maxLength' && t('lengthLimit', {length: 50})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('phone')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control m-input digits"
                              name="telephoneNumber"
                              type="number"
                              placeholder="+39 748 734 2935"
                              innerRef={register({ required: true, maxLength: 20 })}
                            />
                            <span className="">{errors.telephoneNumber?.type === 'required' && t('requiredField')}</span>
                            <span className="">{errors.telephoneNumber?.type === 'maxLength' && t('lengthLimit', {length: 20})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('username')}*</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              name="username"
                              className="form-control"
                              type="text"
                              placeholder="Username"
                              innerRef={register({ required: true, maxLength:30 })} 
                            />
                            <span className="">{errors.username?.type === "required" && t('requiredField')}</span>
                            <span className="">{errors.username?.type === 'maxLength' && t('lengthLimit', {length: 30})}</span>
                          </div>
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col sm="9 offset-sm-3">
                      <Button color="primary" className="mr-1">{t('edit')}</Button>
                      {/* <Button color="light" type="reset" >{t('reset')}</Button> */}
                    </Col>
                    {/* non editable fields */}
                    <Col className="mt-2">
                      <hr/>
                      <FormGroup className="row" style={ !userData?.company ? { display: 'none' } : {} }>
                        <Label className="col-sm-3 col-form-label">{t('company')}</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control no-border"
                              name='company.companyName'
                              type="text"
                              innerRef={register( )}
                              disabled
                            />
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row" style={ !userData?.holding ? { display: 'none' } : {} }>
                        <Label className="col-sm-3 col-form-label">{t('holding')}</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control no-border"
                              name="holding.companyName"
                              type="text"
                              innerRef={register()}
                              disabled
                            />
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('email')}</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control no-border"
                              name='email'
                              type="text"
                              innerRef={register()}
                              disabled
                            />
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{t('language')}</Label>
                        <Col sm="9">
                          <div className="form-row">
                            <Input
                              className="form-control no-border"
                              name='language.name'
                              type="text"
                              innerRef={register()}
                              disabled
                            />
                          </div>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default EditUser;