import React,{useState, useRef, useEffect} from 'react';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button } from 'reactstrap'
import {Password,SignIn,EmailAddress,RememberPassword,ForgotPassword} from '../../constant';
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useAuth from '../../hooks/useAuth'
import axios from '../../api/axios'
import Cookies from 'js-cookie'

const LOGIN_URL = 'auth/login/'

const Logins = () => {
  const { t } = useTranslation('login')
  const [isLoading, setIsLoading] = useState(false)

  const [togglePassword,setTogglePassword] = useState(false)

  const [user, setUser] = useState('')
  const [pwd, setPwd] = useState('')
  const [errMsg, setErrMsg] = useState('')

  const { setAuth, persist, setPersist } = useAuth()
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const userRef = useRef();
  const errRef = useRef();

  useEffect(() => {
    userRef.current.focus();
  }, [])

  useEffect(() => {
    setErrMsg('');
  }, [user, pwd])

  useEffect(() => {
    localStorage.setItem("persist", persist);
  }, [persist])

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    try {
        const body = {
          email: user, password: pwd
        }
        const response = await axios.post(LOGIN_URL,
            JSON.stringify(body),
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
        )
        const { access: accessToken, refresh: refreshToken } = response?.data
        const { role, id, username } = response?.data?.user
        const { slug: holdingSlug, id: holdingId } = response?.data?.user?.holding || {}
        const { slug: companySlug, id: companyId } = response?.data?.user?.company || {}
        const language = response?.data?.user?.language?.acronym
        setAuth({ username, role, id, language, holdingSlug, holdingId, companySlug, companyId, accessToken, refreshToken })
        Cookies.set('refresh', refreshToken, { expires: 30 })
        setUser('')
        setPwd('')
        navigate('/homepage', { replace: true });
    } catch (err) {
        if (!err?.response) {
            setErrMsg('No Server Response');
        } else if (err.response?.status === 400) {
            setErrMsg('Missing Username or Password');
        } else if (err.response?.status === 401) {
            setErrMsg('Unauthorized');
        } else if (err.response?.status === 404) {
            setErrMsg('Login failed, check if password and email are correct');
        } else {
          setErrMsg('Login Failed');
        }
    } finally {
      setIsLoading(false)
    }
  }

  const togglePersist = () => {
    setPersist(prev => !prev);
  }

  const HideShowPassword  = (tPassword) => {
    setTogglePassword(!tPassword)
  }

  return (
    <Container fluid={true} className="p-0">
      <Row className="m-0">
        <Col xs="12" className="p-0">     
          <div className="login-card">
            <div>
              <div className="login-main login-tab"> 
                <Form className="theme-form" onSubmit={handleSubmit}>
                  <h4>{t('signIn')}</h4>
                  <p>{t('enterEmailPassword')}</p>
                  <span style={{ color: 'red', padding: 0, margin: 0 }}>{!!errMsg && errMsg}</span>
                  <FormGroup>
                    <Label className="col-form-label">{t('email')}</Label>
                    <Input
                      ref={userRef}
                      className="form-control"
                      type="email"
                      placeholder="example@mail.com"
                      autoComplete="off"
                      onChange={(e) => setUser(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">{t('password')}</Label>
                    <Input
                      id='password'
                      className="form-control"
                      type={togglePassword ?  "text" : "password" }
                      name="login[password]"
                      value={pwd}
                      onChange={(e) => setPwd(e.target.value)}
                      placeholder="*********"
                      required
                    />
                    <div className="show-hide" onClick={() => HideShowPassword(togglePassword)}>
                      <span className={togglePassword ? "" : "show"}></span>
                    </div>
                  </FormGroup>
                  <div className="form-group mb-0">
                    <div className="checkbox ml-3">
                      <Input id="checkbox1" type="checkbox" checked={!persist} onChange={togglePersist}/>
                      <Label className="text-muted" for="checkbox1">{t('dontTrust')}</Label>
                    </div>
                    <Link className="link" to="/forget-password/">{t('forgotPassword')}</Link>
                  </div>
                  <div className="form-group mb-0">
                    <Button type="submit" color="primary" className="btn-block" disabled={isLoading}>{t('signIn')}</Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Logins;