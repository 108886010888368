import React, {useState,Fragment, useRef} from 'react';
import {Container,Row,Col,Card,CardHeader,CardBody,InputGroup, InputGroupAddon, InputGroupText,Input, Fade} from 'reactstrap'

import 'react-datepicker/dist/react-datepicker.css'


import SweetAlert from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate,useParams, Link } from 'react-router-dom'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import LoadingCardSpinner from '../../../components/LoadingCardSpinner/LoadingCardSpinner'
import useAuth from '../../../hooks/useAuth'
import { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { debounce, words } from 'lodash';
import { useCallback } from 'react';

const CompileReportsTable = (props) =>{
  const {subindicatorSlug:subSlug = '', actualReportSlug = ''} = props
  const { t } = useTranslation('dataTables')
  const { auth: { role: userRole }, auth } = useAuth()
  const [isTableLoading, setIsTableLoading] = useState(false)
  const { axiosPrivate } = useAxiosPrivate()
  const [ inputMap, setInputMap ] = useState({}) //objcet of reportSlugs:{value, note, notSaved}
  const [ numPrevReports, setNumPrevReports ] = useState(0)

  const [data,setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)

  useEffect(() => {
    try {
        getCompilationForReports()
    } catch (e) {
        // console.log(e)
    }
  }, [subSlug])

  useEffect(()=>{
    getCompilationForReports(numPrevReports)
  },[numPrevReports])
  
  const updateInputMap = async (slug, field, value)=>{
    let custom = {...inputMap?.[slug]}
    if(custom){
      custom[field] = value
      if(field !== 'notSaved') custom['notSaved'] = true
      //not know why but here, it's already updated (correctly) and do not triggers useEffect
      let newInputMap = {...inputMap}
      newInputMap[slug] = custom
      setInputMap(newInputMap)
    }
  }
  //debounced on change
  const debouncedUpdateInputMap = debounce(updateInputMap,500)

  //debounced setNumPrevReports
  const debouncedSetNumPrevReports = useRef(
    debounce(async (n) => {
      setNumPrevReports(n)
    },300)
  ).current

  /**  cancel debounced functions on unmount  **/
  useEffect(() => {
    return () => {
      debouncedSetNumPrevReports.cancel();
    };
  }, [debouncedSetNumPrevReports]);
  
  useEffect(() => {
    return () => {
      debouncedUpdateInputMap.cancel();
    };
  }, [debouncedUpdateInputMap]);
  
  /*********************************************/

  
  //GETTING DATA
  const getCompilationForReports = async (oldCompilationNumber = numPrevReports) => {
    if(!subSlug || !actualReportSlug || actualReportSlug === '' || subSlug === '')  return;
    setLoading(true)
    let params = {actualCompilationSlug:actualReportSlug,oldCompilationNumber}

    const response = await axiosPrivate
      .get('compilation/data/subindicator/'+subSlug, {headers:{'Content-Type':''}, params:{...params}})
      .catch(console.log)
      .finally(() => {
        setLoading(false)
      })

    let currentEntries = response?.data?.before?.reverse() ?? [];
      
    if(response?.data?.before)
      currentEntries.push(response?.data?.actual)

    setData(currentEntries)
  }

  //reloading inputMap
  useEffect(()=>{
    let oldInputMap = {...inputMap}??{}
    let oldReportSlugs = Object.keys(oldInputMap)
    let newInputMap = {}
    for(let entry of data){
      let _slug = entry.compilation.slug
      // if this report was on 'compilation phase' but a reload of data tables occurs
      // the modifications not saved must be preserved.
      // If there are no modifications not saved, reload data from server
      if(oldReportSlugs.includes(_slug) && oldInputMap[_slug].notSaved)
        newInputMap[_slug] = {...oldInputMap[_slug]}
      else 
        newInputMap[_slug] = {value:entry.value, note:entry.note, notSaved:false}
    }
    setInputMap(newInputMap)
  },[data])

  const RowButtons = (props)=>{
    const {row, render = true} = props
    return (
      <Fade in={render}>
        <i 
          onClick={
            async () => {
              let slug = row.compilation.slug
              await axiosPrivate
                .patch("/compilation/"+slug+"/subindicator/"+subSlug,{value:inputMap[slug]['value'], note:inputMap[slug]['note']})
                .then((res)=>{
                  if(res.status === 200)
                    SweetAlert.fire(
                      t('edited'),
                      t('successEditMsg'),
                      'success'
                    )
                  else
                    SweetAlert.fire(
                      t('somethingWentWrongMsg'),
                      t('tryAgainMsg'),
                      'warning'
                    )

                  updateInputMap(slug, 'notSaved', false)
                })
                .catch((err)=>{
                  SweetAlert.fire(
                    t('somethingWentWrongMsg'),
                    t('tryAgainMsg'),
                    'warning'
                  )
                })
                .finally(()=>{setLoading(false)})
            }
          } 
          className="fa fa-save" 
          style={{ width: 35, fontSize: 16, padding: 11, color: 'primary', cursor:'pointer' }}
        ></i>
      </Fade>
    )
  }

  useEffect(()=>{
    console.log(inputMap);
  },[inputMap])

  const tableColumns = [
    {
      name: t('reportYearStart'),
      selector: row => row.compilation.start, //end or start?
      center:true,
      wrap: true
    },
    {
      name: t('reportName'),
      selector: row => row.compilation.name,
      center:true,
      wrap: true
    },
    {
      name: t('value'),
      selector: row => {
        let value = inputMap?.[row.compilation.slug]?.value || row.value
        return <InputGroup key={(value)+"-value"}>
          <Input
            className="form-control align-self-center"
            type={"text"}
            placeholder={t('value')}
            defaultValue={value}
            onChange={event => {
              debouncedUpdateInputMap(row.compilation.slug,'value', event.target.value)
              // updateInputMap(row.compilation.slug,'value',event.target.value)
            }}
          />
        </InputGroup>},
      center:true,
      wrap: true
    },
    {
      name: t('note'),
      selector: row => {
        let note = inputMap?.[row.compilation.slug]?.note || row.note
        return <InputGroup key={note+"-note"}>
          <Input
            className="form-control align-self-center"
            type={"textarea"}
            rows="1"
            placeholder={t('note')}
            defaultValue={note}
            onChange={event => {
              debouncedUpdateInputMap(row.compilation.slug, 'note', event.target.value)
              // updateInputMap(row.compilation.slug, 'note', event.target.value)
            }}
          />
        </InputGroup>
      },
      center:true,
      wrap: true
    },
    {
      name: t('actions'),
      selector: row => <RowButtons 
          key={row.compilation.slug+'-btn0'} 
          row={row}
          render={inputMap?.[row.compilation.slug]?.['notSaved'] ?? false}
        />,
      sortable: false,
      center:true,
      ignoreRowClick: true
    }
  ]
  //******************END******************//


  return (
    <Fragment>
      <LoadingCardSpinner isLoading={isDeleteLoading || loading} />
      <CardBody>
        <Row className='justify-content-end mb-3 '>
          <Col sm="5"  >
            <InputGroup>
              <InputGroupText>{t('numberOfPreviousReports')}:</InputGroupText>
              <Input
                min="0"
                className="form-control align-self-center"
                type="number"
                defaultValue={0}
                onChange={event => {
                  debouncedSetNumPrevReports(event.target.value)
                  // setNumPrevReports(event.target.value)
                }}
              />
            </InputGroup>
          </Col>
        </Row>
        <DataTable
          noDataComponent={<h6 className="mb-4 mt-4">{t('noResults')}</h6> }
          data={data}
          columns={tableColumns}
          striped={true}
          center={true}
          persistTableHead
          highlightOnHover
          pointerOnHover
        />
      </CardBody>
    </Fragment>
  );
}


export default CompileReportsTable