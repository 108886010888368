import React, { Fragment,useState,useEffect,useCallback,useMemo} from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import differenceBy from 'lodash/differenceBy';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component'
import { Search } from 'react-feather'
import {Link, useNavigate} from 'react-router-dom'
import { Container,Row,Col,Card,CardHeader, InputGroup, InputGroupAddon, InputGroupText, CardBody} from 'reactstrap';
import {debounce} from 'lodash'
import ROLES from '../../utils/roles'
import useAuth from '../../hooks/useAuth'
import { useTranslation } from 'react-i18next'

import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import LoadingCardSpinner from '../LoadingCardSpinner/LoadingCardSpinner'
import { Input } from 'reactstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import SweetAlert from 'sweetalert2'
import 'react-bootstrap-typeahead/css/Typeahead.css'

const UsersList = () =>  {
  const { t } = useTranslation('dataTables')
  const { auth: { role: userRole }, auth } = useAuth()
  const navigate = useNavigate()
  const [data,setData] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [toggleCleared, setToggleCleared] = useState(false)
  const [companySelection, setCompanySelection] = useState([]);
  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState(false)
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const { axiosPrivate, usePrivateAxiosHook } = useAxiosPrivate()
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)

  const [searchValue, setSearchValue] = useState('')
  const [sortColumn, setSortColumn] = useState('')
  const [sortDescDirection, setSortDescDirection] = useState(false)

  useEffect(() => {
    try {
      getUsers()
    } catch (e) {
      // console.log(e)
    }
  }, [])


  const getUsers = async (start = 1, limit = 10, sorting, desc, name, email, company) => {
    // debugger
    // console.log('get users called');
    setLoading(true)
    let params = { start, limit }
    if (email) params = { ...params, email }
    if (company) params = { ...params, company }
    if (sorting) params = { ...params, sorting }
    if (desc) params = { ...params, desc }
    if (name) params = { ...params, name }
    let req = `users/`
    if ([ROLES.superadmin, ROLES.admin].includes(userRole)) {
      req = 'users/'
    } else if ([ROLES.groupleader].includes(userRole)) {
      req = `holding/${auth.holdingSlug}/users/`
    } else if ([ROLES.companymanager].includes(userRole)) {
      req = `company/${auth.companySlug}/users/`
    }
    const response = await axiosPrivate.get(req,
      {
        params
      }
    )
    .finally(() => {
      setLoading(false)
    })
    // console.log()
    setData(response?.data?.results ? [...response.data.results] : [])
    setTotalRows(response?.data?.results ? response.data.count : 0)
  }

  const deleteUser = async (id) => {
    setIsDeleteLoading(true)
    axiosPrivate.delete(`user/${id}/`)
      .then(res => {
        setData(prev => prev.filter(user => user.id !== id))
        // check to understand if element is last on the page and in this case after delete switch page behind by one
        if (totalRows - ((currentPage - 1) * perPage) === 1 && totalRows !== 1) {
          handlePageChange(currentPage - 1)
        } else {
          handlePageChange(currentPage)
        }
        SweetAlert.fire(
          t('successfullyDeleted'),
          '',
          'success'
        )
      })
      .catch(err => {
        if (err.request.status === 404) {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('maybeWasAlreadyDeleted'),
            'warning'
          )
        } else {
          SweetAlert.fire(
            t('somethingWentWrongMsg'),
            t('tryAgainMsg'),
            'warning'
          )
        }
      })
      .finally(() => {
        setIsDeleteLoading(false)
      })
  }

  const onUserDeletePress = async (id) => {
    SweetAlert.fire({
      title: t('areYouSureToDelete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    })
      .then((result) => {
        if (result.value) {
          deleteUser(id)
        }
      })
  }

  const tableColumns = [
    {
        name: t('name'),
        selector: row => `${row.name} ${row.surname}`,
        sortable: true,
        center:true,
        wrap: true,
        sortField: 'full_name'
    },
    {
        name: t('email'),
        selector: row => row.email,
        sortable: true,
        center:true,
        wrap: true,
        sortField: 'email'
    },
    {
        name: t('telephone'),
        selector: row => row.telephoneNumber,
        sortable: true,
        center:true,
        wrap: true,
        sortField: 'telephoneNumber'
    },
    {
        name: t('company'),
        selector: row => row?.company?.companyName,
        sortable: true,
        center:true,
        wrap: true,
        sortField: 'id_Company',
        omit: [ROLES.companymanager].includes(userRole)
    },
    {
        name: t('holding'),
        selector: row => row?.holding?.companyName,
        sortable: true,
        center:true,
        wrap: true,
        sortField: 'id_Holding',
        omit: [ROLES.groupleader, ROLES.companymanager].includes(userRole)
    },
    {
        name: t('role'),
        selector: row => row.role,
        sortable: true,
        center:true,
        wrap: true,
        sortField: 'role'
    },
    {
      name: '',
      selector: row => renderRowBtns(row),
      sortable: false,
      center:true,
      ignoreRowClick: true
    }
  ]

  const renderRowBtns = (row) => {
    return (
      <>
        <Link
          to={`/edit-user/${row.id}`}
          state={{ userId: row.id }}
          className="p-1"
          color="primary"
          size="sm"
        >
            <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'primary' }}></i>
        </Link>
        <span onClick={() => onUserDeletePress(row.id)}><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></span>
      </>
      )
  }
  
  const handleRowSelected = useCallback(state => {
      setSelectedRows(state.selectedRows);
    }, []);

    const contextActions = useMemo(() => {
      const handleDelete = () => {
        
        if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.name)}?`)) {
          setToggleCleared(!toggleCleared);
          setData(differenceBy(data, selectedRows, 'name'));
          toast.success("Successfully Deleted !")
        }
      };
  
      return <button key="delete" className="btn btn-danger" onClick={handleDelete}>Delete</button>;
    }, [data, selectedRows, toggleCleared]);

    const handlePageChange = page => {
      setCurrentPage(page)
      getUsers((page -1) * perPage + 1, perPage, sortColumn, sortDescDirection, searchValue)
    }
  
    const handlePerRowsChange = async (newPerPage, page) => {
      const start = (page - 1) * newPerPage + 1
      const limit = newPerPage
      await getUsers(start, limit, sortColumn, sortDescDirection, searchValue)
      setPerPage(newPerPage)
    }

    const handleSort = (column, sortDirection) => {
      let columnName = ''
      if (column.id === 1) {
          columnName = 'full_name'
      } else if (column.id === 2) {
          columnName = 'email'
      } else if (column.id === 3) {
        columnName = 'telephoneNumber'
      } else if (column.id === 4) {
        columnName = 'company'
      } else if (column.id === 5) {
        columnName = 'role'
      }
      const isDescSort = sortDirection === 'desc'
      setSortColumn(column.sortField)
      setSortDescDirection(isDescSort)
      getUsers(1, perPage, column.sortField, isDescSort)
    }

    const onSearch = (query) => {
      getUsers(1, perPage, false, false, query)
    }

    const debouncedSearch = React.useRef(
      debounce(onSearch, 500)
    ).current

    return (
      <Fragment>
        <Breadcrumb parent="Users" title="Users list"/>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <LoadingCardSpinner isLoading={isDeleteLoading || loading} />
                <CardHeader>
                  <div className='header_filters_container'>
                    <h5 className='datatable_header_title'>{t('users')}</h5>
                    <Row className='justify-content-end'>
                      {/* <Col sm="5" className="p-t-10 p-b-10">
                        <Typeahead
                          id="select-preset-indicators"
                          labelKey="companyName"
                          onChange={setCompanySelection}
                          options={companyData || []}
                          placeholder="Seleziona Azienda"
                          selected={companySelection}
                        />
                      </Col> */}
                      <Col sm="10" className="d-flex align-items-center p-t-10 p-b-10">
                        <InputGroup>
                          <Input
                            className="form-control align-self-center"
                            type="search"
                            placeholder={t('search')}
                            value={searchValue}
                            onChange={event => {
                              setSearchValue(event.target.value)
                              debouncedSearch(event.target.value)
                            }}
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText style={{backgroundColor: 'inherit'}} className="form-control">
                              <Search aria-hidden="true" className="" />
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>
                    </Row>
                  </div>
                </CardHeader>
                <CardBody>
                  <DataTable
                    data={data}
                    noDataComponent={<h6 className="mb-4 mt-4">{t('noResults')}</h6> }
                    paginationComponentOptions={{rowsPerPageText: t('rowsPerPage'), rangeSeparatorText: t('of')}}
                    columns={tableColumns}
                    striped={true}
                    center={true}
                    persistTableHead
                    contextActions={contextActions}
                    onSelectedRowsChange={handleRowSelected}
                    clearSelectedRows={toggleCleared}
                    highlightOnHover
                    pointerOnHover
                    pagination
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationTotalRows={totalRows}
                    paginationServer
                    sortServer
                    onSort={handleSort}
                    onRowClicked={(row) => navigate(`/edit-user/${row.id}`, { state: { userId: row.id } })}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>            
      </Fragment>
    );

};

export default UsersList;